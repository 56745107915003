import { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { HBox, VBox } from "../components/Containers";
import { IconButton, Button } from "../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import colors from "../config/colors";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    overflow-y: hidden;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const MessageInput = styled.input`
    border-radius: 1.25em;
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    // ::placeholder {
    //     color: ${colors.grey};
    // }
    :focus {
        outline: 1px solid ${colors.grey};
    }
`

const WriteMessageDlg = ({ userId, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");

    useEffect(() => {
        setMessage("");
    }, [open]);

    const handleMessage = () => {
        if (validate()) {
            axios({
                method: 'POST',
                url: 'chat/inbox-list/',
                data: {
                    receiver_id: userId,
                    message: message,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            }).then(res => {
                if (res.status === 201) {
                    setOpen(false);
                    navigate('/messages');
                    console.log('INBOX CREATED');
                }
                else if (res.status === 200) {
                    setOpen(false);
                    navigate('/messages');
                    console.log('NEW MESSAGE TO ALREADY CREATED INBOX');
                }
                else {
                    console.log('NEW MESSAGE FAILED');
                    enqueueSnackbar('Operation failed!', {variant: 'error'});
                }
            }).catch(error => {
                console.log('NEW MESSAGE ERROR', error);
                enqueueSnackbar('Operation error!', {variant: 'error'});
            });
        }
    }

    const validate = () => {
        if (message.trim().length === 0) {
            enqueueSnackbar('Please write a message.', {variant: 'default'});
            return false;
        }

        return true;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <Container>
                <HBox align="center" justify='space-between' className="mt-1 mb-1">
                    <H4 className="ml-2">Write Message</H4>
                    <IconButton
                        className="mr-2"
                        onClick={() => setOpen(false)}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
                <Line />
                <HBox className="p-2" justify="center" style={{ width: '100%', backgroundColor: colors.lightGrey }}>
                    <MessageInput
                        className="p-1"
                        placeholder="Write your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        autoFocus
                    />
                </HBox>
                <Line />
                <HBox justify="flex-end" className="p-1">
                    <Button
                        size='sm'
                        color='first'
                        className="mx-1"
                        onClick={handleMessage}
                    >
                        Message
                    </Button>
                </HBox>
            </Container>
        </Dialog>
    )
}

export default WriteMessageDlg;