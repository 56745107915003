import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX, FiPlus, FiTrash, FiFileText, FiCamera } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { HBox, VBox } from "../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { Button, IconButton } from "../components/Buttons";
import { InputText } from "../components/InputText";
import colors from "../config/colors";
import Confirm from "./Confirm";
import TakeHRecordPhotoDlg from './TakeHRecordPhotoDlg';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const AddBtn = styled(Button)`
    width: 100%;
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const UploadedFileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
`

const DeleteIcon = styled(FiTrash)`
    color: ${colors.red};
    cursor: pointer;
`

const ViewIcon = styled(FiFileText)`
    color: ${colors.blue};
    cursor: pointer;
`

const UploadedFile = ({ id, index, name, file, files, setFiles }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleClickDelete = () => {
        setConfirmOpen(true);
    }

    const handleConfirm = () => {
        axios({
            method: "DELETE",
            url: 'patient/hr-file-details/',
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            if (res.status === 202) {
                var values = [...files];
                values.splice(index, 1);
                setFiles(values);

                enqueueSnackbar('File deleted.', {variant: 'success'});
            }
            else {
                enqueueSnackbar('File delete failure!', {variant: 'error'});
            }
        }).catch((error) => {
            enqueueSnackbar('File delete error!', {variant: 'error'});
        });
    }

    return (
        <UploadedFileContainer className='p-1 mb-1'>
            <P2>{name}</P2>
            <HBox>
                <DeleteIcon onClick={handleClickDelete} />
                <a href={`${baseURL}${file}`} target="_blank">
                    <ViewIcon
                        className='ml-0_5'
                    />
                </a>
            </HBox>

            <Confirm
                message="Are you sure to delete this file?"
                btnColor="second"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleConfirm}
            />
        </UploadedFileContainer>
    );
}

const UploadRecordDlg = ({ open, setOpen, reloadRecords }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [reportDate, setReportDate] = useState("");
    const [files, setFiles] = useState([]);

    const [titleError, setTitleError] = useState("");
    const [reportDateError, setReportDateError] = useState("");

    const [openTakePhoto, setOpenTakePhoto] = useState(false);

    useEffect(() => {
        const init = () => {
            setId("");
            setTitle("");
            setReportDate("");
            setFiles([]);

            setTitleError("");
            setReportDateError("");
        }
        if (open) init();
    }, [open]);

    const handleAddFile = (uploadFile) => {
        const uploadData = new FormData();
        uploadData.append('id', id);
        uploadData.append('title', title);
        uploadData.append('report_date', reportDate);
        uploadData.append('file', uploadFile, uploadFile.name);

        axios({
            method: "POST",
            url: 'patient/hr-file-list/',
            data: uploadData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            if (res.status === 201) {
                setFiles([...files, res.data.hr_file]);
                setId(res.data.hr.id);

                enqueueSnackbar('File added.', {variant: 'success'});
            }
            else {
                enqueueSnackbar('File add failure!', {variant: 'error'});
            }
        }).catch((error) => {
            enqueueSnackbar('File add error!', {variant: 'error'});
        });
    }

    const validate = () => {
        var ok = true;
        
        if (title) {
            setTitleError("");
        }
        else {
            setTitleError("This field must not be kept blank.");
            ok = false;
        }

        if (reportDate) {
            setReportDateError("");
        }
        else {
            setReportDateError("This field must not be kept blank.");
            ok = false;
        }

        return ok;
    }

    const handleClickBrowse = event => {
        if (validate()) inputRef.current.click();
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleAddFile(e.target.files[0]);
            e.target.value = null;
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
        if (!id) {
            // do nothing. just close
        }
        else if (reloadRecords) {
            reloadRecords();
        }
        else {
            navigate('/health-records');
        }
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Upload Health Record</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-2">
                <P2 className="mb-2">At first, set the title and test date. Then add files by clicking <span style={{ color: colors.green, fontWeight: 'bold' }}>"+ Add"</span> button. Multiple files can be uploaded for each health record.</P2>
                <HBox justify="space-between" className='mb-2'>
                    <InputText
                        label="Title"
                        style={{ width: '60%' }}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        helpText={titleError}
                    />
                    <InputText
                        label="Test Date"
                        type="date"
                        style={{ width: '38%' }}
                        value={reportDate}
                        onChange={(e) => setReportDate(e.target.value)}
                        helpText={reportDateError}
                    />
                </HBox>
                <VBox>
                    <H5 className='mb-1'>Files</H5>
                    {files.map((file, index) => (
                        <UploadedFile
                            id={file.id}
                            index={index}
                            name={`File ${file.order}`}
                            file={file.file}
                            files={files}
                            setFiles={setFiles}
                        />
                    ))}
                    <AddBtn color='third' size='sm' className='mt-1' onClick={handleClickBrowse} outlined>
                        <FiPlus size={16} className='mr-1' /> Browse File
                        <input
                            type="file"
                            // accept="image/*"
                            ref={inputRef}
                            onChange={onSelectFile}
                            hidden
                        />
                    </AddBtn>
                    <P2 align='center' className='my-0_5'>Or</P2>
                    <Button
                        size='sm'
                        color='second'
                        onClick={() => {if (validate()) setOpenTakePhoto(true)}}
                        outlined
                    >
                        <FiCamera size={16} className='mr-1' />
                        Take Photo
                    </Button>
                </VBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                {id ?
                    <Button color="first" size="sm" onClick={handleClose}>Done</Button>
                    :
                    <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                }
            </HBox>

            <TakeHRecordPhotoDlg
                open={openTakePhoto}
                setOpen={setOpenTakePhoto}
                handleAddFile={handleAddFile}
            />
        </Dialog>
    );
}

export default UploadRecordDlg;