import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { BiMessageAdd } from "react-icons/bi";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";

import { P1, P2, H1, H2, H3, H4, H5, H6 } from "../../components/Typography";
import { HBox, VBox } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { LowerNav } from "../../layouts/Navbar";
import InboxList from "./components/InboxList";
import MessageThread from "./components/MessageThread";
import ContactProfile from "./components/ContactProfile";
import NewMessageDlg from "./components/NewMessageDlg";
import Footer from "../../layouts/Footer";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const THBox = styled(HBox)`
    position: absolute;
    left: 0px;
    top: 50px;
    height: 60px;
    width: 20%;
    border-bottom: 2px solid ${colors.grey};
    z-index: 1;
`

const Image = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 1px solid ${colors.grey};
`

const WriteMessageIcon = styled(BiMessageAdd)`
    color: ${colors.red};
    width: 20px;
    height: auto;
    cursor: pointer;
`

const Right = styled(VBox)`
    width: 20%;
`

let msgSocket;

const Messages = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [user, setUser] = useState({
        id: null, name: '', image: null
    });

    const pageSize = 10;
    const [inboxes, setInboxes] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [loadInboxes, setLoadInboxes] = useState(false);
    const [selectedInboxIndex, setSelectedInboxIndex] = useState(null);

    const [newMessageOpen, setNewMessageOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getUserData();
        initInboxes();
    }, []);

    useEffect(() => {
        if (loadInboxes) getInboxes();
    }, [loadInboxes]);

    useEffect(() => {
        if (selectedInboxIndex === null && !isMobile) {
            setSelectedInboxIndex(inboxes.length ? 0 : null);
        }
    }, [inboxes]);

    const initInboxes = () => {
        setSearchKey("");
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setInboxes([]);
        setLoadInboxes(true);
    }

    const getUserData = () => {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            if (res.status === 200) {
                setUser(res.data);
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    const getInboxes = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `chat/inbox-list/?key=${searchKey}&offset=${offset}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            setIsLoading(false);
            closeSnackbar();
            setLoadInboxes(false);
            if (res.status === 200) {
                setInboxes([...inboxes].concat(res.data.inboxes));
                setHasMore(res.data.has_more);
                setCount(res.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            }
            else {
                setIsLoading(false);
                console.log('INBOX LIST FETCH FAILED');
            }
        }).catch(error => {
            setIsLoading(false);
            closeSnackbar();
            setLoadInboxes(false);
            console.log('INBOX LIST FETCH ERROR', error);
        });
    }

    const handleFetchInboxes = () => {
        if (hasMore & !isLoading) {
            getInboxes();
        }
    }

    const handleSearchInbox = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setOffset(0);
                    setLimit(pageSize);
                    setHasMore(true);
                    setInboxes([]);
            }, 300)
        )
    }

    const handleInboxScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchInboxes();
    }

    return (
        <>
            <LowerNav selected='messages' />
            <THBox
                align="center"
                justify="space-between"
                style={{ top: isMobile ? 110 : 50, width: isMobile ? '100%' : '20%', display: isMobile && selectedInboxIndex!==null ? 'none' : '' }}
            >
                <HBox align="center">
                    <Image
                        className="ml-2"
                        src={`${baseURL}${user.image}`}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src="/images/noImage.svg";
                        }}
                    />
                    <H5 className="ml-1">{user.name}</H5>
                </HBox>
                <WriteMessageIcon
                    className="mr-2 clickable"
                    onClick={() => setNewMessageOpen(true)}
                />
            </THBox>

            <HBox>
                {(isMobile && selectedInboxIndex !== null) ?
                    null
                    :
                    <InboxList
                        inboxes={inboxes}
                        setInboxes={setInboxes}
                        selectedInboxIndex={selectedInboxIndex}
                        setSelectedInboxIndex={setSelectedInboxIndex}
                        searchKey={searchKey}
                        handleSearch={handleSearchInbox}
                        handleScrollUpdate={handleInboxScrollUpdate}
                    />
                }
                {(isMobile && selectedInboxIndex === null) ?
                    null
                    :
                    <MessageThread
                        inboxIndex={selectedInboxIndex}
                        setInboxIndex={setSelectedInboxIndex}
                        initInboxes={initInboxes}
                        inboxes={inboxes}
                        setInboxes={setInboxes}
                    />
                }
                {!isMobile && <ContactProfile inboxId={inboxes[selectedInboxIndex]?.id} />}
            </HBox>
            <NewMessageDlg
                open={newMessageOpen}
                setOpen={setNewMessageOpen}
                initInboxes={initInboxes}
                inboxIndex={selectedInboxIndex}
                setInboxIndex={setSelectedInboxIndex}
            />
            {!isMobile && <Footer />}
        </>
    )
}

export default Messages;