import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiSearch, FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";
import DoctorCard from "./DoctorCard";

const Container = styled.div`
    overflow-y: hidden;
`

const SearchIcon = styled(FiSearch)`
    color: ${colors.darkBlue};
`

const Input = styled.input`
    width: 80%;
    height: 2.5em;
    border: 0px;
    color: ${colors.darkGrey};
    outline: none;
    font-size: 1rem;
    padding: 16px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const Image = styled.img`
    width: 10%;
    height: auto;
`

const DoctorSearchDlg = ({ open, setOpen, selectedId, setSelectedDoctor, handleChangeFilter }) => {
    const pageSize = 10;
    const [doctors, setDoctors] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputTimeout, setInputTimeout] = useState(null);

    useEffect(() => {
        const init = () => {
            setSearchKey("");
            setOffset(0);
            setLimit(pageSize);
            setHasMore(true);
            setDoctors([]);
        }
        if (open) init();
    }, [open]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `patient/appointment-doctor-list/?key=${searchKey}&offset=${offset}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setDoctors(doctors.concat(response.data.doctors));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('DOCTOR LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('DOCTOR LIST FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleSearch = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setOffset(0);
                    setLimit(pageSize);
                    setHasMore(true);
                    setDoctors([]);
            }, 300)
        )
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClick = (id, name) => {
        setSelectedDoctor(name);
        handleChangeFilter(id, 'doctorId');
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            scroll="body"
        >
            <Container>
                <HBox align="center" justify='space-between' className="mt-1 mb-1">
                    <HBox align="center" style={{ width: '80%' }}>
                        <SearchIcon fontSize={24} className="ml-2" />
                        <Input
                            placeholder="Search doctors..."
                            type="text"
                            autoFocus
                            value={searchKey}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </HBox>
                    <IconButton
                        className="mr-2"
                        onClick={() => setOpen(false)}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
                <Line />
                {searchKey&&count!==null ?
                    <HBox className="px-2 py-1" style={{ backgroundColor: colors.lightGrey }}>
                        <P2>We have found</P2>
                        <P2 color='third' className="bold" style={{ marginLeft: 4, marginRight: 4 }}>{count}</P2>
                        <P2>{count === 1 ? 'doctor' : 'doctors'}.</P2>
                    </HBox>
                    :
                    <></>
                }
                {searchKey&&count!==null ? <Line /> : <></>}
                <Scrollbars
                    style={{ height: '50vh' }}
                    onUpdate={handleScrollUpdate}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    {doctors.length ?
                        <VBox className="m-2">
                            {doctors.map((doctor, index) => (
                                <DoctorCard
                                    index={index}
                                    name={doctor.name}
                                    image={doctor.image}
                                    bmdc={doctor.bmdc}
                                    qualification={doctor.qualification}
                                    specialty={doctor.specialty}
                                    selected={doctor.id === selectedId}
                                    onClick={() => handleClick(doctor.id, doctor.name)}
                                />
                            ))}
                        </VBox>
                        :
                        <VBox justify="center" align="center" style={{ height: '80%' }}>
                            <Image src="/images/search.png" />
                        </VBox>
                    }
                </Scrollbars>
            </Container>
        </Dialog>
    )
}

export default DoctorSearchDlg;