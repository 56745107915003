import { useState, useEffect } from "react";
import styled from "styled-components";

import { VBox, HBox } from "../components/Containers";
import { P2, P3 } from "../components/Typography";
import colors from "../config/colors";
import responsive from "../config/responsive";
import TermsAndConditionsDlg from "./TermsAndConditionsDlg";
import PrivacyPoilcyDlg from "./PrivacyPolicyDlg";
import RefundPoilcyDlg from "./RefundPolicyDlg";

const Container = styled.div`
    height: 40px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGrey};
    border-top: 1px solid ${colors.grey};
`

const A = styled.a`
    text-decoration: none;
`

const Footer = () => {
    const [openTNC, setOpenTNC] = useState(false);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const [openRefundPolicy, setOpenRefundPolicy] = useState(false);

    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };

    return (
        <Container>
            <VBox align='center'>
                <HBox>
                    <A href={process.env.REACT_APP_USER_MANUAL_URL} target='_blank'>
                        <P3 color="third" className="bold">
                            User Manual
                        </P3>
                    </A>
                    <P3 className="mx-0_5">|</P3>
                    <P3
                        className='clickable'
                        onClick={() => setOpenTNC(true)}
                    >
                        Terms & Conditions
                    </P3>
                    <P3 className="mx-0_5">|</P3>
                    <P3
                        className='clickable'
                        onClick={() => setOpenPrivacyPolicy(true)}
                    >
                        Privacy Policy
                    </P3>
                    <P3 className="mx-0_5">|</P3>
                    <P3
                        className='clickable'
                        onClick={() => setOpenRefundPolicy(true)}
                    >
                        Refund Policy
                    </P3>
                </HBox>
                <P3>Copyright © {getYear()} NextGen DigiTech Ltd. All rights reserved.</P3>
            </VBox>

            <TermsAndConditionsDlg
                open={openTNC}
                setOpen={setOpenTNC}
            />
            <PrivacyPoilcyDlg
                open={openPrivacyPolicy}
                setOpen={setOpenPrivacyPolicy}
            />
            <RefundPoilcyDlg
                open={openRefundPolicy}
                setOpen={setOpenRefundPolicy}
            />
        </Container>
    )
}

export default Footer;