import {
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_REFRESH,
    LOGIN,
    VERIFICATION,
    LOGOUT,
    NETWORK_ERROR,
    LOGIN_ERROR,
    VERIFICATION_ERROR,
    SHOW_UPPERNAV,
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    isVerified: true,  // Setting true, as temporarily turned off passcode verification
    networkError: false,
    loginError: false,
    verificationError: false,
    showUppernav: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                networkError: false,
                showUppernav: true,
            }
        case AUTHENTICATION_REFRESH:
            localStorage.setItem('nh-access', payload.access);
            return {
                ...state,
                isAuthenticated: true,
                networkError: false,
                showUppernav: true,
            }
        case LOGIN:
            localStorage.setItem('nh-access', payload.access);
            localStorage.setItem('nh-refresh', payload.refresh);
            localStorage.setItem('nh-loginwith', payload.login_with);
            return {
                ...state,
                isAuthenticated: true,
                isVerified: payload.is_verified,
                networkError: false,
                loginError: false,
                showUppernav: true,
            }
        case VERIFICATION:
            return {
                ...state,
                isVerified: payload.is_verified,
                verificationError: false,
            }
        case LOGOUT:
            localStorage.removeItem('nh-access');
            localStorage.removeItem('nh-refresh');
            localStorage.removeItem('nh-loginwith');
            return {
                ...state,
                isAuthenticated: false,
                networkError: false,
                showUppernav: false,
            }
        case NETWORK_ERROR:
            return {
                ...state,
                networkError: true,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                networkError: false,
                loginError: true,
            }
        case VERIFICATION_ERROR:
            return {
                ...state,
                networkError: false,
                verificationError: true,
            }
        case SHOW_UPPERNAV:
            return {
                ...state,
                showUppernav: payload.showUppernav,
            }
        default:
            return state;
    }
};
