import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiSearch, FiX } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";
import DoctorCard from "./DoctorCard";
import SelectPracticeLocation from "./SelectPracticeLocationDlg";
import { specialtiesData } from "../../../data";

const Container = styled.div`
    overflow-y: hidden;
`

const SearchIcon = styled(FiSearch)`
    color: ${colors.darkBlue};
`

const Input = styled.input`
    width: 90%;
    height: 2.5em;
    border: 0px;
    color: ${colors.darkGrey};
    outline: none;
    font-size: 1rem;
    padding: 16px;
`

const Select = styled.select`
    /* width: 30%; */
    height: 2.5em;
    color: ${colors.darkGrey};
    /* font-weight: bold; */
    font-size: 0.75rem;
    border: 1px solid ${colors.grey};
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    outline: none;
    cursor: pointer;
`

const SelectBtn = styled.button`
    height: 2.5em;
    color: ${colors.darkGrey};
    /* font-weight: bold; */
    font-size: 0.75rem;
    border: 1px solid ${colors.grey};
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
`

const ClearBtn = styled(Button)`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    background-color: white;
    font-size: 0.75rem;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const Image = styled.img`
    width: 10%;
    height: auto;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const SearchDlg = ({ isMobile, open, setOpen }) => {
    const pageSize = 10;
    const [doctors, setDoctors] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [filter, setFilter] = useState({
        specialty: "",
        gender: "",
        district_id: "",
    });
    const [locfiltTxt, setLocFiltTxt] = useState("All Practice Locations");
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [openSelectLoc, setOpenSelectLoc] = useState(false);
    
    useEffect(() => {
        const init = () => {
            setSearchKey("");
            clearFilter();
            setOffset(0);
            setLimit(pageSize);
            setHasMore(true);
            setDoctors([]);
        }
        if (open) init();
    }, [open]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'patient/doctor-list/',
            params: {
                key: searchKey,
                specialty: filter.specialty,
                gender: filter.gender,
                district_id: filter.district_id,
                offset: offset,
                limit: limit
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setDoctors(doctors.concat(response.data.doctors));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('DOCTOR LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('DOCTOR LIST FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleSearch = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setOffset(0);
                    setLimit(pageSize);
                    setHasMore(true);
                    setDoctors([]);
            }, 300)
        )
    }

    const handleChangeFilter = (value, key) => {
        setFilter({...filter, [key]: value});
        setCount(null);
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setDoctors([]);
    }

    const handleClickClearFilter = () => {
        clearFilter();
        setCount(null);
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setDoctors([]);
    }

    const clearFilter = () => {
        setFilter({
            specialty: "",
            gender: "",
            district_id: ""
        });
        setLocFiltTxt("All Practice Locations");
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="md"
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <Container>
                <HBox align="center" justify='space-between' className="mt-1 mb-1">
                    <HBox align="center" style={{ width: '85%' }}>
                        <SearchIcon fontSize={24} className="ml-2" />
                        <Input
                            style={{ fontSize: isMobile ? '0.8rem' : '1rem', width: '80%' }}
                            placeholder="Search doctors by name or BMDC..."
                            type="text"
                            autoFocus
                            value={searchKey}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </HBox>
                    <IconButton
                        className="mr-2"
                        onClick={handleClose}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
                <Line />
                <HBox align="center" justify="space-between" className='px-2 py-1'>
                    <H6 className="m-0_5 bold" color='second'>Filtering</H6>
                    <HBox>
                        <Select
                            style={{ width: 120 }}
                            className="px-1 m-0_5"
                            onChange={(e) => handleChangeFilter(e.target.value, 'specialty')}
                        >
                            <option value="" selected={filter.specialty === ""}>All Specialties</option>
                            {specialtiesData.map((specialty, index) => (
                                <option value={specialty} selected={filter.specialty === specialty}>{specialty}</option>
                            ))}
                        </Select>
                        <Select className="px-1 m-0_5" onChange={(e) => handleChangeFilter(e.target.value, 'gender')}>
                            <option value="" selected={filter.gender === ""}>All Gender</option>
                            <option value='Female' selected={filter.gender === "Female"}>Female</option>
                            <option value='Male' selected={filter.gender === "Male"}>Male</option>
                        </Select>
                        <SelectBtn className="px-1 m-0_5" onClick={() => setOpenSelectLoc(true)}>
                            {locfiltTxt}<IoLocationOutline className="ml-0_5" />
                        </SelectBtn>
                        <ClearBtn
                            size="sm"
                            className="m-0_5"
                            outlined
                            onClick={handleClickClearFilter}
                        >
                            Clear
                        </ClearBtn>
                    </HBox>
                </HBox>
                <Line />
                {(searchKey||filter.specialty||filter.gender||filter.district_id)&&count!==null ?
                    <HBox className="px-2 py-1" style={{ backgroundColor: colors.lightGrey }}>
                        <P2>We have found</P2>
                        <P2 color='third' className="bold" style={{ marginLeft: 4, marginRight: 4 }}>{count}</P2>
                        <P2>{count === 1 ? 'doctor' : 'doctors'}.</P2>
                    </HBox>
                    :
                    <></>
                }
                {(searchKey||filter.specialty||filter.gender||filter.district_id)&&count!==null ? <Line /> : <></>}
                <Scrollbars
                    style={{ height: '70vh' }}
                    onUpdate={handleScrollUpdate}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    {doctors.length ?
                        <VBox className="m-2">
                            {doctors.map((doctor, index) => (
                                <SLink to={`/doctor/${doctor.id}`}>
                                    <DoctorCard
                                        index={index}
                                        name={doctor.name}
                                        image={doctor.image}
                                        doctorType={doctor.doctor_type}
                                        bmdc={doctor.bmdc}
                                        qualification={doctor.qualification}
                                        specialty={doctor.specialty}
                                        fee={doctor.fee}
                                        experience={doctor.experience}
                                        isOnline={doctor.is_online}
                                    />
                                </SLink>
                            ))}
                        </VBox>
                        :
                        <VBox justify="center" align="center" style={{ height: '80%' }}>
                            <Image src="/images/search.png" />
                        </VBox>
                    }
                </Scrollbars>
            </Container>

            <SelectPracticeLocation
                open={openSelectLoc}
                setOpen={setOpenSelectLoc}
                handleChangeFilter={handleChangeFilter}
                setLocFiltTxt={setLocFiltTxt}
            />
        </Dialog>
    )
}

export default SearchDlg;