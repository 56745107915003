import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { FiChevronRight } from "react-icons/fi";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import CheckButton from "../../components/CheckButton";
import { InputText } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { PatchLeft } from "../../components/Patches";

const Container = styled(HBox)``

const SLink = styled(Link)`
    text-decoration: none;
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const ButtonNav = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0px;
    align-items: center;
    justify-content: center;
`

const HealthHistory = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [drugSensitivity, setDrugSensitivity] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [takingMedication, setTakingMedication] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [healthCondition, setHealthCondition] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [anySurgery, setAnySurgery] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [covidPositive, setCovidPositive] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);

    const [params, setParams] = useState({
        height_ft: "", height_in: "", weight: "", bmi: "",  blood_pressure_systolic: "",
        blood_pressure_diastolic: "", temperature: "", pulse: "",
        blood_glucose: "", pulse_oximetry: ""
    });

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar('Loading...', { persist: true });
        fetchData();
    }, []);

    useEffect(() => {
        var height = getHeight();
        if (params.weight && height) {
            let weight = parseFloat(params.weight) * 2.20462;  // kg to lb
            let bmi = (weight / height**2) * 703;

            setParams({...params, bmi: bmi.toFixed(1)});
        }
        else {
            setParams({...params, bmi: ""});
        }
    }, [params.height_ft, params.height_in, params.weight]);

    const fetchData = async () => {
        await axios({
            method: 'GET',
            url: `patient/health-history-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setData(response.data);
            } else {
                console.log('HEALTH HISTORY FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('HEALTH HISTORY FETCH ERROR', error);
        })
    }

    const submit = () => {
        axios({
            method: 'PUT',
            url: `patient/health-history-details/`,
            data: {
                drug_sensitivity: getBoolean(drugSensitivity),
                taking_medication: getBoolean(takingMedication),
                health_condition: getBoolean(healthCondition),
                any_surgery: getBoolean(anySurgery),
                covid_positive: getBoolean(covidPositive),
                height: getHeight(),
                weight: params.weight,
                bmi: params.bmi,
                blood_pressure_systolic: params.blood_pressure_systolic,
                blood_pressure_diastolic: params.blood_pressure_diastolic,
                temperature: params.temperature,
                pulse: params.pulse,
                blood_glucose: params.blood_glucose,
                pulse_oximetry: params.pulse_oximetry
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                enqueueSnackbar('Successfully updated.', { variant: 'success' });
            } else {
                enqueueSnackbar('Submission failed!', { variant: 'error' });
                console.log('HEALTH HISTORY SET FAILED', response.status);
            }
        })
        .catch((error) => {
            enqueueSnackbar('Submission error!', { variant: 'error' });
            console.log('HEALTH HISTORY SET ERROR', error);
        })
    }

    const setData = (data) => {
        setBoolean(data.drug_sensitivity, drugSensitivity, setDrugSensitivity);
        setBoolean(data.taking_medication, takingMedication, setTakingMedication);
        setBoolean(data.health_condition, healthCondition, setHealthCondition);
        setBoolean(data.any_surgery, anySurgery, setAnySurgery);
        setBoolean(data.covid_positive, covidPositive, setCovidPositive);
        
        let heightArr = convertHeight(data.height);
        setParams({...params, height_ft: heightArr[0], height_in: heightArr[1],
                   weight: data.weight, bmi: data.bmi,
                   blood_pressure_systolic: data.blood_pressure_systolic,
                   blood_pressure_diastolic: data.blood_pressure_diastolic,
                   temperature: data.temperature, pulse: data.pulse,
                   blood_glucose: data.blood_glucose, pulse_oximetry: data.pulse_oximetry});
    }

    const setBoolean = (value, state, setState) => {
        if (value === true) {
            let values = [...state];
            values[0].checked = true;
            setState(values);
        }
        else if (value === false) {
            let values = [...state];
            values[1].checked = true;
            setState(values);
        }
        else {
            // do nothing
        }
    }

    const getBoolean = (state) => {
        if (state[0].checked) return true;
        else if (state[1].checked) return false;
        else return null;
    }

    const convertHeight = (height) => {
        if (height) {
            let feet = Math.floor(height / 12);
            let inch = height%12;

            return [feet, inch];
        }
        return ["", ""];
    }

    const getHeight = () => {
        if (params.height_ft && params.height_in) {
            return parseFloat(params.height_ft)*12 + parseFloat(params.height_in);
        }
        else if (params.height_ft) {
            return parseFloat(params.height_ft)*12;
        }
        else if (params.height_in) {
            return parseFloat(params.height_in);
        }
        else {
            return "";
        }
    }

    const setNumeric = (field, value, lowLim, highLim, prec=2) => {
        if (value < lowLim) value = lowLim;
        else if (value > highLim) value = highLim;
        
        value = parseFloat(value);
        setParams({...params, [field]: parseFloat(value.toFixed(prec))});
    }

    return (
        <div>
            <LowerNav selected="health-history" />
            <Container style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <PatchLeft />
                {isMobile && <TopPanel className='p-2' align="center">
                    <HBox align="center">
                        <ButtonNav size='md' color='first'>1</ButtonNav>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/behavioral-health-history' className="">
                            <ButtonNav size='md' color='first' outlined>2</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/life-style' className="">
                            <ButtonNav size='md' color='first' outlined>3</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/family-history' className="">
                            <ButtonNav size='md' color='first' outlined>4</ButtonNav>
                        </SLink>
                    </HBox>
                    <H3 color='second' justify='center' className='mt-2'>Health History</H3>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <NavBtnActive size="sm" color="first" className="mt-3" outlined>
                        <div className="ml-1">My Health History</div>
                    </NavBtnActive>
                    <SLink to='/behavioral-health-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Behavioral Health History</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/life-style'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Life Style</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/family-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Family History</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: '100%' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-3'>
                            <P1>Are you currently taking any medication?</P1>
                            <CheckButton className="mt-1" items={takingMedication} setItems={setTakingMedication} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you have any allergies or drug sensitivities?</P1>
                            <CheckButton className="mt-1" items={drugSensitivity} setItems={setDrugSensitivity} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you have any health conditions?</P1>
                            <CheckButton className="mt-1" items={healthCondition} setItems={setHealthCondition} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Have you been tested COVID-19 positive?</P1>
                            <CheckButton className="mt-1" items={covidPositive} setItems={setCovidPositive} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you ever have any surgeries or medical procedures?</P1>
                            <CheckButton className="mt-1" items={anySurgery} setItems={setAnySurgery} size="md" radio={true} />
                        </VBox>
                        
                        <VBox className="p-3">
                            <H5>Enter your body measurements:</H5>
                            <VBox className="mx-4">
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Current Height:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.height_ft}
                                        onChange={e => setNumeric('height_ft', e.target.value, 0, 10, 0)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">ft</P2>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.height_in}
                                        onChange={e => setNumeric('height_in', e.target.value, 0, 11.9, 1)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">in</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Current Weight:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.weight}
                                        onChange={e => setNumeric('weight', e.target.value, 0, 600, 1)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">kg</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>BMI (calculated):</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.bmi}
                                        style={{ width: isMobile ? 50 : 80 }}
                                        disabled
                                    />
                                    <P2 className="ml-0_5">kg/m^2</P2>
                                </HBox>
                            </VBox>
                        </VBox>

                        <VBox className="p-3">
                            <H5>Do you know your current vitals? (not mandatory)</H5>
                            <VBox className="mx-4">
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Blood Pressure:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        placeholder="Systolic"
                                        value={params.blood_pressure_systolic}
                                        onChange={e => setNumeric('blood_pressure_systolic', e.target.value, 0, 300, 0)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P1 className="ml-0_5">/</P1>
                                    <InputText
                                        type="number"
                                        className="ml-0_5"
                                        placeholder="Diastolic"
                                        value={params.blood_pressure_diastolic}
                                        onChange={e => setNumeric('blood_pressure_diastolic', e.target.value, 0, 200, 0)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">mmHg</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Temperature:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.temperature}
                                        onChange={e => setNumeric('temperature', e.target.value, 0, 110, 1)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">&deg;F</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Pulse:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.pulse}
                                        onChange={e => setNumeric('pulse', e.target.value, 0, 300, 0)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">BPM</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Blood Glucose:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.blood_glucose}
                                        onChange={e => setNumeric('blood_glucose', e.target.value, 0, 30, 2)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">mmol/L</P2>
                                </HBox>
                                <HBox align="center" className="mt-1">
                                    <P1 style={{ width: isMobile ? 150 : 200 }}>Pulse Oximetry:</P1>
                                    <InputText
                                        type="number"
                                        className="ml-1"
                                        value={params.pulse_oximetry}
                                        onChange={e => setNumeric('pulse_oximetry', e.target.value, 0, 100, 0)}
                                        style={{ width: isMobile ? 50 : 80 }}
                                    />
                                    <P2 className="ml-0_5">%SpO2</P2>
                                </HBox>
                            </VBox>
                        </VBox>
                        
                        <HBox justify="center" className="mt-4 mb-2">
                            <Button size="md" color="first" onClick={submit}>Submit</Button>
                        </HBox>
                        <HBox justify="flex-end" className="mx-2 mb-2">
                            <SLink to="/behavioral-health-history">
                                <Button size="sm" outlined>Next</Button>
                            </SLink>
                        </HBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </Container>
            {!isMobile && <Footer />}
        </div>
    );
}

export default HealthHistory;