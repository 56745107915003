import { useState } from "react";
import styled from "styled-components";
import { FiFileText, FiCalendar } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

import { VBox, HBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getDate, trimText } from "../../../utils";
import PrescriptionPDF from "../../../layouts/PrescriptionPDF";

const Container = styled.div`
    background-color: ${colors.veryLightGreen};
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.green};
    border-radius: 10px;
    /* box-shadow: 0px 1px 0px 0px ${colors.shadow}; */
    contain: content;

    :hover {
        box-shadow: 1px 1px 15px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 85%;
        height: auto;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 40%;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35%;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 25%;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 23%;
    }
`

const ColorBar = styled.div`
    height: 4px;
    width: 40%;
    border-radius: 0px 0px 4px 4px;
    background-color: ${colors.green};
`

const FileTxtIcon = styled(FiFileText)``

const Count = styled(P2)`
    padding: 2px;
    margin-left: 4px;
    color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 4px;
`

const CalIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const SLine = styled.div`
    width: 85%;
    border-bottom: 1px solid ${colors.green};
`

const SHBox = styled(HBox)`
    width: 100%;
`

const TxtBtn = styled(H6)`
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
    position: fixed;
    bottom: 24px;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        position: static;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const PrescriptionInd = ({ id, doctorName, qualification, specialty, date }) => {
    const [openPres, setOpenPres] = useState(false);

    return (
        <>
        <SHBox className="pl-4 pr-4">
            <H5 className="mr-1">{doctorName}</H5>
            <Tooltip title={qualification} arrow>
                <P2>{trimText(qualification, 32)}</P2>
            </Tooltip>
        </SHBox>
        <SHBox className="px-4 mb-0_5">
        <P2 color="second">{specialty}</P2>
        </SHBox>
        <SHBox justify="space-between" className="pl-4 pr-4 mb-2">
            <HBox>
                <CalIcon className="mr-1" />
                <P2 className="mr-2">{getDate(date)}</P2>
            </HBox>
            <TxtBtn color='fourth' onClick={() => setOpenPres(true)}>View</TxtBtn>
        </SHBox>

        <PrescriptionPDF
            open={openPres}
            setOpen={setOpenPres}
            prescriptionId={id}
        />
        </>
    )
}

const PrescriptionsCard = ({ isMobile, prescriptions }) => {
    return (
        <Container className="m-2">
            <ColorBar />
            <FileTxtIcon color={colors.darkGrey} fontSize={25} className="mt-1 mb-1" />
            <HBox align="center" className="mb-1">
                <H4>Prescriptions</H4>
                <Count>{prescriptions.count}</Count>
            </HBox>
            <SLine className="mb-2" />
            {prescriptions.data ?
                prescriptions.data.map((prescription, index) => (
                    <PrescriptionInd
                        id={prescription.id}
                        doctorName={prescription.doctor_name}
                        qualification={prescription.doctor_qualification}
                        specialty={prescription.doctor_specialty}
                        date={prescription.date}
                    />
                ))
                :
                <P2>No prescription.</P2>
            }
            <SLink to="/prescriptions">
                <Button size="sm" color="third" outlined>View All</Button>
            </SLink>
        </Container>
    )
}

export default PrescriptionsCard;