import { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { getDate, getTime } from "../../../utils";
import Confirm from "../../../layouts/Confirm";
import { setShowUppernav } from "../../../services/actions/authAction";
import SelectPGWDlg from "./SelectPGWDlg";

const Container = styled(VBox)`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 1px 1px 0px 0px ${colors.grey};

    :hover {
        box-shadow: 1px 1px 15px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }
`

const DateCard = styled(VBox)`
    position: relative;
    top: -30px;
    height: 60px;
    align-self: center;
    border: 1px solid ${colors.grey};
    border-radius: 30px;
    background-color: ${colors.lightGrey};
    z-index: 1;
    // box-shadow: 1px 1px 3px 0px ${colors.shadow};
`

const SlotContainer = styled(HBox)`
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    background-color: ${props => props.occupied ? colors.grey : colors.veryLightGreen};
    
    :hover {
        background-color: ${props => props.occupied ? colors.grey : colors.lightGrey};
    }
`

const SlotCard = ({ id, doctorId, date, day, timeSpan, isOccupied }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showBtn, setShowBtn] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSelectPGW, setOpenSelectPGW] = useState(false);

    const handleConfirm = () => {
        axios({
            method: 'POST',
            url: `patient/appointment-list/`,
            data: {
                doctor_id: doctorId,
                consult_slot_id: id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 201) {
                localStorage.setItem('nh-appointment-id', response.data.id);
                localStorage.setItem('nh-amount-payable', response.data.amount_payable);
                localStorage.setItem('nh-discount', null);
                setOpenSelectPGW(true);
            } else {
                console.log('APPOINTMENT CREATE FAILED', response.status);
            }
        })
        .catch((error) => {
            if (error.response.status === 409) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
            }
            console.log('APPOINTMENT CREATE ERROR', error);
        });
    }
    
    return (
        <Tooltip title={isOccupied ? 'Occupied' : ''} arrow>
            <SlotContainer
                className="px-2 py-1 m-0_5 clickable"
                align='center'
                occupied={isOccupied}
                onClick={(e) => {e.stopPropagation(); !isOccupied && setShowBtn(true)}}
            >
                {showBtn ?
                    <>
                        <VBox>
                            <P2>{timeSpan}</P2>
                            <H6>{date}</H6>
                            <P3 color='second'>{day}</P3>
                        </VBox>
                        <HBox onClick={(e) => e.stopPropagation()}>
                            <Button
                                size="sm"
                                color='third'
                                className="my-2 ml-2 mr-1"
                                onClick={() => setOpenConfirm(true)}
                            >
                                Proceed
                            </Button>
                            <Button size='sm' color='second' className="my-2 mr-2" onClick={() => setShowBtn(false)}>Close</Button>
                        </HBox>
                    </>
                    :
                    <P1>{timeSpan}</P1>
                
                }
                <Confirm
                    // message="Please confirm the booking. Your appointment status will be in pending state untill payment. We will contact you for payment from 01321119391."
                    message="Please confirm the booking."
                    btnTxt='Confirm'
                    btnColor="first"
                    onConfirm={handleConfirm}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                />

                <SelectPGWDlg
                    open={openSelectPGW}
                    setOpen={setOpenSelectPGW}
                />
            </SlotContainer>
        </Tooltip>
    )
}

const DayScheduleCard = ({ doctorId, date, day, consultSlots, availableSlotsCount }) => {
    return (
        <Container className="px-4 pb-4 mb-6">
            <DateCard className="px-2" align="center" justify="center">
                <H5>{date}</H5>
                <P2 color='second'>{day}</P2>
            </DateCard>
            <H4 className="ml-0_5 mb-1"><span style={{ color: colors.green }}>{availableSlotsCount}</span> available</H4>
            <HBox align='flex-start'>
                {consultSlots?.length ?
                    consultSlots?.map((slot, index) => (
                        <SlotCard
                            id={slot.id}
                            doctorId={doctorId}
                            date={date}
                            day={day}
                            timeSpan={`${getTime(slot.start_time)} - ${getTime(slot.end_time)}`}
                            isOccupied={slot.is_occupied}
                        />
                    ))
                    :
                    <P1>No slots.</P1>
                }
            </HBox>
        </Container>
    )
}

export default DayScheduleCard;