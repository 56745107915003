import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { FiSearch, FiCheck, FiFilter, FiChevronUp } from "react-icons/fi";
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import moment from "moment";
import { useSnackbar } from "notistack";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button } from "../../components/Buttons";
import { InputText } from "../../components/InputText";
import CheckButton from "../../components/CheckButton";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { specialtiesData } from "../../data";
import PrescriptionCard from "./components/PrescriptionCard";
import DateInput from "./components/DateInput";
import DoctorSearchDlg from "./components/DoctorSearchDlg";
import { PatchLeft } from "../../components/Patches";

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const CenterPanel = styled(VBox)``

const RightPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-left: 1px solid ${colors.grey};
`

const TopPanel = styled(VBox)`
    width: 100%;
    backgrouond-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const FilterIcon = styled(FiFilter)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const AscendIcon = styled(ImSortAmountAsc)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const DescendIcon = styled(ImSortAmountDesc)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const DoctorSearchBtn = styled.button`
    height: 2.5em;
    color: ${colors.darkGrey};
    display: flex;
    align-items: center;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    background-color: ${props => props.selected ? colors.veryLightGrey : 'white'};
    cursor: pointer;

    :hover {
        background-color: ${colors.veryLightGrey};
    }
`

const SpecialtySelect = styled.select`
    width: 180px;
    height: 2.5em;
    color: ${colors.darkGrey};
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    background-color: ${props => props.selected ? colors.veryLightGrey : 'white'};
    outline: none;
    cursor: pointer;

    :hover {
        background-color: ${colors.veryLightGrey};
    }
}
`

const Prescriptions = () => {
    const pageSize = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [prescriptions, setPrescriptions] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    
    const [isOpenDSearch, setIsOpenDSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showTopPanel, setShowTopPanel] = useState(false);

    const [order, setOrder] = useState([
        { text: "Date", checked: true },
        { text: "Doctor", checked: false }
    ]);
    const [filter, setFilter] = useState({
        fromDate: "",
        toDate: "",
        doctorId: "",
        specialty: "",
    });
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [filterBtns, setFilterBtns] = useState([
        { text: "Date", checked: true },
        { text: "Specialty", checked: false },
        { text: "Doctor", checked: false },
    ]);
    const [isAscending, setIsAscending] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => () => reloadPrescriptions(), [order, isAscending]);
    useEffect(() => () => {clearFilter(); reloadPrescriptions()}, [filterBtns]);
    
    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'patient/prescription-list/',
            params: {
                offset: offset,
                limit: limit,
                order_by: order[0].checked ? 'appointment__consult_slot__date' : 'appointment__doctor',
                order_direc: isAscending ? '' : '-',
                doctor_id: filter.doctorId,
                specialty: filter.specialty,
                from_date: filter.fromDate,
                to_date: filter.toDate,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setPrescriptions(prescriptions.concat(response.data.prescriptions));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('PRESCRIPTIONS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PRESCRIPTIONS FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleChangeFilter = (value, key) => {
        setFilter({...filter, [key]: value});
        if (value && key === 'fromDate' ? filter.toDate : filter.fromDate) {
            reloadPrescriptions();
        }
        else if (key === 'specialty' || key === 'doctorId') {
            reloadPrescriptions();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClearFilter = () => {
        // var values = [...filterBtns];
        // for (var i=0; i<values.length; i++) {
        //     if (i === 0) values[i].checked = true;
        //     else values[i].checked = false;
        // }
        // setFilterBtns(values);

        clearFilter();
        reloadPrescriptions();
    }

    const clearFilter = () => {
        var values = {...filter};
        Object.entries(values).map(([key, value]) => {
            values[key] = '';
        });
        setFilter(values);
    }

    const reloadPrescriptions = () => {
        setCount(null);
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setPrescriptions([]);
    }

    const filterByHtml = () => (
        <VBox className="p-2" style={{ width: isMobile ? 'auto' : '100%' }}>
            <H6 color="second" className="mb-0_5">Filter by</H6>
            <HBox align="center" justify={isMobile ? "flex-start" : "space-between"}>
                <CheckButton items={filterBtns} setItems={setFilterBtns} radio={true} />
                <Button
                    size="sm"
                    outlined
                    className={isMobile ? "ml-2" : ""}
                    style={{ height: 25, padding: "0px 0.5em 0px" }}
                    onClick={handleClearFilter}
                >
                    Clear
                </Button>
            </HBox>
            {filterBtns[0].checked && <VBox align={isMobile ? 'flex-start' : 'center'} className={isMobile ? 'mt-2' : 'mt-4'}>
                <DateInput
                    label="From"
                    value={filter.fromDate}
                    onChange={(e) => handleChangeFilter(e.target.value, 'fromDate')}
                />
                <HBox className="mt-1">
                    <DateInput
                        label="To"
                        value={filter.toDate}
                        onChange={(e) => handleChangeFilter(e.target.value, 'toDate')}
                    />
                </HBox>
            </VBox>}
            {filterBtns[1].checked && <VBox align='center' className="mt-4">
                <SpecialtySelect
                    className="px-1"
                    onChange={(e) => handleChangeFilter(e.target.value, 'specialty')}
                    selected={filter.specialty}
                >
                    <option value="" style={{ display: 'none' }}>No specialty selected</option>
                    {specialtiesData.map((specialty, index) => (
                        <option value={specialty} selected={filter.specialty === specialty}>{specialty}</option>
                    ))}
                </SpecialtySelect>
            </VBox>}
            {filterBtns[2].checked && <VBox align='center' className="mt-4">
                <DoctorSearchBtn
                    className="px-1"
                    onClick={() => setIsOpenDSearch(true)}
                    selected={filter.doctorId}
                >
                    {filter.doctorId ?
                        <><FiCheck className="mr-1" style={{ color: colors.green }} />{selectedDoctor}</>
                        :
                        <><FiSearch className="mr-1" style={{ color: colors.blue }} />Select a doctor</>
                    }
                </DoctorSearchBtn>
            </VBox>}
        </VBox>
    )

    const orderByHtml = () => (
        <VBox className="p-2" style={{ width: isMobile ? 'auto' : '100%' }}>
            <H6 color="second" className="mb-0_5">Order by</H6>
            <CheckButton items={order} setItems={setOrder} radio={true} />
        </VBox>
    )
    
    return (
        <div>
            <LowerNav selected="prescriptions" />
            <HBox>
                <PatchLeft />
                {(isMobile && showTopPanel) && <TopPanel>
                    <HBox>
                        {filterByHtml()}
                        {orderByHtml()}
                    </HBox>
                    <Button
                        size='sm'
                        className='mb-1'
                        style={{ alignSelf: 'center' }}
                        onClick={() => setShowTopPanel(false)}
                        outlined
                    >
                        <FiChevronUp className="mr-0_5" />
                        Hide
                    </Button>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    {filterByHtml()}
                    {orderByHtml()}
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        style={{ height: isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 150px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <HBox justify='space-between' className="mx-3 mt-2 mb-1">
                            <HBox>
                                {count !== null && <P2>Found <span className="bold" style={{ color: colors.darkGrey }}>{count}</span> prescriptions</P2>}
                                {count !== null &&
                                    filter.fromDate &&
                                    filter.toDate &&
                                    <P2 className="ml-0_5">
                                        from
                                        <span className="ml-0_5 mr-0_5 bold" style={{ color: colors.darkGrey }}>{moment(filter.fromDate, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                        to
                                        <span className="ml-0_5 bold" style={{ color: colors.darkGrey }}>{moment(filter.toDate, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                    </P2>
                                }
                                {count !== null &&
                                    filter.specialty &&
                                    <P2 className="ml-0_5">
                                        in <span className="bold" style={{ color: colors.darkGrey }}>{filter.specialty}</span>
                                    </P2>
                                }
                                {count !== null &&
                                    filter.doctorId &&
                                    <P2 className="ml-0_5">
                                        for <span className="bold" style={{ color: colors.darkGrey }}>{selectedDoctor}</span>
                                    </P2>
                                }
                            </HBox>
                            <HBox>
                                {isMobile && <FilterIcon className="mr-1" onClick={() => setShowTopPanel(true)} />}
                                {isAscending ?
                                    <AscendIcon onClick={() => setIsAscending(false)} />
                                    :
                                    <DescendIcon onClick={() => setIsAscending(true)} />
                                }
                            </HBox>
                        </HBox>
                        {prescriptions.length ?
                            prescriptions.map((prescription, index) => (
                                <PrescriptionCard
                                    isMobile={isMobile}
                                    id={prescription.id}
                                    name={prescription.doctor_name}
                                    qualification={prescription.doctor_qualification}
                                    specialty={prescription.doctor_specialty}
                                    date={prescription.date}
                                    doctorIsOnline={prescription.doctor_is_online}
                                />
                            ))
                            :
                            !isLoading && <VBox align='center' justify='center' style={{ width: '100%', height: '90%' }}>
                                <img src='images/notfound.png' style={{ height: 80 }} />
                                <H3 className="mt-2">No Prescriptions</H3>
                            </VBox>
                        }
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </HBox>
            {!isMobile && <Footer />}

            <DoctorSearchDlg
                open={isOpenDSearch}
                setOpen={setIsOpenDSearch}
                selectedId={filter.doctorId}
                setSelectedDoctor={setSelectedDoctor}
                handleChangeFilter={handleChangeFilter}
            />
        </div>
    )
}

export default Prescriptions;