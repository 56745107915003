import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { MdOutlineDashboard } from "react-icons/md";
import {
    FiCalendar, FiFileText, FiMail,
    FiFilePlus, FiHeart, FiUser,
    FiMenu,
    FiLogOut,
    FiFile,
    FiMoreHorizontal,
} from "react-icons/fi";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";
import { Drawer, Menu, MenuItem, Divider } from '@mui/material';
import { MdOutlineNotificationsNone } from "react-icons/md";
import { useSnackbar } from "notistack";
import useSound from 'use-sound';

import { HBox, VBox } from "../components/Containers";
import { H3, H4, H5, P1, P2, P3 } from "../components/Typography";
import { Button } from "../components/Buttons";
import colors from "../config/colors";
import responsive from '../config/responsive';
import { removeHttp } from "../utils";
import { logout } from "../services/actions/authAction";
import {
    setNewMessage, setNewOnlineStatusChange, setCallState,
} from "../services/actions/chatAction";
import NotificationsDlg from "./NotificationsDlg";
import TermsAndConditionsDlg from "./TermsAndConditionsDlg";
import PrivacyPoilcyDlg from "./PrivacyPolicyDlg";
import RefundPoilcyDlg from "./RefundPolicyDlg";
import ringTone from '../assets/sounds/ring_tone.mp3';
import logoMain from '../assets/images/logo_main.png';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

/* ********************* Incoming call ************************ */
const AnsDlgContainer = styled(VBox)`
    border-radius: 10px;
    // background-color: ${colors.lightBlue};
    background: linear-gradient(182deg, ${colors.lightGrey}, ${colors.lightBlue});
    box-shadow: 0px 5px 10px 0px ${colors.shadow};
    width: 300px;

    position: absolute;
    top: calc(50vh - 150px);
    left: calc(50vw - 150px);
    z-index: 2;
`

const Image = styled.img`
    height: 80px;
    width: 80px;
    border-radius: 40px;
    border: 1px solid ${colors.grey};
    background-color: white;
`

const SLink = styled(Link)`
    text-decoration: none;
`
/* ****************** End incoming call *********************** */

/* ************************ Upper nav *********************** */
const LogoutBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    /* text-decoration: none; */
`

const NavbarUp = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`

const NotificationIcon = styled(MdOutlineNotificationsNone)`
    font-size: 24px;
    color: ${colors.darkGrey};
`

const NotifCount = styled(P3)`
    position: relative;
    top: -8px;
    left: -5px;
    border-radius: 10px;
    background-color: ${colors.blue};
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
`

const A = styled.a`
    text-decoration: none;
`

const NotificationBadge = ({ count, setCount, className }) => {
    const [openNotif, setOpenNotif] = useState(false);

    return (
        <>
        <HBox className={className} align='center'>
            <NotificationIcon className="clickable" onClick={() => setOpenNotif(true)} />
            {count ? <NotifCount>
                {count}
            </NotifCount> : null}
        </HBox>

        <NotificationsDlg
            open={openNotif}
            setOpen={setOpenNotif}
            setNotifCount={setCount}
        />
        </>
    )
}

let callSocket;

const _UpperNav = ({ isAuthenticated, callState,
                     setNewMessage, setNewOnlineStatusChange, setCallState,
                     logout, style }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [userId, setUserId] = useState("");
    const [caller, setCaller] = useState({
        id: "", name: "", image: ""
    });
    const [newNotification, setNewNotification] = useState(null);
    const [notifCount, setNotifCount] = useState(null);
    const [promptAnsDialog, setPromptDialog] = useState(0);
    const [showAnsDialog, setShowAnsDialog] = useState(false);
    // const [ring, setRing] = useState(new Audio(process.env.REACT_APP_RING_TONE_URL));
    const [play, { stop }] = useSound(ringTone, { interrupt: true });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (isAuthenticated) getUserData();
    }, [isAuthenticated]);
    
    useEffect(() => {
        if (showAnsDialog) {
            // play();
            // Called in useEffect
        }
        else {
            stop();
        }
    }, [showAnsDialog]);

    useEffect(() => {
        if (newNotification?.type === 'zoom_invitation' && !isMobile) {
            enqueueSnackbar(<HBox align='center'>
                            <P2 color='white' className="mr-1">{newNotification.text}</P2>
                            <Button size='sm' color='first' onClick={handleJoinZoom}>Join</Button>
                        </HBox>, { style: {  }, persist: true });
        }
    }, [newNotification]);

    useEffect(() => {
        if (callState !== 'INCALL' && callState !== 'CONNECTING' && promptAnsDialog) {
            setShowAnsDialog(true);
            play();

            // If call prompt not arrive in 20 sec, will close the callilng dialog
            setTimeout(() => {
                setShowAnsDialog(false);
            }, 20000);
        }
    }, [promptAnsDialog]);

    function getUserData() {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            if (res.status === 200) {
                setUserId(res.data.id);
                setNotifCount(res.data.unseen_notif_count);
                connectSocket(res.data.id);
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    function connectSocket(id) {
        let ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
        
        callSocket = new WebSocket(
            ws_scheme
            + removeHttp(process.env.REACT_APP_SERVER_URL)
            // + window.location.host
            + 'ws/chat/'
        );
        
        callSocket.onopen = event => {
            callSocket.send(JSON.stringify({
                type: 'login',
                data: {
                    id: id
                }
            }));
        }
    
        callSocket.onmessage = (e) => {
            let response = JSON.parse(e.data);
            let type = response.type;

            if(type === 'connection') {
                console.log(response.data.message);

            }
    
            else if(type === 'call_received') {
                onNewCall(response.data);
            }

            else if(type == 'dialing_closed') {
                setShowAnsDialog(false);
            }

            else if(type === 'call_ended') {
                onEndCall(response.data);
            }

            else if (type === 'message_received') {
                onNewMessage(response.data);
            }

            else if (type === 'notification_received') {
                onNewNotification(response.data);
            }

            else if (type === 'onlinestatus_changed') {
                onNewOnlineStatusChange(response.data);
            }
        }
    
        const onNewCall = (data) => {
            //when other called you
            //show answer button
    
            setCaller({...caller, id: data.caller_id, name: data.caller_name, image: data.caller_image});
            // setRemoteRTCMessage(data.rtcMessage);

            // setCallState(null);
            // setShowAnsDialog(true);
            setPromptDialog(state => state + 1);

            // Send acknowledgement
            callSocket.send(JSON.stringify({
                type: 'call_ack',
                data: {
                    caller_id: data.caller_id
                }
            }));
            
            console.log("Call received.");
        }

        const onEndCall = (data) => {
            setShowAnsDialog(false);

            console.log("Call ended.");
        }

        const onNewMessage = (data) => {
            setNewMessage(data);
        }

        const onNewNotification = (data) => {
            setNewNotification(data);
            setNotifCount(data.unseen_count);
        }

        const onNewOnlineStatusChange = (data) => {
            setNewOnlineStatusChange(data);
        }
    }

    function handleReject() {
        setShowAnsDialog(false);

        callSocket.send(JSON.stringify({
            type: 'reject_call',
            data: {
                caller_id: caller.id
            }
        }));
    }

    const handleLogout = () => {
        callSocket?.close();
        logout();
        navigate('/login');
    }

    const handleJoinZoom = () => {
        closeSnackbar();
        const { patientName, meetingNumber, password } = JSON.parse(newNotification.data);

        window.open(`${process.env.REACT_APP_ZOOM_MEETING_URL}p/${meetingNumber}/${password}/${patientName}`);
    }

    // const playSound = (url) => {
    //     const audio = new Audio(url);
    //     audio.play();
    // }

    return (
        <>
        <NavbarUp style={style}>
            <HBox align="center">
                <img
                    style={{ height: 45 }}
                    className="ml-1 clickable"
                    src={logoMain}
                    alt="logo"
                    onClick={() => {window.open(process.env.REACT_APP_MYHEALTH_WEBSITE_URL)}}
                />
                {/* <H3 className='ml-2'>NextGen MyHealth</H3>
                <P2 className="ml-1" style={{ marginBottom: 2 }}>Patient Portal</P2> */}
            </HBox>
            <HBox align='center'>
                <NotificationBadge count={notifCount} setCount={setNotifCount} className="mr-2" />
                <LogoutBtn onClick={handleLogout}>
                    <FiLogOut />
                    <P1 className='mr-2 ml-1'>Logout</P1>
                </LogoutBtn>
            </HBox>
        </NavbarUp>
        {showAnsDialog && <AnsDlgContainer className="mb-8 mr-4 p-4" align="center">
            <H3 className="mb-4">Incoming Video Call</H3>
            <Image
                className="mb-1"
                src={`${baseURL}${caller.image}`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src="/images/noImage.svg";
                }}
            />
            <H5 className="mb-4">{caller.name}</H5>
            <HBox justify="space-around" style={{ width: "100%" }}>
                <Button color="second" size="sm" className="mr-4" onClick={handleReject}>Reject</Button>
                <SLink
                    // target='_blank'
                    to={`/call/${caller.id}/${userId}`}
                    // state={{ remoteRTCMessage: remoteRTCMessage }}
                >
                    <Button color="third" size="sm" onClick={() => setShowAnsDialog(false)}>Accept</Button>
                </SLink>
            </HBox>
        </AnsDlgContainer>}
        </>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    callState: state.chat.callState,
});

export const UpperNav = connect(mapStateToProps, { setNewMessage,
                                                   setNewOnlineStatusChange, setCallState,
                                                   logout })(_UpperNav);
/* ****************** End upper nav ************************ */

/* ******************** Lower nav ************************** */
const LowerNavContainer = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    display: flex;
    justify-content: center;
    z-index: 1;
`

const NavbarLow = styled.div`
    width: 60%;
    height: 60px;
    display: flex;
    background-color: ${colors.blue};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: stretch;
    justify-content: space-around;
`

const SNavLink = styled(NavLink)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    :hover {
        border-bottom: 3px solid white;
    }
    :active {
        border-bottom: 3px solid white;
    }
`

const NavItemContainerMobile = styled.div`
    // width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
`

const NavbarLowMobile = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    background-color: ${colors.blue};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    // align-items: stretch;
    justify-content: space-between;
`

const SNavLinkMobile = styled(NavLink)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
`

const _LowerNav = ({ selected, newMessage, setNewMessage }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isMobile, setIsMobile] = useState(false);
    const [openMenuDrawer, setOpenMenuDrawer] = useState(false);

    const [anchorElMore, setAnchorElMore] = useState(null);
    const openMore = Boolean(anchorElMore);
    const handleClickMoreMenu = (to) => {
        navigate(to);

        setAnchorElMore(null);
    };

    const [openTNC, setOpenTNC] = useState(false);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const [openRefundPolicy, setOpenRefundPolicy] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (newMessage) {
            if (selected !== 'messages') {
                enqueueSnackbar(<HBox align='center'>
                                    <P2 color='white' className="mr-1">You have a new message.</P2>
                                    <Button size='sm' color='first' onClick={() => navigate('/messages')}>View</Button>
                                </HBox>, { style: {  }, persist: true });
            }
            setNewMessage(null);
        }
        
    }, [newMessage]);

    const getSelectedTitle = () => {
        if (selected === 'dashboard') {
            return (
                <HBox align='center'>
                    <MdOutlineDashboard fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Dashboard</H4>
                </HBox>
            );
        }
        else if (selected === 'upcoming-appointments') {
            return (
                <HBox align='center'>
                    <FiCalendar fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Appointments</H4>
                </HBox>
            );
        }
        else if (selected === 'prescriptions') {
            return (
                <HBox align='center'>
                    <FiFileText fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Prescriptions</H4>
                </HBox>
            );
        }
        else if (selected === 'health-records') {
            return (
                <HBox align='center'>
                    <FiFilePlus fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Health Records</H4>
                </HBox>
            );
        }
        else if (selected === 'health-history') {
            return (
                <HBox align='center'>
                    <FiHeart fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Health Profile</H4>
                </HBox>
            );
        }
        else if (selected === 'messages') {
            return (
                <HBox align='center'>
                    <FiMail fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Messages</H4>
                </HBox>
            );
        }
        else if (selected === 'account') {
            return (
                <HBox align='center'>
                    <FiUser fontSize='1.5rem' />
                    <H4 color='white' className='ml-1'>Account</H4>
                </HBox>
            );
        }
        else {
            return null;
        }
    }

    return (
        <LowerNavContainer>
            {!isMobile ?
                <>
                <NavbarLow>
                    <SNavLink to='/dashboard' style={{ borderBottom: selected==='dashboard' ? '3px solid white' : "" }}>
                        <MdOutlineDashboard fontSize='1.5rem' />
                        <P2 color='white'>Dashboard</P2>
                    </SNavLink>
                    <SNavLink to='/upcoming-appointments' style={{ borderBottom: selected==='upcoming-appointments' ? '3px solid white' : "" }}>
                        <FiCalendar fontSize='1.5rem' />
                        <P2 color='white'>Appointments</P2>
                    </SNavLink>
                    <SNavLink to="/prescriptions" style={{ borderBottom: selected==='prescriptions' ? '3px solid white' : "" }}>
                        <FiFileText fontSize='1.5rem' />
                        <P2 color='white'>Prescriptions</P2>
                    </SNavLink>
                    <SNavLink to="/health-records" style={{ borderBottom: selected==='health-records' ? '3px solid white' : "" }}>
                        <FiFilePlus fontSize='1.5rem' />
                        <P2 color='white'>Health Records</P2>
                    </SNavLink>
                    <SNavLink
                        to='#'
                        style={{ borderBottom: selected==='health-history' || selected==='messages' || selected==='account' ? '3px solid white' : "" }}
                        onClick={(e) => setAnchorElMore(e.currentTarget)}
                    >
                        <FiMoreHorizontal fontSize='1.5rem' />
                        <P2 color='white'>More</P2>
                    </SNavLink>
                    {/* <SNavLink to="/health-history" style={{ borderBottom: selected==='health-history' ? '3px solid white' : "" }}>
                        <FiHeart fontSize='1.5rem' />
                        <P2 color='white'>Health Profile</P2>
                    </SNavLink> */}
                    {/* <SNavLink to="/messages" style={{ borderBottom: selected==='messages' ? '3px solid white' : "" }}>
                        <FiMail fontSize='1.5rem' />
                        <P2 color='white'>Messages</P2>
                    </SNavLink>
                    <SNavLink to="/account" style={{ borderBottom: selected==='account' ? '3px solid white' : "" }}>
                        <FiUser fontSize='1.5rem' />
                        <P2 color='white'>Account</P2>
                    </SNavLink> */}
                </NavbarLow>
                <Menu
                    anchorEl={anchorElMore}
                    open={openMore}
                    onClose={() => setAnchorElMore(null)}
                >
                    <MenuItem onClick={() => handleClickMoreMenu('/health-history')}>
                        <FiHeart className="mr-1" style={{ color: colors.blue }} />
                        Health Profile
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMoreMenu('/messages')}>
                        <FiMail className="mr-1" style={{ color: colors.blue }} />
                        Messages
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMoreMenu('/account')}>
                        <FiUser className="mr-1" style={{ color: colors.blue }} />
                        Account
                    </MenuItem>
                </Menu>
                </>
                :
                <>
                    <NavbarLowMobile>
                        <NavItemContainerMobile className="ml-2">
                            <FiMenu
                                fontSize='1.5rem'
                                onClick={() => setOpenMenuDrawer(true)}
                            />
                        </NavItemContainerMobile>
                        <NavItemContainerMobile className='mr-2'>
                            {getSelectedTitle()}
                        </NavItemContainerMobile>
                    </NavbarLowMobile>

                    <Drawer
                        anchor='left'
                        open={openMenuDrawer}
                        onClose={() => setOpenMenuDrawer(false)}
                    >
                        <VBox
                            onClick={() => setOpenMenuDrawer(false)}
                            className='p-2'
                        >
                            <SNavLinkMobile className="p-1" to='/dashboard'>
                                <MdOutlineDashboard fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Dashboard</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/upcoming-appointments'>
                                <FiCalendar fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Appointments</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/prescriptions'>
                                <FiFileText fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Prescriptions</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/health-records'>
                                <FiFilePlus fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Health Records</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/health-history'>
                                <FiHeart fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Health Profile</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/messages'>
                                <FiMail fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Messages</P1>
                            </SNavLinkMobile>
                            <SNavLinkMobile className="p-1" to='/account'>
                                <FiUser fontSize='1.5rem' color={colors.blue} />
                                <P1 className="ml-1">Account</P1>
                            </SNavLinkMobile>
                            <Divider style={{ marginTop: 8 }} />
                            <A className='mt-1' href={process.env.REACT_APP_USER_MANUAL_URL} target='_blank'>
                                <P2>
                                    User Manual
                                </P2>
                            </A>
                            <P2
                                className="mt-1 clickable"
                                onClick={() => setOpenTNC(true)}
                            >
                                Terms & Conditions
                            </P2>
                            <P2
                                className="mt-1 clickable"
                                onClick={() => setOpenPrivacyPolicy(true)}
                            >
                                Privacy Polity
                            </P2>
                            <P2
                                className="mt-1 clickable"
                                onClick={() => setOpenRefundPolicy(true)}
                            >
                                Refund Policy
                            </P2>
                        </VBox>
                    </Drawer>

                    <TermsAndConditionsDlg
                        open={openTNC}
                        setOpen={setOpenTNC}
                    />
                    <PrivacyPoilcyDlg
                        open={openPrivacyPolicy}
                        setOpen={setOpenPrivacyPolicy}
                    />
                    <RefundPoilcyDlg
                        open={openRefundPolicy}
                        setOpen={setOpenRefundPolicy}
                    />
                </>
            }
        </LowerNavContainer>
    )
}

const mapStateToProps1 = state => ({
    newMessage: state.chat.newMessage,
});

export const LowerNav = connect(mapStateToProps1, { setNewMessage })(_LowerNav);
/* ****************** End lower nav *************************** */
