import { useState, useEffect } from "react";
import styled from "styled-components";
import { FiCalendar, FiClock, FiMail, FiUser } from "react-icons/fi";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import WriteMessageDlg from "../../../layouts/WriteMessageDlg";
import { getTime, trimText } from "../../../utils";
import { OnlineChip, OfflineChip } from "../../../components/Chips";
import SelectPGWDlg from "../../AppointmentSchedule/components/SelectPGWDlg";
import Confirm from "../../../layouts/Confirm";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    background-color: ${colors.veryLightGrey};
`

const Image = styled.img`
    height: 80px;
    width: 80px;
    border-radius: 40px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const TimeIcon = styled(FiClock)`
    color: ${colors.red};
`

const SLink = styled(Link)`
    text-decoration: none;
    color: white;
`

const AppointmentCard = ({ isMobile, index, id, doctorId, doctorUserId, name, image, qualification,
                           specialty, affiliationSummary, date, startTime, endTime,
                           paymentStatus, amountPayable, discount, doctorIsOnline,
                           newOnlineStatusChange }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar(); 
    const [openWriteMsg, setOpenWriteMsg] = useState(false);
    const [openSelectPayment, setOpenSelectPayment] = useState(false);
    const [openConfirmCancelAppoint, setOpenConfirmCancelAppoint] = useState(false);
    const [docIsOnline, setDocIsOnline] = useState(doctorIsOnline);

    useEffect(() => {
        if (newOnlineStatusChange) {
            if (doctorUserId == newOnlineStatusChange.user_id) {
                setDocIsOnline(newOnlineStatusChange.is_online);
            }
        }
    }, [newOnlineStatusChange]);
    useEffect(() => {setDocIsOnline(doctorIsOnline)}, [doctorIsOnline]);

    const handleClickCompletePayment = () => {
        localStorage.setItem('nh-appointment-id', id);
        localStorage.setItem('nh-amount-payable', amountPayable);
        localStorage.setItem('nh-discount', discount);

        setOpenSelectPayment(true);
    }

    const handleConfirmCancelAppoint = () => {
        enqueueSnackbar("Cancelling the appointment... Please wait a moment.", { persist: true });
        axios({
            method: 'DELETE',
            url: 'patient/appointment-details/',
            params: {
                appointment_id: id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                closeSnackbar();
                if (response.status === 200) {
                    enqueueSnackbar('Appointment cancelled.', { variant: 'success' });
                    window.location.reload();
                } else {
                    console.log('APPOINTMENT DELETE FAILED', response.status);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            })
            .catch((error) => {
                console.log('APPOINTMENT DELETE ERROR', error);
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            })
    }

    return (
        <Container className={`${isMobile ? "p-2" : 'p-4'} mb-2`}>
            <HBox align="flex-start" className="mb-2">
                <Image
                    src={`${baseURL}${image}`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="/images/noImage.svg";
                    }}
                />
                <VBox className="mx-1" style={{ width: isMobile ? 'calc(100% - 116px)' : 'calc(100% - 276px)' }}>
                    <HBox align='center'>
                        <H4 className="bold">{name}</H4>
                        {docIsOnline ?
                            <OnlineChip className="ml-0_5"><H6 color='white'>Online</H6></OnlineChip>
                            :
                            <OfflineChip className="ml-0_5"><H6 color='white'>Offline</H6></OfflineChip>
                        }
                    </HBox>
                    <Tooltip title={qualification} arrow>
                        <P3>{trimText(qualification, 64)}</P3>
                    </Tooltip>
                    <P2 color='second'>{specialty}</P2>
                    {affiliationSummary.length ?
                        <P2 className="mt-1">{affiliationSummary[0].designation}, {affiliationSummary[0].institution}</P2>
                        :
                        <></>
                    }
                </VBox>
                {!isMobile && <VBox justify='space-around' style={{ width: 180, height: '100%' }}>
                    <Button
                        color="second"
                        size="sm"
                        onClick={() => setOpenWriteMsg(true)}
                    >
                        <FiMail className="mr-1" />
                        Message
                    </Button>
                    <Button color="first" size="sm" className="mt-1">
                        <SLink to={`/doctor/${doctorId}`}>
                            <FiUser className="mr-1" />
                            Doctor Profile
                        </SLink>
                    </Button>
                </VBox>}
            </HBox>
            <Line />
            <HBox justify="center" className={isMobile ? "p-2" : "p-3"}>
                <HBox align="center" className="my-0_5">
                    <DateIcon />
                    <P2 style={{ marginLeft: 4 }}>{moment(date).format('D MMMM YYYY')}</P2>
                </HBox>
                <HBox align="center" className="ml-2 my-0_5">
                    <TimeIcon />
                    <P2 style={{ marginLeft: 4 }}>
                        {getTime(startTime)} - {getTime(endTime)}
                    </P2>
                </HBox>
            </HBox>
            <Line />
            {isMobile && <HBox justify="space-around" className="mt-2">
                <Button
                    color="second"
                    size="sm"
                    className="m-0_5"
                    onClick={() => setOpenWriteMsg(true)}
                >
                    <FiMail className="mr-1" />
                    Message Doctor
                </Button>
                <Button color="first" size="sm" className="m-0_5">
                    <SLink to={`/doctor/${doctorId}`}>
                        <FiUser className="mr-1" />
                        Doctor Profile
                    </SLink>
                </Button>
            </HBox>}
            
            {paymentStatus === 'Pending' ?
                <VBox style={{ alignSelf: 'center' }} align="center" className="mt-3">
                    <HBox align='center'>
                        {/* <H6 color="second">Your payment is pending.</H6> */}
                        <Button
                            color='second'
                            size='sm'
                            className=""
                            outlined
                            onClick={handleClickCompletePayment}
                        >
                            <span className="bold">Complete Payment</span>
                        </Button>
                    </HBox>
                    <H6 className="mt-1 bold">Total amount payable: ৳ {amountPayable}</H6>
                    <P3
                        className="clickable underline mt-0_5"
                        onClick={() => setOpenConfirmCancelAppoint(true)}
                    >
                        Cancel Appointment
                    </P3>
                </VBox>
                :
                <H6 align="center" color='second' className="mt-2">Doctor will call you according to schedule. Please stay logged in at your scheduled time.</H6>
            }

            <WriteMessageDlg
                userId={doctorUserId}
                open={openWriteMsg}
                setOpen={setOpenWriteMsg}
            />
            <SelectPGWDlg
                open={openSelectPayment}
                setOpen={setOpenSelectPayment}
            />
            <Confirm
                message='Are you sure to cancel the appointment?'
                open={openConfirmCancelAppoint}
                setOpen={setOpenConfirmCancelAppoint}
                onConfirm={handleConfirmCancelAppoint}
                btnColor='second'
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    newOnlineStatusChange: state.chat.newOnlineStatusChange,
});

export default connect(mapStateToProps, {  })(AppointmentCard);
