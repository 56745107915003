import {
    SET_NEW_MESSAGE,
    SET_CALL_STATE,
    SET_NEW_ONLINE_STATUS_CHANGE,
} from './types';

export const setNewMessage = (message) => dispatch => {
    dispatch({
        type: SET_NEW_MESSAGE,
        payload: message,
    });
}

export const setCallState = (state) => dispatch => {
    dispatch({
        type: SET_CALL_STATE,
        payload: state,
    });
}

export const setNewOnlineStatusChange = (data) => dispatch => {
    dispatch({
        type: SET_NEW_ONLINE_STATUS_CHANGE,
        payload: data
    });
}