import { useState } from "react";
import styled from "styled-components";
import { FiCalendar, FiClock, FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import { getTime, getDate, trimText } from "../../../utils";
import WriteMessageDlg from "../../../layouts/WriteMessageDlg";
import PrescriptionPDF from "../../../layouts/PrescriptionPDF";
import UploadRecordDlg from "../../../layouts/UploadRecordDlg";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    background-color: ${colors.veryLightGrey};
`

const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const TimeIcon = styled(FiClock)`
    color: ${colors.red};
`

const SLink = styled(Link)`
    text-decoration: none;
    color: black;
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

const AppointmentCard = ({ isMobile, id, prescriptionId, doctorId, doctorUserId, name,
                           image, qualification, specialty, affiliationSummary,
                           date, startTime, endTime, doctorIsOnline }) => {
    const [openWriteMsg, setOpenWriteMsg] = useState(false);
    const [openPres, setOpenPres] = useState(false);
    const [openUploadRecord, setOpenUploadRecord] = useState(false);

    const [anchorElExpand, setAnchorElExpand] = useState(null);
    const openExpand = Boolean(anchorElExpand);
    const handleClickExpand = (event) => {
        setAnchorElExpand(event.currentTarget);
    };
    
    return (
        <Container className="mb-2">
            <HBox align="flex-start" className="p-2">
                <Image
                    src={`${baseURL}${image}`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="/images/noImage.svg";
                    }}
                />
                <VBox className="ml-1" style={{ width: 'calc(100% - 68px)' }}>
                    <HBox align='center'>
                        <H5>{name}</H5>
                        {!isMobile && <Tooltip title={qualification} arrow>
                            <P3 className="ml-1">{trimText(qualification, 48)}</P3>
                        </Tooltip>}
                        {doctorIsOnline ? <Tooltip title='Online' arrow><OnlineIcon className="ml-0_5" /></Tooltip> : null}
                    </HBox>
                    <P2 color='second'>{specialty}</P2>
                    <HBox className="mt-1"> 
                        <P2>Consulted on</P2>
                        <HBox align="center" className="ml-1">
                            <DateIcon />
                            <P2 className="ml-0_5">{getDate(date, 'medium')}</P2>
                        </HBox>
                        <HBox align="center" className="ml-1">
                            <TimeIcon />
                            <P2 className="ml-0_5">
                                {getTime(startTime)}
                            </P2>
                            {!isMobile && <P2 className="ml-0_5">
                                - {getTime(endTime)}
                            </P2>}
                        </HBox>
                    </HBox>
                </VBox>
            </HBox>
            <Line />
            <HBox className="p-1">
                <Button
                    size={isMobile ? 'xs' : 'sm'}
                    className='m-0_5'
                    outlined
                    onClick={() => setOpenPres(true)}
                >
                    Prescription
                </Button>
                <Button
                    size={isMobile ? 'xs' : 'sm'}
                    className='m-0_5'
                    outlined
                    onClick={() => setOpenUploadRecord(true)}
                >
                    Upload Health Records
                </Button>
                {!isMobile ?
                    <>
                        <Button
                            size={isMobile ? 'xs' : 'sm'}
                            className='m-0_5'
                            outlined
                            onClick={() => setOpenWriteMsg(true)}
                        >
                            Message
                        </Button>
                        <SLink to={`/doctor/${doctorId}`}>
                            <Button
                                size={isMobile ? 'xs' : 'sm'}
                                className='m-0_5'
                                outlined
                            >
                                Doctor Profile
                            </Button>
                        </SLink>
                        <SLink to={`/appointment-schedule/${doctorId}`}>
                            <Button
                                size="sm"
                                className='m-0_5'
                                outlined
                            >
                                Book Again
                            </Button>
                        </SLink>
                    </>
                    :
                    <>
                        <Button
                            size={isMobile ? 'xs' : 'sm'}
                            className='m-0_5'
                            outlined
                            onClick={handleClickExpand}
                        >
                            <FiChevronDown className="mr-0_5" />
                            more
                        </Button>

                        <Menu
                            anchorEl={anchorElExpand}
                            open={openExpand}
                            onClose={() => setAnchorElExpand(null)}
                            onClick={() => setAnchorElExpand(null)}
                        >
                            <MenuItem onClick={() => setOpenWriteMsg(true)}>Message</MenuItem>
                            <SLink to={`/doctor/${doctorId}`}>
                                <MenuItem>Doctor Profile</MenuItem>
                            </SLink>
                            <SLink to={`/appointment-schedule/${doctorId}`}>
                                <MenuItem>Book Again</MenuItem>
                            </SLink>
                        </Menu>
                    </>
                }
                
            </HBox>

            <WriteMessageDlg
                userId={doctorUserId}
                open={openWriteMsg}
                setOpen={setOpenWriteMsg}
            />
            <PrescriptionPDF
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={prescriptionId}
            />
            <UploadRecordDlg
                open={openUploadRecord}
                setOpen={setOpenUploadRecord}
            />
        </Container>
    )
}

export default AppointmentCard;