import styled from "styled-components";
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { FiChevronRight } from "react-icons/fi";
import { Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { trimText } from "../../../utils";
import { OnlineChip } from "../../../components/Chips";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};

    :hover {
        box-shadow: 0px 1px 8px 0px ${colors.shadow};
        transition: box-shadow 300ms;
    }
`

const Image = styled.img`
    height: 70px;
    width: 70px;
    border-radius: 35px;
    /* border: 2px solid ${colors.grey}; */
    margin-bottom: 4px;
`

const BtnIcon = styled(FiChevronRight)`
    margin-left: 4px;
`

const ProceedButton = styled(Button)`
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover ${BtnIcon} {
        margin-left: 8px;
        transition: margin-left 300ms;
    }
`

const SLink = styled(Link)`
    text-decoration: none;
`

const DoctorCard = ({ isMobile, id, name, image, doctorType, bmdc, qualification, experience, specialty,
                      rating, affiliationSummary, fee, isOnline }) => {
    return (
        <Container className="p-2 m-1" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <HBox>
                <VBox align="center">
                    <Image
                        src={`${baseURL}${image}`}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src="/images/noImage.svg";
                        }}
                    />
                    <P4>BMDC No.</P4>
                    <H6>{doctorType === "MBBS" ? "A-" : ""}{bmdc}</H6>
                </VBox>
                <VBox justify='space-between' className="ml-2" style={{ width: isMobile ? 'calc(100% - 86px)' : 'auto' }}>
                    <HBox align="center">
                        <H4 className="bold">{name}</H4>
                        <Tooltip title={qualification} arrow>
                            <P3 style={{ marginLeft: 4 }}>{trimText(qualification, 64)}</P3>
                        </Tooltip>
                        {isOnline ? <OnlineChip className='ml-0_5'><H6 className="" color='white'>Online</H6></OnlineChip> : null}
                    </HBox>
                    <P2 color='second' className="">{specialty}</P2>
                    {/* <HBox align='flex-end' className="mb-1"><Rating value={rating} style={{ fontSize: 16 }} readOnly /><P2 className="ml-1">{rating}</P2></HBox> */}
                    {experience ?
                        <P2 color='first' className="mb-1">{experience} experience</P2>
                        :
                        <P2 className='mb-1'></P2>
                    }
                    {affiliationSummary?.length ?
                        <P2>
                            {affiliationSummary[0].designation}, {affiliationSummary[0].institution}
                        </P2>
                        :
                        <></>
                    }
                </VBox>
            </HBox>
            {!isMobile ?
                <VBox justify="space-between" align="center" className="p-1 pl-2" style={{ width: 160, borderLeft: `1px solid ${colors.grey}` }}>
                    <H4 color='third' className="mb-1">৳ {fee}</H4>
                    <SLink to={`/doctor/${id}`}>
                        <ProceedButton color='second'>
                            Proceed
                            <BtnIcon />
                        </ProceedButton>
                    </SLink>
                </VBox>
                :
                <HBox align='center' justify='space-between' className="p-1 pt-2 mt-2" style={{ width: '100%', borderTop: `1px solid ${colors.grey}` }}>
                    <H4 color='third'>৳ {fee}</H4>
                    <SLink to={`/doctor/${id}`}>
                        <ProceedButton color='second' size='sm'>
                            Proceed
                            <BtnIcon />
                        </ProceedButton>
                    </SLink>
                </HBox>
            }

        </Container>
    )
}

export default DoctorCard;
