import { useState } from "react";
import styled from "styled-components";
import { FiCalendar, FiTrash2, FiFileText, FiDownload } from "react-icons/fi";
import moment from "moment";
import { Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import { trimText } from "../../../utils";
import PrescriptionPDF from "../../../layouts/PrescriptionPDF";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    background-color: ${colors.veryLightGrey};
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
`

const Image = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const DateIcon = styled(FiCalendar)`
    color: ${colors.blue};
`

const DeleteIcon = styled(FiTrash2)`
    color: ${colors.red};
`

const ShowIcon = styled(FiFileText)`
    color: ${colors.blue};
    cursor: pointer;
    font-size: 20px;
`

const DownloadIcon = styled(FiDownload)`
    color: ${colors.green};
    cursor: pointer;
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

const PrescriptionCard = ({ isMobile, id, name, qualification , specialty, date, doctorIsOnline }) => {
    const [openPres, setOpenPres] = useState(false);

    return (
        <Container className="my-2 mx-3">
            <HBox align="center" justify="space-between" className="p-2">
                <VBox className="ml-1">
                    <HBox align='center'>
                        <H5>{name}</H5>
                        {!isMobile && <Tooltip title={qualification} arrow>
                            <P3 className="ml-0_5">{trimText(qualification, 48)}</P3>
                        </Tooltip>}
                        {doctorIsOnline ? <Tooltip title='Online' arrow><OnlineIcon className="ml-0_5" /></Tooltip> : null}
                    </HBox>
                    <P2 color="second" className="mb-1">{specialty}</P2>
                    <HBox align="center" className="mt-0_5">
                        <DateIcon />
                        <P2 className="ml-0_5">{moment(date).format('D MMMM YYYY')}</P2>
                    </HBox>
                </VBox>
                <HBox>
                    <ShowIcon className="ml-1" onClick={() => setOpenPres(true)} />
                    {/* <DownloadIcon className="ml-1" /> */}
                </HBox>
            </HBox>

            <PrescriptionPDF
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={id}
            />
        </Container>
    )
}

export default PrescriptionCard;