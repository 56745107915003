import { useState, forwardRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { FiX, FiArrowLeft, FiSearch } from "react-icons/fi";
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import { setShowUppernav } from "../../services/actions/authAction";
import { HBox, VBox } from "../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button, IconButton } from "../../components/Buttons";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import SearchButton from "./components/SearchButton";
import SearchDlg from "./components/SearchDlg";
import {
    GeneralPhysician,
    Cardiology,
    Dermatology,
    Endocrinology,
    AestheticDermatology,
    Gastroenterology,
    Hematology,
    Hepatology,
    InternalMedicine,
    Nephrology,
    Neurology,
    Oncology,
    Pediatrics,
    PhysicalMedicine,
    Psychiatry,
    Respiratory,
    Rheumatology
} from "./components/SpecialtyCardsMedicine";
import {
    GeneralSurgery,
    Cardiovascular,
    Colorectal,
    Dental,
    ENT,
    Gynecology,
    Hepatobiliary,
    Neuro,
    Ophthalmology,
    Orthopedic,
    Pediatric,
    Urology,
    Vascular
} from "./components/SpecialtyCardsSurgery";
// import GeneralPhysician from "./components/GenPhysicianCard";
// import UrgentConsultCard from "./components/UrgentConsultCard";

const Topbar = styled.div`
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    z-index: 1;
`

const SearchIcon = styled(FiSearch)`
    color: ${colors.blue};
`

const Container = styled(HBox)`
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
`

const TitleBox = styled(VBox)`
    // background-color: ${colors.lightGrey};
    // border: 1px solid ${colors.grey};
    // border-radius: 10px;
    // box-shadow: 0px 1px 0px 0px ${colors.shadow};
    border-bottom: 3px solid ${colors.grey};
`

const SelectSpecialty = ({ setShowUppernav }) => {
    const navigate = useNavigate();
    const [openSearchDlg, setOpenSearchDlg] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    setShowUppernav(false);
    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    return (
        <>
            <Topbar>
                {/* <IconButton
                    className="ml-2"
                    onClick={() => {setShowUppernav(true); navigate(-1)}}
                >
                    <FiArrowLeft />
                </IconButton> */}
                <HBox></HBox>
                <SearchButton
                    onClick={() => setOpenSearchDlg(true)}
                >
                    <SearchIcon fontSize={16} className="mr-1" />
                    <P2 className="mr-1">Search doctors by name, BMDC, specialty...</P2>
                </SearchButton>
                <Link to='/dashboard'>
                    <IconButton
                        className="mr-2"
                        onClick={() => setShowUppernav(true)}
                    >
                        <FiX />
                    </IconButton>
                </Link>
            </Topbar>
            <Scrollbars
                style={{ height: 'calc(100vh - 50px)' }}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <VBox>
                    {/* <HBox className="mt-2" justify='center' style={{ width: '100%' }}>
                        <UrgentConsultCard />
                    </HBox> */}
                    {/* <HBox className="mt-2" justify='center' style={{ width: '100%' }}>
                        <GeneralPhysician />
                    </HBox> */}
                    <VBox className="mb-1 mt-2 p-0_5" style={{ alignSelf: 'center', maxWidth: 'fit-content', borderBottom: `3px solid ${colors.red}` }}>
                        <H1 color='second' align="center" className="">Specialties</H1>
                    </VBox>
                    {/* <P2 align="center" className="">We have a large pool of verified doctors in different specialties.</P2> */}
                    <TitleBox className='p-0_5 mt-3 mb-2' style={{ alignSelf: 'center' }}>
                        <H2 color='first'>Medicine</H2>
                    </TitleBox>
                    <Container className="px-2 pb-2">
                        <GeneralPhysician />
                        <InternalMedicine />
                        <Cardiology />
                        <Respiratory />
                        <Neurology />
                        <Nephrology />
                        <Gastroenterology />
                        <Hepatology />
                        <Endocrinology />
                        <Pediatrics />
                        <Oncology />
                        <Rheumatology />
                        <Hematology />
                        <Dermatology />
                        <Psychiatry />
                        <PhysicalMedicine />
                        <AestheticDermatology />
                    </Container>
                    <TitleBox className='p-1 mt-3 mb-2' style={{ alignSelf: 'center' }}>
                        <H2 color='first'>Surgery</H2>
                    </TitleBox>
                    <Container className="px-2 pb-2">
                        <GeneralSurgery />
                        {/* <Cardiovascular /> */}
                        <Orthopedic />
                        <Neuro />
                        <Gynecology />
                        <ENT />
                        <Ophthalmology />
                        <Dental />
                        <Urology />
                        <Colorectal />
                        <Pediatric />
                        <Vascular />
                        {/* <Hepatobiliary /> */}
                    </Container>
                </VBox>
            </Scrollbars>

            <SearchDlg isMobile={isMobile} open={openSearchDlg} setOpen={setOpenSearchDlg} />
        </>
    );
}

export default connect(null, { setShowUppernav })(SelectSpecialty);