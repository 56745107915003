import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';
import { BsFillCheckCircleFill, BsXCircleFill } from "react-icons/bs";

import { H1, H2, H3, H4, H5, H6, P1, P2 } from "../../components/Typography";
import { Button } from "../../components/Buttons";
import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import { setShowUppernav } from "../../services/actions/authAction";
import SelectPGWDlg from "../AppointmentSchedule/components/SelectPGWDlg";

const Container = styled(VBox)`
    width: 100%;
    height: 100vh;
    // background-color: ${colors.red};
    background: linear-gradient(184deg, white 0%, rgba(0, 0, 0, 0.08) 100%);
`

const SLink = styled(Link)`
    text-decoration: none;
`

const BkashPaymentCallback = ({ setShowUppernav }) => {
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [statusCode, setStatusCode] = useState(null);

    const [openSelectPayment, setOpenSelectPayment] = useState(false);

    setShowUppernav(false);
    useEffect(() => {
        if (queryParameters.get('status') === 'success') {
            executeBkashPayment();
        }
        else {
            setIsLoading(false);
            setIsSuccess(false);
        }
    }, []);

    const executeBkashPayment = () => {
        axios({
            method: 'POST',
            url: `patient/bkash-execute-payment/`,
            data: {
                payment_id: queryParameters.get('paymentID'),
                appointment_id: localStorage.getItem('nh-appointment-id')
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setStatusCode(response.data.statusCode);
                if (response.data.statusCode === "0000") {
                    setIsSuccess(true);
                    {delay(1500).then(() => navigate("/upcoming-appointments"))}
                }
                else {
                    enqueueSnackbar(response.data.statusMessage, {variant: 'error'});
                    setIsSuccess(false);
                }
            } else {
                console.log('BKASH PAYMENT EXECUTE FAILED', response.status);
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
                setIsSuccess(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('BKASH PAYMENT EXECUTE ERROR', error);
            enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
            setIsSuccess(false);
        });
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    return (
        <Container align='center' justify='center'>
            {isLoading ?
                <>
                    <SpinnerCircular size={60} thickness={100} speed={100} color={colors.blue} secondaryColor={colors.lightGrey} />
                    <H3 className="p-1 mt-4">Finalizing the Payment... Please Wait a Moment.</H3>
                </>
                :
                isSuccess ?
                    <>
                        <BsFillCheckCircleFill size={40} color={colors.green} />
                        <H1 color="third" className="mt-1">Payment Success</H1>
                        <H4 className="mt-2">Redirecting to Appointments...</H4>
                    </>
                    :
                    <>
                        <BsXCircleFill size={40} color={colors.red} />
                        {queryParameters.get('status') === 'cancel' ?
                            <H1 color="second" className="mt-1">Payment Cancelled</H1>
                            :
                            <H1 color="second" className="mt-1">Payment Failed</H1>
                        }
                        {statusCode === '2062' ?
                            <H3 className="mt-1">Already Paid.</H3>
                            :
                            <Button
                                color='second'
                                className="mt-4"
                                onClick={() => setOpenSelectPayment(true)}
                                outlined
                            >
                                Try Again
                            </Button>
                        }
                        <SLink to='/upcoming-appointments' className="mt-2">
                            <H6 style={{ textDecoration: 'underline' }}>Go to Appointments</H6>
                        </SLink>
                    </>
                
            }

            <SelectPGWDlg
                open={openSelectPayment}
                setOpenSelectPayment={setOpenSelectPayment}
            />
        </Container>
    )
}

export default connect(null, { setShowUppernav })(BkashPaymentCallback);