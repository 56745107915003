import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { FiChevronRight } from "react-icons/fi";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import CheckChoice from "../../components/CheckChoice";
import CheckButton from "../../components/CheckButton";
import { InputTextarea } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { PatchLeft } from "../../components/Patches";

const Container = styled(HBox)``

const SLink = styled(Link)`
    text-decoration: none;
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const ButtonNav = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0px;
    align-items: center;
    justify-content: center;
`

const BehavioralHealthHistory = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [reason, setReason] = useState([
        {text: 'Emotional problem', checked: false},
        {text: 'Relationship issue', checked: false},
        {text: 'Substance abuse', checked: false},
        {text: 'Other', checked: false},
    ]);
    const otherReasonChLim = 256;  // Sync with database limit
    const [otherReason, setOtherReason] = useState("");
    const [previousDiagnosis, setPreviousDiagnosis] = useState([
        {text: 'Alcohol dependance', checked: false},
        {text: 'Anxiety', checked: false},
        {text: 'Bipolar disorder', checked: false},
        {text: 'Depression', checked: false},
        {text: 'Obsessive compulsive disorder (OCD)', checked: false},
        {text: 'Panic attacks', checked: false},
        {text: 'Schizophrenia', checked: false},
        
    ]);
    const [hospitalizedBefore, setHospitalizedBefore] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false},
    ]);
    const [familyPrevDiagnosis, setFamilyPrevDiagnosis] = useState([
        {text: 'Alcohol dependance', checked: false},
        {text: 'Bipolar disorder', checked: false},
        {text: 'Depression', checked: false},
        {text: 'Obsessive compulsive disorder (OCD)', checked: false},
        {text: 'Schizophrenia', checked: false},
        {text: 'Unknown', checked: false},
    ]);
    const [familyHospitalizedBefore, setFamilyHospitalizedBefore] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false},
    ]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);
    
    useEffect(() => {
        enqueueSnackbar('Loading...', { persist: true });
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios({
            method: 'GET',
            url: `patient/behavioral-health-history-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setData(response.data);
            } else {
                console.log('BEHAVIORAL HEALTH HISTORY FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('BEHAVIORAL HEALTH HISTORY FETCH ERROR', error);
        })
    }

    const submit = () => {
        axios({
            method: 'PUT',
            url: `patient/behavioral-health-history-details/`,
            data: {
                reason: getChoices(reason),
                reason_other: otherReason,
                previous_diagnosis: getChoices(previousDiagnosis),
                hospitalized_before: getBoolean(hospitalizedBefore),
                previous_family_diagnosis: getChoices(familyPrevDiagnosis),
                family_hospitalized_before: getBoolean(familyHospitalizedBefore)
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                enqueueSnackbar('Successfully updated.', { variant: 'success' });
            } else {
                enqueueSnackbar('Submission failed!', { variant: 'error' });
                console.log('BEHAVIORAL HEALTH HISTORY SET FAILED', response.status);
            }
        })
        .catch((error) => {
            enqueueSnackbar('Submission error!', { variant: 'error' });
            console.log('BEHAVIORAL HEALTH HISTORY SET ERROR', error);
        })
    }

    const setData = (data) => {
        setBoolean(data.hospitalized_before, hospitalizedBefore, setHospitalizedBefore);
        setBoolean(data.family_hospitalized_before, familyHospitalizedBefore, setFamilyHospitalizedBefore);

        setChoices(data.reason, reason, setReason);
        setOtherReason(data.reason_other ? data.reason_other : "");
        setChoices(data.previous_diagnosis, previousDiagnosis, setPreviousDiagnosis);
        setChoices(data.previous_family_diagnosis, familyPrevDiagnosis, setFamilyPrevDiagnosis);
    }

    const setBoolean = (value, state, setState) => {
        if (value === true) {
            let values = [...state];
            values[0].checked = true;
            setState(values);
        }
        else if (value === false) {
            let values = [...state];
            values[1].checked = true;
            setState(values);
        }
        else {
            // do nothing
        }
    }

    const getBoolean = (state) => {
        if (state[0].checked) return true;
        else if (state[1].checked) return false;
        else return null;
    }

    const setChoices = (choices, state, setState) => {
        if (choices) {
            if (typeof(choices) === 'string') {
                setIndvChoice(choices, state, setState);
            }
            else {
                for (let i=0; i<choices.length; i++) {
                    let choice = choices[i];
                    setIndvChoice(choice, state, setState);
                }
            }
        }
    }

    const setIndvChoice = (choice, state, setState) => {
        for (let j=0; j<state.length; j++) {
            let values = [...state];
            if (values[j].text === choice) {
                values[j].checked = true;
                setState(values);
                break;
            }
        }
    }

    const getChoices = (state) => {
        let choices = [];
        for (let i=0; i<state.length; i++) {
            if (state[i].checked) {
                choices.push(state[i].text);
            }
        }

        return String(choices);
    }

    return (
        <div>
            <LowerNav selected="health-history" />
            <Container style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <PatchLeft />
                {isMobile && <TopPanel className='p-2' align="center">
                    <HBox align="center">
                        <SLink to='/health-history'>
                            <ButtonNav size='md' color='first' outlined>1</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <ButtonNav size='md' color='first'>2</ButtonNav>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/life-style'>
                            <ButtonNav size='md' color='first' outlined>3</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/family-history'>
                            <ButtonNav size='md' color='first' outlined>4</ButtonNav>
                        </SLink>
                    </HBox>
                    <H3 color='second' justify='center' className='mt-2'>Behavioral Health History</H3>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <SLink to='/health-history'>
                        <NavBtn size="sm" color="first" className="mt-3" outlined>
                            <div className="ml-1">My Health History</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">My Behavioral Health History</div>
                    </NavBtnActive>
                    <SLink to='/life-style'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Life Style</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/family-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Family History</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: '100%' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-3'>
                            <P1>What is your reason for seeking behavioral health care? (check all that apply)</P1>
                            <CheckChoice
                                items={reason}
                                setItems={setReason}
                                className='p-2'
                            />
                            {reason.at(-1).checked &&
                                <VBox className="mx-3">
                                    <InputTextarea
                                        placeholder="Please type other reasons..."
                                        value={otherReason}
                                        onChange={(e) => setOtherReason(e.target.value.slice(0, otherReasonChLim))}
                                    />
                                    <P2 align="right">{otherReason.length} / {otherReasonChLim}</P2>
                                </VBox>
                            }
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Have you ever been diagnosed with any of the following conditions?</P1>
                            <CheckChoice
                                items={previousDiagnosis}
                                setItems={setPreviousDiagnosis}
                                className='p-2'
                            />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Have you ever been hospitalized for mental health or substance abuse treatment?</P1>
                            <CheckButton className="mt-1" items={hospitalizedBefore} setItems={setHospitalizedBefore} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Has anyone in your family been diagnosed with any of the follwing?</P1>
                            <CheckChoice
                                items={familyPrevDiagnosis}
                                setItems={setFamilyPrevDiagnosis}
                                className='p-2'
                            />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Has any family member ever been hospitalized for mental health or substance abuse treatment?</P1>
                            <CheckButton className="mt-1" items={familyHospitalizedBefore} setItems={setFamilyHospitalizedBefore} size="md" radio={true} />
                        </VBox>
                        {/* <VBox className='p-3'>
                            <P1>Do you have a gender preference for a provider?</P1>
                            <CheckButton className="mt-1" items={genderPreference} setItems={setGenderPreference} size="md" radio={true} />
                        </VBox> */}
                        
                        <HBox justify="center" className="mt-4 mb-2">
                            <Button size="md" color="first" onClick={submit}>Submit</Button>
                        </HBox>
                        <HBox justify="space-between" className="mx-2 mb-2">
                            <SLink to="/health-history">
                                <Button size="sm" outlined>Previous</Button>
                            </SLink>
                            <SLink to="/life-style">
                                <Button size="sm" outlined>Next</Button>
                            </SLink>
                        </HBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </Container>
            {!isMobile && <Footer />}
        </div>
    );
}

export default BehavioralHealthHistory;