export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_REFRESH = 'AUTHENTICATION_REFRESH';
export const LOGIN = 'LOGIN';
export const VERIFICATION = 'VERIFICATION';
export const LOGOUT = 'LOGOUT';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const VERIFICATION_ERROR = 'VERIFICATION_ERROR';
export const SHOW_UPPERNAV = 'SHOW_UPPERNAV';

export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_CALL_STATE = 'SET_CALL_STATE';
export const SET_NEW_ONLINE_STATUS_CHANGE = 'SET_NEW_ONLINE_STATUS_CHANGE';
