import styled from "styled-components";
import { Link } from 'react-router-dom';
import { FiChevronRight, FiCheck } from "react-icons/fi";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";

const Card = styled.div`
    // width: 20%;
    height: 230px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    contain: content;

    :hover {
        box-shadow: 0px 1px 8px 0px ${colors.shadow};
        transition: box-shadow 300ms;
    }
`

const Image = styled.img`
    width: 70px;
    height: 70px;
    align-self: center;
`

const BtnIcon = styled(FiChevronRight)`
    font-size: 16px;
    border-radius: 8px;
    margin-left: 4px;
    color: white;
    background-color: ${colors.blue};
`

const CheckIcon = styled(FiCheck)`
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 4px;
    color: white;
    background-color: ${colors.red};
`

const BookAppointmentBtn = styled.button`
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: fixed;
    bottom: 4px;
    background-color: white;
    border: 0px;
    outline: 0px;
    cursor: pointer;

    :hover ${BtnIcon} {
        margin-left: 8px;
        transition: margin-left 300ms;
    }
`

// const BulletContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin-bottom: 4px;
// `

// const BulletPoint = ({ text }) => {
//     return (
//         <BulletContainer>
//             <CheckIcon />
//             <P2>{text}</P2>
//         </BulletContainer>
//     )
// }

const SLink = styled(Link)`
    text-decoration: none;
    width: 180px;
`

export const GeneralPhysician = () => {
    return (
        <SLink to='/doctors/General-Physician'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">General Physician</H5>
                <P2 align='center' color='second' className="mb-2">জেনারেল ফিজিসিয়ান</P2>
                <Image className="mb-4" src='/images/specialties/generalphysician.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Cardiology = () => {
    return (
        <SLink to='/doctors/Cardiology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Cardiology</H5>
                <P2 align='center' color='second' className="mb-2">হৃদরোগ</P2>
                <Image className="mb-4" src='/images/specialties/cardiology.png' />
                {/* <div>
                    <BulletPoint text="Heart failure" />
                    <BulletPoint text="Inherited heart diseases" />
                    <BulletPoint text="Cardio-oncology etc." />
                </div> */}
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Dermatology = () => {
    return (
        <SLink to='/doctors/Dermatology-&-Venereology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Dermatology & Venereology</H5>
                <P2 align='center' color='second' className="mb-2">চর্ম ও যৌনরোগ</P2>
                <Image className="mb-4" src='/images/specialties/dermatology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Endocrinology = () => {
    return (
        <SLink to='/doctors/Endocrinology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Endocrinology</H5>
                <P2 align='center' color='second' className="mb-2">হরমোন</P2>
                <Image className="mb-4" src='/images/specialties/endocrinology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const AestheticDermatology = () => {
    return (
        <SLink to='/doctors/Aesthetic-Dermatology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Aesthetic Dermatology</H5>
                <P2 align='center' color='second' className="mb-2">এস্থেটিক ডার্মাটোলজি</P2>
                <Image className="mb-4" src='/images/specialties/female_beautification.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Gastroenterology = () => {
    return (
        <SLink to='/doctors/Gastroenterology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Gastroenterology</H5>
                <P2 align='center' color='second' className="mb-2">পরিপাকতন্ত্র</P2>
                <Image className="mb-4" src='/images/specialties/gastroenterology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Hematology = () => {
    return (
        <SLink to='/doctors/Hematology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Hematology</H5>
                <P2 align='center' color='second' className="mb-2">রক্তরোগ</P2>
                <Image className="mb-4" src='/images/specialties/hematology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Hepatology = () => {
    return (
        <SLink to='/doctors/Hepatology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Hepatology</H5>
                <P2 align='center' color='second' className="mb-2">লিভার</P2>
                <Image className="mb-4" src='/images/specialties/hepatology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const InternalMedicine = () => {
    return (
        <SLink to='/doctors/Internal-Medicine'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Internal Medicine</H5>
                <P2 align='center' color='second' className="mb-2">ইন্টারনাল মেডিসিন</P2>
                <Image className="mb-4" src='/images/specialties/internalmedicine.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Nephrology = () => {
    return (
        <SLink to='/doctors/Nephrology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Nephrology</H5>
                <P2 align='center' color='second' className="mb-2">কিডনি</P2>
                <Image className="mb-4" src='/images/specialties/nephrology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Neurology = () => {
    return (
        <SLink to='/doctors/Neurology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Neurology</H5>
                <P2 align='center' color='second' className="mb-2">স্নায়ুরোগ</P2>
                <Image className="mb-4" src='/images/specialties/neurology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Oncology = () => {
    return (
        <SLink to='/doctors/Oncology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Oncology</H5>
                <P2 align='center' color='second' className="mb-2">ক্যান্সার</P2>
                <Image className="mb-4" src='/images/specialties/oncology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Pediatrics = () => {
    return (
        <SLink to='/doctors/Pediatrics'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Pediatrics</H5>
                <P2 align='center' color='second' className="mb-2">শিশুরোগ</P2>
                <Image className="mb-4" src='/images/specialties/pediatrics.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const PhysicalMedicine = () => {
    return (
        <SLink to='/doctors/Physical-Medicine-&-Rehabilitation'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Physical Medicine & Rehabilitation</H5>
                <P2 align='center' color='second' className="mb-2">ফিজিক্যাল মেডিসিন</P2>
                <Image className="mb-4" src='/images/specialties/rehabilitation.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Psychiatry = () => {
    return (
        <SLink to='/doctors/Psychiatry'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Psychiatry</H5>
                <P2 align='center' color='second' className="mb-2">মনোরোগ</P2>
                <Image className="mb-4" src='/images/specialties/psychiatry.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Respiratory = () => {
    return (
        <SLink to='/doctors/Respiratory-Medicine'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Respiratory Medicine</H5>
                <P2 align='center' color='second' className="mb-2">ফুসফুস</P2>
                <Image className="mb-4" src='/images/specialties/pulmonology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Rheumatology = () => {
    return (
        <SLink to='/doctors/Rheumatology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Rheumatology</H5>
                <P2 align='center' color='second' className="mb-2">বাতরোগ</P2>
                <Image className="mb-4" src='/images/specialties/rheumatology.jpeg' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}
