import styled from "styled-components";
import { Link } from 'react-router-dom';
import { FiChevronRight, FiCheck } from "react-icons/fi";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";

const Card = styled.div`
    // width: 20%;
    height: 230px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    contain: content;

    :hover {
        box-shadow: 0px 1px 8px 0px ${colors.shadow};
        transition: box-shadow 300ms;
    }
`

const Image = styled.img`
    width: 70px;
    height: 70px;
    align-self: center;
`

const BtnIcon = styled(FiChevronRight)`
    font-size: 16px;
    border-radius: 8px;
    margin-left: 4px;
    color: white;
    background-color: ${colors.blue};
`

const CheckIcon = styled(FiCheck)`
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 4px;
    color: white;
    background-color: ${colors.red};
`

const BookAppointmentBtn = styled.button`
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: fixed;
    bottom: 4px;
    background-color: white;
    border: 0px;
    outline: 0px;
    cursor: pointer;

    :hover ${BtnIcon} {
        margin-left: 8px;
        transition: margin-left 300ms;
    }
`

const SLink = styled(Link)`
    text-decoration: none;
    width: 180px;
`

export const GeneralSurgery = () => {
    return (
        <SLink to='/doctors/General-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">General Surgery</H5>
                <P2 align='center' color='second' className="mb-2">জেনারেল  সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/generalsurgery.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Cardiovascular = () => {
    return (
        <SLink to='/doctors/Cardiovascular-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Cardiovascular Surgery</H5>
                <P2 align='center' color='second' className="mb-2">কার্ডিয়াক সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/cardiovascular.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Colorectal = () => {
    return (
        <SLink to='/doctors/Colorectal-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Colorectal Surgery</H5>
                <P2 align='center' color='second' className="mb-2">কোলন, রেকটাম সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/colorectal.jpg' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Dental = () => {
    return (
        <SLink to='/doctors/Oral-&-Dental-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Oral & Dental Surgery</H5>
                <P2 align='center' color='second' className="mb-2">ডেন্টাল সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/dentistry.png' />
                {/* <>
                    <BulletPoint text="Tooth impaction" />
                    <BulletPoint text="Oral cancer etc." />
                </> */}
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const ENT = () => {
    return (
        <SLink to='/doctors/ENT-&-Head-Neck-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">ENT & Head Neck Surgery</H5>
                <P2 align='center' color='second' className="mb-2">নাক, কান ও গলা</P2>
                <Image className="mb-4" src='/images/specialties/ent.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Gynecology = () => {
    return (
        <SLink to='/doctors/Gynecology-&-Obstetrics'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Gynecology & Obstetrics</H5>
                <P2 align='center' color='second' className="mb-2">স্ত্রীরোগ ও প্রসূতি</P2>
                <Image className="mb-4" src='/images/specialties/obstetrics.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Hepatobiliary = () => {
    return (
        <SLink to='/doctors/Hepatobiliary-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Hepatobiliary Surgery</H5>
                <P2 align='center' color='second' className="mb-2">হেপাটোবিলিয়ারি সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/hepatobiliary.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Neuro = () => {
    return (
        <SLink to='/doctors/Neurosurgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Neurosurgery</H5>
                <P2 align='center' color='second' className="mb-2">স্নায়ু সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/neurology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Ophthalmology = () => {
    return (
        <SLink to='/doctors/Ophthalmology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Ophthalmology</H5>
                <P2 align='center' color='second' className="mb-2">চক্ষু</P2>
                <Image className="mb-4" src='/images/specialties/ophthalmology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Orthopedic = () => {
    return (
        <SLink to='/doctors/Orthopedic-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Orthopedic Surgery</H5>
                <P2 align='center' color='second' className="mb-2">অর্থোপেডিক সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/orthopedics.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Pediatric = () => {
    return (
        <SLink to='/doctors/Pediatric-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Pediatric Surgery</H5>
                <P2 align='center' color='second' className="mb-2">শিশুরোগ সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/pediatrics.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Urology = () => {
    return (
        <SLink to='/doctors/Urology'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Urology</H5>
                <P2 align='center' color='second' className="mb-2">মুত্ররোগ সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/urology.png' />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}

export const Vascular = () => {
    return (
        <SLink to='/doctors/Vascular-Surgery'>
            <Card className="p-2 m-1">
                <H5 align="center" className="mb-0_5">Vascular Surgery</H5>
                <P2 align='center' color='second' className="mb-2">রক্তনালী সার্জারি</P2>
                <Image className="mb-4" src='/images/specialties/vascular.png' style={{ height: 100, width: 'auto' }} />
                <BookAppointmentBtn>
                    Book Appointment
                    <BtnIcon />
                </BookAppointmentBtn>
            </Card>
        </SLink>
    )
}