import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiPlus, FiFilter, FiChevronUp } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import moment from "moment";
import { useSnackbar } from "notistack";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button } from "../../components/Buttons";
import { InputText } from "../../components/InputText";
import CheckButton from "../../components/CheckButton";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import HealthRecordCard from "./components/HealthRecordCard";
import DateInput from "./components/DateInput";
import UploadRecordDlg from "../../layouts/UploadRecordDlg";
import { PatchLeft } from "../../components/Patches";

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const CenterPanel = styled(VBox)``

const RightPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-left: 1px solid ${colors.grey};
`

const TopPanel = styled(VBox)`
    width: 100%;
    backgrouond-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const SLine = styled.div`
    width: 100%;
    border-bottom: 0.5px solid ${colors.grey};
`

const FilterTxt = styled.button`
    height: 25px;
    background-color: ${props => props.active ? colors.blue : "white"};
    color: ${props => props.active ? "white" : colors.darkGrey};
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    padding: 0px 5px;
    font-size: 1rem;
`

const FilterIcon = styled(FiFilter)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const AscendIcon = styled(ImSortAmountAsc)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const DescendIcon = styled(ImSortAmountDesc)`
    font-size: 16px;
    color: ${colors.darkGrey};
    cursor: pointer;
`

const HealthRecords = () => {
    const pageSize = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [records, setRecords] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [uploadRecordOpen, setUploadRecordOpen] = useState(false);

    const [order, setOrder] = useState([
        { text: "Date", checked: true },
        { text: "Title", checked: false }
    ]);
    const [filter, setFilter] = useState({
        fromDate: "",
        toDate: ""
    });
    const [isAscending, setIsAscending] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [showTopPanel, setShowTopPanel] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);
    useEffect(() => () => reloadRecords(), [order, isAscending]);
    
    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'patient/health-record-list/',
            params: {
                key: searchKey,
                offset: offset,
                limit: limit,
                order_by: order[0].checked ? 'report_date' : 'title',
                order_direc: isAscending ? '' : '-',
                from_date: filter.fromDate,
                to_date: filter.toDate,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setRecords(records.concat(response.data.health_records));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('RECORDS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('RECORDS FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleSearch = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    reloadRecords();
            }, 300)
        )
    }

    const handleChangeFilter = (value, key) => {
        setFilter({...filter, [key]: value});
        if (value && key === 'fromDate' ? filter.toDate : filter.fromDate) {
            reloadRecords();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClearFilter = () => {
        setFilter({...filter, fromDate: "", toDate: ""});
        reloadRecords();
    }

    const reloadRecords = () => {
        setCount(null);
        setOffset(0);
        setLimit(pageSize);
        setHasMore(true);
        setRecords([]);
    }

    const searchHtml = () => (
        <VBox className="p-2">
            <H6 color="second" className="mb-0_5">Search</H6>
            <InputText
                placeholder="Search in health records ..."
                onChange={(e) => handleSearch(e.target.value)}
            />
        </VBox>
    );

    const filterByHtml = () => (
        <VBox className="p-2" style={{ width: isMobile ? 'auto' : '100%' }}>
            <H6 color="second" className="mb-0_5">Filter by</H6>
            <HBox align="center" justify={isMobile ? "flex-start" : "space-between"} className={isMobile ? "mb-2" : "mb-4"}>
                <FilterTxt active={true}>Date</FilterTxt>  {/* Setting always true for similiarity with prescriptions view */}
                <Button
                    size="sm"
                    outlined
                    style={{ height: 25, padding: "0px 0.5em 0px" }}
                    onClick={handleClearFilter}
                    className={isMobile ? 'ml-2' : ""}
                >
                    Clear
                </Button>
            </HBox>
            <VBox align={isMobile ? 'flex-start' : 'center'}>
                <DateInput
                    label="From"
                    value={filter.fromDate}
                    onChange={(e) => handleChangeFilter(e.target.value, 'fromDate')}
                />
                <HBox className="mt-1">
                    <DateInput
                        label="To"
                        value={filter.toDate}
                        onChange={(e) => handleChangeFilter(e.target.value, 'toDate')}
                    />
                </HBox>
            </VBox>
        </VBox>
    );

    const orderByHtml = () => (
        <VBox className="p-2" style={{ width: isMobile ? "auto" : "100%" }}>
            <H6 color="second" className="mb-0_5">Order by</H6>
            <CheckButton items={order} setItems={setOrder} radio={true} />
        </VBox>
    )
    
    return (
        <div>
            <LowerNav selected="health-records" />
            <HBox>
                <PatchLeft />
                {(isMobile && showTopPanel) && <TopPanel>
                    <HBox>
                        {searchHtml()}
                    </HBox>
                    <HBox>
                        {filterByHtml()}
                        {orderByHtml()}
                    </HBox>
                    {/* <HBox style={{ alignSelf: 'center' }}> */}
                        <Button
                            size='sm'
                            className='mb-1'
                            style={{ alignSelf: 'center' }}
                            onClick={() => setShowTopPanel(false)}
                            outlined
                        >
                            <FiChevronUp className="mr-0_5" />
                            Hide
                        </Button>
                    {/* </HBox> */}
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <HBox justify="center">
                        <Button size="sm" color='first' outlined className="mb-6" onClick={() => setUploadRecordOpen(true)}>
                            <FiPlus fontSize={16} className="mr-1" />Add Health Record
                        </Button>
                    </HBox>
                    <SLine className="" />
                    {searchHtml()}
                    {filterByHtml()}
                    {orderByHtml()}
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        style={{ height: isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 150px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <HBox justify='space-between' className="mx-3 mt-2 mb-1">
                            <HBox>
                                {count !== null && <P2>Found <span className="bold" style={{ color: `${colors.darkGrey}` }}>{count}</span> records</P2>}
                                {count !== null && searchKey && <P2 className="ml-0_5">for <span className="bold" style={{ color: `${colors.darkGrey}` }}>{searchKey}</span></P2>}
                                {count !== null &&
                                    filter.fromDate &&
                                    filter.toDate &&
                                    <P2 className="ml-0_5">
                                        from
                                        <span className="ml-0_5 mr-0_5 bold" style={{ color: `${colors.darkGrey}` }}>{moment(filter.fromDate).format("DD MMM YYYY")}</span>
                                        to
                                        <span className="ml-0_5 bold" style={{ color: `${colors.darkGrey}` }}>{moment(filter.toDate).format("DD MMM YYYY")}</span>
                                    </P2>
                                }
                            </HBox>
                            <HBox>
                                {isMobile && <FilterIcon className="mr-1" onClick={() => setShowTopPanel(true)} />}
                                {isAscending ?
                                    <AscendIcon onClick={() => setIsAscending(false)} />
                                    :
                                    <DescendIcon onClick={() => setIsAscending(true)} />
                                }
                            </HBox>
                        </HBox>
                        {records.length ?
                            records.map((record, index) => (
                                <HealthRecordCard
                                    id={record.id}
                                    index={index}
                                    title={record.title}
                                    reportDate={record.report_date}
                                    hrFiles={record.hr_files}
                                    reloadRecords={reloadRecords}
                                />
                            ))
                            :
                            !isLoading && <VBox align='center' justify='center' style={{ width: '100%', height: '90%' }}>
                                <img src='images/notfound.png' style={{ height: 80 }} />
                                <H3 className="mt-2">No Health Records</H3>
                            </VBox>
                        }
                    </Scrollbars>
                    {isMobile && <Button
                        size='lg'
                        style={{ width: '50px', padding: 0, position: 'fixed', bottom: '54px', left: 'calc(50% - 25px)' }}
                        color='first'
                        onClick={() => setUploadRecordOpen(true)}
                        elevated
                    >
                        <FiPlus fontSize={16} className="" />
                    </Button>}
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </HBox>
            {!isMobile && <Footer />}

            <UploadRecordDlg
                open={uploadRecordOpen}
                setOpen={setUploadRecordOpen}
                reloadRecords={reloadRecords}
            />
        </div>
    )
}

export default HealthRecords;