import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";

import { P1, P2, P3, H1, H2, H3, H4, H5, H6 } from "../../../components/Typography";
import { HBox, VBox } from "../../../components/Containers";
import colors from "../../../config/colors";
import { trimText } from "../../../utils";
import { OnlineChip, OfflineChip } from "../../../components/Chips";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled(VBox)`
    width: 20%;
`

const Image = styled.img`
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: 1px solid ${colors.grey};
`

const _PatientCard = ({ userId, image, name, age, gender, phone, address, isOnline,
                       newOnlineStatusChange }) => {
    const [_isOnline, _setIsOnline] = useState(isOnline);

    useEffect(() => {
        if (newOnlineStatusChange) {
            if (userId == newOnlineStatusChange.user_id) {
                _setIsOnline(newOnlineStatusChange.is_online);
            }
        }
    }, [newOnlineStatusChange]);
    useEffect(() => {_setIsOnline(isOnline)}, [isOnline]);

    return (
        <VBox style={{ width: '100%' }} align="center">
            <Image
                src={`${baseURL}${image}`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src="/images/noImage.svg";
                }}
            />
            
            <H4 className="mt-1" align='center'>{name}</H4>
            {_isOnline ?
                <OnlineChip className="mt-0_5"><H6 color='white'>Online</H6></OnlineChip>
                :
                <OfflineChip className="mt-0_5"><H6 color='white'>Offline</H6></OfflineChip>
            }
            <P2 className="mt-0_5" align='center'>{age} / {gender}</P2>
            <P2 align='center'>{phone ? 'Phone: ' + phone : ""}</P2>
            <P2 align='center'>{address ? 'Address: ' + address : ""}</P2>
        </VBox>
    )
}

const mapStateToPropsPt = state => ({
    newOnlineStatusChange: state.chat.newOnlineStatusChange,
});

const PatientCard =  connect(mapStateToPropsPt, {  })(_PatientCard);

const _DoctorCard = ({ userId, image, name, qualification, specialty, isOnline,
                       newOnlineStatusChange, setNewOnlineStatusChange }) => {
    const [_isOnline, _setIsOnline] = useState(isOnline);

    useEffect(() => {
        if (newOnlineStatusChange) {
            if (userId == newOnlineStatusChange.user_id) {
                _setIsOnline(newOnlineStatusChange.is_online);
            }
        }
    }, [newOnlineStatusChange]);
    useEffect(() => {_setIsOnline(isOnline)}, [isOnline]);

    return (
        <VBox style={{ width: '100%' }} align="center">
            <Image
                src={`${baseURL}${image}`}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src="/images/noImage.svg";
                }}
            />
            <H4 className="mt-1" align='center'>{name}</H4>
            {_isOnline ?
                <OnlineChip className="mt-0_5"><H6 color='white'>Online</H6></OnlineChip>
                :
                <OfflineChip className="mt-0_5"><H6 color='white'>Offline</H6></OfflineChip>
            }
            <Tooltip title={qualification} arrow>
                <P2 className="mt-0_5" align='center'>{trimText(qualification, 64)}</P2>
            </Tooltip>
            <P2 color='second' align='center'>{specialty}</P2>
        </VBox>
    )
}

const mapStateToPropsDoc = state => ({
    newOnlineStatusChange: state.chat.newOnlineStatusChange,
});

const DoctorCard =  connect(mapStateToPropsDoc, {  })(_DoctorCard);

const ContactProfile = ({ inboxId }) => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (inboxId) getInboxDetails();
    }, [inboxId]);

    function getInboxDetails() {
        axios({
            method: 'GET',
            url: 'chat/inbox-details/',
            params: {
                inbox_id: inboxId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            if (res.status === 200) {
                setContacts(res.data);
            }
            else {
                console.log('INBOX DETAILS FETCH FAILED');
            }
        }).catch(error => {
            console.log('INBOX DETAILS FETCH ERROR', error);
        });
    }

    return (
        <Container align="center" className="px-2">
            {contacts.map((contact, index) => (
                contact.type === 'Doctor' ?
                    <DoctorCard
                        userId={contact.user_id}
                        image={contact.image}
                        name={contact.name}
                        qualification={contact.qualification}
                        specialty={contact.specialty}
                        isOnline={contact.is_online}
                    />
                    :
                    <PatientCard
                        userId={contact.user_id}
                        image={contact.image}
                        name={contact.name}
                        age={contact.age ? contact.age : '[Age]'}
                        gender={contact.gender ? contact.gender : '[Gender]'}
                        phone={contact.phone}
                        address={contact.address}
                        isOnline={contact.is_online}
                    />
            ))}
        </Container>
    )
}

export default ContactProfile;
