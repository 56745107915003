import { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox } from "../../../components/Containers";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { getDate, getTime } from "../../../utils";
import Confirm from "../../../layouts/Confirm";
import { setShowUppernav } from "../../../services/actions/authAction";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled(VBox)`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;

    :hover {
        box-shadow: 1px 1px 8px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }
`

const DateCard = styled(VBox)`
    position: relative;
    top: -30px;
    height: 60px;
    align-self: center;
    border: 1px solid ${colors.grey};
    border-radius: 30px;
    background-color: ${colors.lightGrey};
    z-index: 1;
    // box-shadow: 1px 1px 3px 0px ${colors.shadow};
`

const Image = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 40px;
    border: 1px solid ${colors.grey};
`

const SlotContainer = styled(HBox)`
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    background-color: ${props => props.occupied ? colors.grey : colors.veryLightGreen};
    
    :hover {
        background-color: ${props => props.occupied ? colors.grey : colors.lightGrey};
    }
`

const SlotCard = ({ id, doctorId, date, timeSpan, isOccupied }) => {
    const navigate = useNavigate();
    const [showBtn, setShowBtn] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleConfirm = () => {
        axios({
            method: 'POST',
            url: `patient/appointment-list/`,
            data: {
                doctor_id: doctorId,
                consult_slot_id: id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 201) {
                navigate('/upcoming-appointments');
            } else {
                console.log('APPOINTMENT CREATE FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('APPOINTMENT CREATE ERROR', error);
        });
    }
    
    return (
        <Tooltip title={isOccupied ? 'Occupied' : ''} arrow>
            <SlotContainer
                className="px-2 py-1 m-0_5 clickable"
                align='center'
                occupied={isOccupied}
                onClick={(e) => {e.stopPropagation(); !isOccupied && setShowBtn(true)}}
            >
                {showBtn ?
                    <>
                        <VBox>
                            <P2>{timeSpan}</P2>
                            <H6 color='second'>{date}</H6>
                        </VBox>
                        <HBox onClick={(e) => e.stopPropagation()}>
                            <Button
                                size="sm"
                                color='third'
                                className="my-2 ml-2 mr-1"
                                onClick={() => setOpenConfirm(true)}
                            >
                                Proceed
                            </Button>
                            <Button size='sm' color='second' className="my-2 mr-2" onClick={() => setShowBtn(false)}>Close</Button>
                        </HBox>
                    </>
                    :
                    <P1>{timeSpan}</P1>
                
                }
                <Confirm
                    message="Please confirm the appointment. [Note: In production, after completing slot booking, users have to pay through a payment gateway before confirmation.]"
                    btnTxt='Confirm'
                    btnColor="first"
                    onConfirm={handleConfirm}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                />
            </SlotContainer>
        </Tooltip>
    )
}

const DoctorScheduleCard = ({ doctorData, urgentSlots, availableSlotsCount }) => {
    return (
        <Container className="p-4 mb-6">
            <HBox className='mb-2 pb-1' style={{ borderBottom: `1px solid ${colors.grey}` }}>
                <Image
                    src={`${baseURL}${doctorData?.image}`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="/images/noImage.svg";
                    }}
                />
                <VBox className='ml-1'>
                    <H5>{doctorData?.name}</H5>
                    <P2 color='second'>{doctorData?.qualification}</P2>
                    <P2>Fee: <span style={{ color: colors.green }}>৳ {doctorData?.urgent_fee}</span></P2>
                </VBox>
            </HBox>
            <H4 className="ml-0_5 mb-1"><span style={{ color: colors.green }}>{availableSlotsCount}</span> available</H4>
            <HBox align='flex-start'>
                {urgentSlots?.length ?
                    urgentSlots?.map((slot, index) => (
                        <SlotCard
                            id={slot.id}
                            doctorId={doctorData?.id}
                            date={getDate(slot?.date, 'long')}
                            timeSpan={`${getTime(slot?.start_time)} - ${getTime(slot?.end_time)}`}
                            isOccupied={slot?.is_occupied}
                        />
                    ))
                    :
                    <P1>No slots.</P1>
                }
            </HBox>
        </Container>
    )
}

export default DoctorScheduleCard;