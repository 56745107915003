import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { FiChevronRight } from "react-icons/fi";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import CheckButton from "../../components/CheckButton";
import { InputText } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { PatchLeft } from "../../components/Patches";

const Container = styled(HBox)``

const SLink = styled(Link)`
    text-decoration: none;
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const ButtonNav = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0px;
    align-items: center;
    justify-content: center;
`

const LifeStyle = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [adequateSleep, setAdeauateSleep] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [exercise, setExercise] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [fluShots, setFluShots] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [smoke, setSmoke] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [alcohol, setAlcohol] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [recrDrugs, setRecrDrugs] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [toxicChem, setToxicChem] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);
    const [disability, setDisability] = useState([
        {text: 'Yes', checked: false},
        {text: 'No', checked: false}
    ]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar('Loading...', { persist: true });
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios({
            method: 'GET',
            url: `patient/life-style-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setData(response.data);
            } else {
                console.log('LIFE STYLE FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('LIFE STYLE FETCH ERROR', error);
        })
    }

    const submit = () => {
        axios({
            method: 'PUT',
            url: `patient/life-style-details/`,
            data: {
                adequate_sleep: getBoolean(adequateSleep),
                exercise: getBoolean(exercise),
                yearly_flu_shots: getBoolean(fluShots),
                smoke: getBoolean(smoke),
                alcohol: getBoolean(alcohol),
                recreational_drugs: getBoolean(recrDrugs),
                toxic_chemicals: getBoolean(toxicChem),
                disability: getBoolean(disability)
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                enqueueSnackbar('Successfully updated.', { variant: 'success' });
            } else {
                enqueueSnackbar('Submission failed!', { variant: 'error' });
                console.log('LIFE STYLE SET FAILED', response.status);
            }
        })
        .catch((error) => {
            enqueueSnackbar('Submission error!', { variant: 'error' });
            console.log('LIFE STYLE SET ERROR', error);
        })
    }

    const setData = (data) => {
        setBoolean(data.adequate_sleep, adequateSleep, setAdeauateSleep);
        setBoolean(data.exercise, exercise, setExercise);
        setBoolean(data.yearly_flu_shots, fluShots, setFluShots);
        setBoolean(data.smoke, smoke, setSmoke);
        setBoolean(data.alcohol, alcohol, setAlcohol);
        setBoolean(data.recreational_drugs, recrDrugs, setRecrDrugs);
        setBoolean(data.toxic_chemicals, toxicChem, setToxicChem);
        setBoolean(data.disability, disability, setDisability);
    }

    const setBoolean = (value, state, setState) => {
        if (value === true) {
            let values = [...state];
            values[0].checked = true;
            setState(values);
        }
        else if (value === false) {
            let values = [...state];
            values[1].checked = true;
            setState(values);
        }
        else {
            // do nothing
        }
    }

    const getBoolean = (state) => {
        if (state[0].checked) return true;
        else if (state[1].checked) return false;
        else return null;
    }

    return (
        <div>
            <LowerNav selected="health-history" />
            <Container style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <PatchLeft />
                {isMobile && <TopPanel className='p-2' align="center">
                    <HBox align="center">
                        <SLink to='/health-history'>
                            <ButtonNav size='md' color='first' outlined>1</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/behavioral-health-history'>
                            <ButtonNav size='md' color='first' outlined>2</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <ButtonNav size='md' color='first'>3</ButtonNav>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/family-history'>
                            <ButtonNav size='md' color='first' outlined>4</ButtonNav>
                        </SLink>
                    </HBox>
                    <H3 color='second' justify='center' className='mt-2'>Life Style</H3>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <SLink to='/health-history'>
                        <NavBtn size="sm" color="first" className="mt-3" outlined>
                            <div className="ml-1">My Health History</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/behavioral-health-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Behavioral Health History</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">My Life Style</div>
                    </NavBtnActive>
                    <SLink to='/family-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Family History</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: '100%' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-3'>
                            <P1>Do you exercise?</P1>
                            <CheckButton className="mt-1" items={exercise} setItems={setExercise} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you get at least 7 hours of sleep daily?</P1>
                            <CheckButton className="mt-1" items={adequateSleep} setItems={setAdeauateSleep} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you smoke or use tobacco?</P1>
                            <CheckButton className="mt-1" items={smoke} setItems={setSmoke} size="md" radio={true} />
                        </VBox><VBox className='p-3'>
                            <P1>Do you drink alcohol?</P1>
                            <CheckButton className="mt-1" items={alcohol} setItems={setAlcohol} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you use recreational drugs?</P1>
                            <CheckButton className="mt-1" items={recrDrugs} setItems={setRecrDrugs} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Are you or have you ever been disabled?</P1>
                            <CheckButton className="mt-1" items={disability} setItems={setDisability} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you get yearly flu shots?</P1>
                            <CheckButton className="mt-1" items={fluShots} setItems={setFluShots} size="md" radio={true} />
                        </VBox>
                        <VBox className='p-3'>
                            <P1>Do you work around hazardous or toxic chemicals?</P1>
                            <CheckButton className="mt-1" items={toxicChem} setItems={setToxicChem} size="md" radio={true} />
                        </VBox>
                        
                        <HBox justify="center" className="mt-4 mb-2">
                            <Button size="md" color="first" onClick={submit}>Submit</Button>
                        </HBox>
                        <HBox justify="space-between" className="mx-2 mb-2">
                            <SLink to="/behavioral-health-history">
                                <Button size="sm" outlined>Previous</Button>
                            </SLink>
                            <SLink to="/family-history">
                                <Button size="sm" outlined>Next</Button>
                            </SLink>
                        </HBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </Container>
            {!isMobile && <Footer />}
        </div>
    );
}

export default LifeStyle;