import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { FiX, FiArrowLeft } from "react-icons/fi";
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";

import { setShowUppernav } from "../../services/actions/authAction";
import { HBox, VBox } from "../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button, IconButton } from "../../components/Buttons";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import DoctorCard from "./components/DoctorCard";

const Topbar = styled.div`
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    z-index: 1;
`

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const SpecialtyDoctors = ({ setShowUppernav }) => {
    const pageSize = 10;
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    var { specialty } = useParams(); specialty = specialty.replace(/-/g, " ");
    const [doctors, setDoctors] = useState([]);
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    setShowUppernav(false);
    useEffect(() => {
        enqueueSnackbar('Loading doctors...', { persist: true });
        getData();
    }, [])

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `patient/doctor-list/?specialty=${specialty}&offset=${offset}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDoctors(doctors.concat(response.data.doctors));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                console.log('DOCTOR LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setIsLoading(false);
            console.log('DOCTOR LIST FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    return (
        <>
            <Topbar>
                <HBox align="center">
                    <IconButton
                        className="ml-2"
                        onClick={() => navigate(-1)}
                    >
                        <FiArrowLeft />
                    </IconButton>
                    {isMobile ?
                        <HBox className="ml-1">
                            <P2>We have found</P2>
                            <P2 color='first' style={{ marginLeft: 4, marginRight: 4 }}>{count}</P2>
                            <P2>doctors</P2>
                            <P2 style={{ marginLeft: 4, marginRight: 4 }}>in</P2>
                            <P2 color='second'>{specialty}</P2>
                            <P2>.</P2>
                        </HBox>
                        :
                        <HBox className="ml-2">
                            <P1>We have found</P1>
                            <P1 color='first' style={{ marginLeft: 4, marginRight: 4 }}>{count}</P1>
                            <P1>doctors</P1>
                            <P1 style={{ marginLeft: 4, marginRight: 4 }}>in</P1>
                            <P1 color='second'>{specialty}</P1>
                            <P1>.</P1>
                        </HBox>
                    }
                </HBox>
                <SLink to="/dashboard">
                    <IconButton
                        className="mr-2"
                        onClick={() => setShowUppernav(true)}
                    >
                        <FiX />
                    </IconButton>
                </SLink>
            </Topbar>
            <Scrollbars
                onUpdate={handleScrollUpdate}
                style={{ height: 'calc(100vh - 50px)' }}
                renderThumbVertical={({ style, ...props }) =>
                <div {...props} style={{ ...style, backgroundColor: colors.darkGrey,
                                                                    width: '6px',
                                                                    borderRadius: '3px',
                                                                    opacity: '0.4',
                                                                    }}/>
            }>
                <Container className={isMobile ? "mx-2 my-2" : "mx-4 my-2"}>
                    {doctors.map((doctor, index) => (
                        <DoctorCard
                            isMobile={isMobile}
                            index={index}
                            id={doctor.id}
                            name={doctor.name}
                            image={doctor.image}
                            doctorType={doctor.doctor_type}
                            bmdc={doctor.bmdc}
                            qualification={doctor.qualification}
                            experience={doctor.experience}
                            specialty={specialty}
                            rating={doctor.rating}
                            affiliationSummary={doctor.affiliation_summary}
                            fee={doctor.fee}
                            isOnline={doctor.is_online}
                        />
                    ))}
                </Container>
            </Scrollbars>
        </>
    );
}

export default connect(null, { setShowUppernav })(SpecialtyDoctors);