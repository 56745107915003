import styled from "styled-components";

import colors from "../../../config/colors";

const SearchButton = styled.button`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: ${colors.grey};
    border-radius: 5px;
    padding: 8px;
    background-color: white;
    color: ${colors.grey};
    font-size: 0.75rem;
    cursor: pointer;

    :hover {
        background-color: ${colors.veryLightGrey};
    };
`
export default SearchButton;