import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { FiCalendar } from "react-icons/fi";
import { useSnackbar } from 'notistack';
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import axios from "axios";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { HBox, VBox } from "../../components/Containers";
import { Button } from "../../components/Buttons";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { setShowUppernav } from "../../services/actions/authAction";
import { LowerNav } from "../../layouts/Navbar";
import { PatchLeft, PatchRight } from "../../components/Patches";
import GPCampaignCard from "./components/GPCampaignCard";
import AppointmentsCard from "./components/AppointmentsCard";
import PrescriptionsCard from "./components/PrescriptionsCard";
import MessagesCard from "./components/MessagesCard";
import Footer from "../../layouts/Footer";

const Container = styled(VBox)`
    width: 100%;
    position: absolute;
    left: 0px;
    top: 50px;
    z-index: -1;
`

const Header = styled(VBox)`
    position: relative;
    height: 60px;
    z-index: 1;
`

const Body = styled(HBox)`
    height: calc(100vh - 150px);
`

const RightPanel = styled(HBox)`
    flex-wrap: wrap;
`

const LeftPanel = styled(VBox)`
    position: relative;
    top: -60px;
    left: 0px;
    width: 20%;
    min-height: calc(100vh - 90px);
    background-color: ${colors.lightGrey};
    border-left: 1px solid ${colors.grey};
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Dashboard = ({ setShowUppernav }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [patientName, setPatientName] = useState("");
    const [appointments, setAppointments] = useState({ count: null, data: null });
    const [prescriptions, setPrescriptions] = useState({ count: null, data: null });
    const [messages, setMessages] = useState({ count: null, data: null });

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    setShowUppernav(true);
    useEffect(() => {
        closeSnackbar();
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    const getData = async () => {
        await axios({
            method: 'GET',
            url: 'patient/dashboard/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                console.log('DASHBOARD DATA FETCH SUCCESS');

                setPatientName(response.data.name);
                setAppointments(response.data.appointments);
                setPrescriptions(response.data.prescriptions);
                setMessages(response.data.messages);
            } else {
                console.log('DASHBOARD DATA FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('DASHBOARD DATA FETCH ERROR', error);
        })
    }

    return (
        <div>
            <LowerNav selected="dashboard" />
            <Container>
                {!isMobile && <PatchLeft />}
                {!isMobile && <PatchRight />}
                <Header className="pl-2" justify="center">
                    {/* {!isMobile && <H6 color='second'>WELCOME</H6>} */}
                    {/* {!isMobile && <H5 color=''>{patientName}</H5>} */}
                </Header>
                <Body>
                    {isMobile && <TopPanel justify="center" align='center' className='p-4'>
                        <H5 className='mb-1'><span style={{ color: colors.green }}>Welcome,</span> {patientName}</H5>
                        <H3 color='first' align="center" className="mb-1">Looking for an appointment?</H3>
                        {/* <P2 align="center" className="mb-3 pl-1 pr-1">We have more than 1000 doctors on board. We provide 24/7 online medical consultancy.</P2> */}
                        <SLink to='/select-specialty'>
                            <Button
                                color='first'
                                size='sm'
                                elevated
                            >
                                <FiCalendar fontSize={16} className="mr-1" /> Book Appointment
                            </Button>
                        </SLink>
                    </TopPanel>}
                    <Scrollbars
                        style={{ height: '100%', width: '100%', backgroundColor: colors.lightGrey }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        {!isMobile && <HBox justify='center' className='mt-2'>
                            <H3 color='third' className='bold'>Welcome,</H3>
                            <H3 className='bold ml-0_5'>{patientName}</H3>
                        </HBox>}
                        {!isMobile && <VBox align='center'> 
                            {/* <H3 color='' align="center" className="mb-1">Looking for an appointment?</H3> */}
                            <SLink to='/select-specialty' className="mt-1">
                                <Button
                                    color='first'
                                    size='sm'
                                    elevated
                                >
                                    <FiCalendar fontSize={16} className="mr-1" /> Book Appointment
                                </Button>
                            </SLink>
                        </VBox>}
                        <HBox className={isMobile ? "" : "p-2"} justify="center">
                            <GPCampaignCard />
                            {/* <AppointmentsCard isMobile={isMobile} appointments={appointments} /> */}
                            <PrescriptionsCard isMobile={isMobile} prescriptions={prescriptions} />
                            <MessagesCard isMobile={isMobile} messages={messages} />
                        </HBox>
                    </Scrollbars>
                    {/* {!isMobile && <LeftPanel justify="center" align="center">
                        <VBox className="p-1" justify="center" align="center">
                            <H3 color='first' align="center" className="mb-1">Looking for an appointment?</H3>
                            <P2 align="center" className="mb-2 pl-1 pr-1">We have more than 1000 doctors on board. We provide 24/7 virtual medical consultancy.</P2>
                            <SLink to='/select-specialty'>
                                <Button
                                    color='first'
                                    size='sm'
                                    elevated
                                >
                                    <FiCalendar fontSize={16} className="mr-1" /> Book Appointment
                                </Button>
                            </SLink>
                        </VBox>
                    </LeftPanel>} */}
                </Body>
                {!isMobile && <Footer />}
            </Container>

            {/* <SelectSpecialty style={{ position: 'sticky', zIndex: '100' }} open={openSelectSp} setOpen={setOpenSelectSp} /> */}
        </div>
    )
}

export default connect(null, { setShowUppernav })(Dashboard);