import styled from "styled-components";
import { BsDot } from "react-icons/bs";

import { HBox, VBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";
import { trimText } from "../../../utils";
import { Tooltip } from "@mui/material";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Image = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    /* border: 1px solid ${colors.grey}; */
`

const Container = styled.div`
    width: '100%';
    /* border: 1px solid ${colors.grey}; */
    border-radius: 5px;
    cursor: pointer;

    :hover {
        /* background-color: ${colors.lightGrey}; */
        box-shadow: 0px 1px 5px 0px ${colors.shadow};
        transition: box-shadow 100ms;
    }
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

const DoctorCard = ({ image, name, doctorType, bmdc, qualification, specialty, fee,
                      experience, isOnline }) => {
    return (
        <Container className="p-2 mb-1">
            <HBox justify='space-between'>
                <HBox>
                    <VBox align='center' className="">
                        <Image
                            src={`${baseURL}${image}`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="/images/noImage.svg";
                            }}
                        />
                    </VBox>
                    <VBox className="ml-1">
                        <HBox align='center' className="">
                            <H5>{name}</H5>
                            <Tooltip title={qualification} arrow>
                                <P3 className="" style={{ marginLeft: 4 }}>{trimText(qualification, 64)}</P3>
                            </Tooltip>
                            {isOnline ? <OnlineIcon className='ml-0_5' /> : null}
                        </HBox>
                        {experience && <P3 className="bold">{experience}</P3>}
                        <P2 color='second'>{specialty}</P2>
                        <P3>BMDC: {doctorType === "MBBS" ? "A-" : ""}{bmdc}</P3>
                    </VBox>
                </HBox>
                <VBox justify='center'>
                    <P2 color='third'>৳ {fee}</P2>
                </VBox>
            </HBox>
        </Container>
    );
}

export default DoctorCard;