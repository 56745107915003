import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dialog } from "@mui/material"
import { FiX } from "react-icons/fi";
import { Scrollbars } from 'react-custom-scrollbars';

import { HBox, VBox } from "../components/Containers";
import { IconButton, Button } from "../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import colors from "../config/colors";
import responsive from "../config/responsive";
import { termsAndConditionsData } from '../data';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const TermsAndConditionsDlg = ({ open, setOpen }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H4 className="ml-2">Terms & Conditions</H4>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Scrollbars
                    style={{ height: '70vh' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <VBox className={isMobile ? 'px-2 pb-2' : 'px-4 pb-4'}>
                {Object.keys(termsAndConditionsData).map((key, index) => (
                    <VBox>
                        <H4 color='first' className={isMobile ? 'mt-2' : 'mt-4'}>{key}</H4>
                        <Line className='mb-0_5' />
                        {termsAndConditionsData[key].map((paragraph, index) => (
                            <P2 className='mt-1' style={{ textAlign: 'justify' }}>{paragraph}</P2>
                        ))}
                    </VBox>
                ))}
                </VBox>
            </Scrollbars>
            <Line />
            <HBox justify="flex-end" className="p-1">
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
    )
}

export default TermsAndConditionsDlg;