import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";

const Container = styled.div`
    background-image: url("/images/gp_campaign_banner.png");
    background-size: 100% 100%, cover;
    // height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.red};
    border-radius: 10px;
    /* box-shadow: 0px 1px 0px 0px ${colors.shadow}; */
    contain: content;

    :hover {
        box-shadow: 1px 1px 15px 0px ${colors.grey};
        transition: box-shadow 300ms;
    }

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        width: 85%;
        padding-top: 70%;
    }
    @media only screen and (min-width: ${responsive.xs}px) and (max-width: ${responsive.sm-1}px) {  // sm
        width: 40%;
    }
    @media only screen and (min-width: ${responsive.sm}px) and (max-width: ${responsive.md-1}px) {  // md
        width: 35%;
    }
    @media only screen and (min-width: ${responsive.md}px) and (max-width: ${responsive.lg-1}px) {  // lg
        width: 25%;
    }
    @media only screen and (min-width: ${responsive.lg}px) {  // xl
        width: 23%;
    }
`

const ColorBar = styled.div`
    position: absolute;
    top: 0;
    height: 4px;
    width: 40%;
    border-radius: 0px 0px 4px 4px;
    background-color: ${colors.red};
`

const SLink = styled(Link)`
    text-decoration: none;
    position: fixed;
    bottom: 24px;

    @media only screen and (max-width: ${responsive.xs-1}px) { // xs
        position: static;
        margin-top: 24px;
        margin-bottom: 24px;
    }
`

const GPCampaignCard = () => {
    return (
        <Container className="m-2">
            <ColorBar />
            <SLink to="/doctor/136">
                <Button size="sm" color="second" outlined>Book Appointment</Button>
            </SLink>
        </Container>
    )
}

export default GPCampaignCard;