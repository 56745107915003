import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { FiChevronRight } from "react-icons/fi";

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import CheckChoice from "../../components/CheckChoice";
import CheckButton from "../../components/CheckButton";
import { InputTextarea } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { PatchLeft } from "../../components/Patches";

const Container = styled(HBox)``

const SLink = styled(Link)`
    text-decoration: none;
`

const TopPanel = styled(VBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const ButtonNav = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0px;
    align-items: center;
    justify-content: center;
`

const FamilyHistory = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [diseasesHistory, setDiseasesHistory] = useState([
        {text: 'Asthma', checked: false},
        {text: 'Cancer', checked: false},
        {text: 'Cystic fibrosis', checked: false},
        {text: 'Diabetes', checked: false},
        {text: 'Early/unexplained death', checked: false},
        {text: 'Elevated cholesterol', checked: false},
        {text: 'Heart diseases', checked: false},
        {text: 'High blood pressure', checked: false},
        {text: 'Metabolic diseases', checked: false},
        {text: 'Seasonal allergies', checked: false},
        {text: 'Sickle cell asthma', checked: false},
        {text: 'Stroke', checked: false},
        {text: 'Thyroid disease', checked: false},
        {text: 'Other', checked: false},
    ]);
    const otherHistoryChLim = 256;  // Sync with database limit
    const [otherHistory, setOtherHistory] = useState("");

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        enqueueSnackbar('Loading...', { persist: true });
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios({
            method: 'GET',
            url: `patient/family-history-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setData(response.data);
            } else {
                console.log('FAMILY HISTORY FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('FAMILY HISTORY FETCH ERROR', error);
        })
    }

    const submit = () => {
        axios({
            method: 'PUT',
            url: `patient/family-history-details/`,
            data: {
                diseases_history: getChoices(diseasesHistory),
                diseases_history_other: otherHistory
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                enqueueSnackbar('Successfully updated.', { variant: 'success' });
            } else {
                enqueueSnackbar('Submission failed!', { variant: 'error' });
                console.log('FAMILY HISTORY SET FAILED', response.status);
            }
        })
        .catch((error) => {
            enqueueSnackbar('Submission error!', { variant: 'error' });
            console.log('FAMILY HISTORY SET ERROR', error);
        })
    }

    const setData = (data) => {
        setChoices(data.diseases_history, diseasesHistory, setDiseasesHistory);
        setOtherHistory(data.diseases_history_other ? data.diseases_history_other : "");
    }

    const setChoices = (choices, state, setState) => {
        if (choices) {
            if (typeof(choices) === 'string') {
                setIndvChoice(choices, state, setState);
            }
            else {
                for (let i=0; i<choices.length; i++) {
                    let choice = choices[i];
                    setIndvChoice(choice, state, setState);
                }
            }
        }
    }

    const setIndvChoice = (choice, state, setState) => {
        for (let j=0; j<state.length; j++) {
            let values = [...state];
            if (values[j].text === choice) {
                values[j].checked = true;
                setState(values);
                break;
            }
        }
    }

    const getChoices = (state) => {
        let choices = [];
        for (let i=0; i<state.length; i++) {
            if (state[i].checked) {
                choices.push(state[i].text);
            }
        }

        return String(choices);
    }

    return (
        <div>
            <LowerNav selected="health-history" />
            <Container style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <PatchLeft />
                {isMobile && <TopPanel className='p-2' align="center">
                    <HBox align="center">
                        <SLink to='/health-history'>
                            <ButtonNav size='md' color='first' outlined>1</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/behavioral-health-history'>
                            <ButtonNav size='md' color='first' outlined>2</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <SLink to='/life-style'>
                            <ButtonNav size='md' color='first' outlined>3</ButtonNav>
                        </SLink>
                        <FiChevronRight color={colors.blue} className="mx-1" />
                        <ButtonNav size='md' color='first'>4</ButtonNav>
                    </HBox>
                    <H3 color='second' justify='center' className='mt-2'>Family History</H3>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ backgroundColor: colors.lightGrey }}>
                    <SLink to='/health-history'>
                        <NavBtn size="sm" color="first" className="mt-3" outlined>
                            <div className="ml-1">My Health History</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/behavioral-health-history'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Behavioral Health History</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/life-style'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">My Life Style</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">My Family History</div>
                    </NavBtnActive>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: '100%' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: `${colors.darkGrey}`, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-3'>
                            <P1>Does anyone in your family have the following? (check all that apply)</P1>
                            <CheckChoice
                                items={diseasesHistory}
                                setItems={setDiseasesHistory}
                                className='p-2'
                            />
                            {diseasesHistory.at(-1).checked &&
                                <VBox className="mx-3">
                                    <InputTextarea
                                        placeholder="Please type other history..."
                                        value={otherHistory}
                                        onChange={(e) => setOtherHistory(e.target.value.slice(0, otherHistoryChLim))}
                                    />
                                    <P2 align="right">{otherHistory.length} / {otherHistoryChLim}</P2>
                                </VBox>
                            }
                        </VBox>
                        
                        <HBox justify="center" className="mt-4 mb-2">
                            <Button size="md" color="first" onClick={submit}>Submit</Button>
                        </HBox>
                        <HBox className="mx-2 mb-2">
                            <SLink to="/life-style">
                                <Button size="sm" outlined>Previous</Button>
                            </SLink>
                        </HBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel />}
            </Container>
            {!isMobile && <Footer />}
        </div>
    );
}

export default FamilyHistory;