export const specialtiesData = [
    "General Physician",
    // Medicine
    "Internal Medicine",
    "Cardiology",
    "Respiratory Medicine",
    "Neurology",
    "Nephrology",
    "Gastroenterology",
    "Hepatology",
    "Endocrinology",
    "Pediatrics",
    "Oncology",
    "Rheumatology",
    "Hematology",
    "Dermatology & Venereology",
    "Psychiatry",
    "Physical Medicine & Rehabilitation",
    "Aesthetic Dermatology",
    // Surgery
    "General Surgery",
    // "Cardiovascular Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Gynecology & Obstetrics",
    "ENT & Head Neck Surgery",
    "Ophthalmology",
    "Oral & Dental Surgery",
    "Urology",
    "Colorectal Surgery",
    "Pediatric Surgery",
    "Vascular Surgery",
    // "Hepatobiliary Surgery",
]

export const genderData = [
    'Female',
    'Male',
    'Other',
]

export const termsAndConditionsData = {
    'Terms & Conditions': [
            'Please read these terms and conditions (“Terms and Conditions“) carefully before using the website and services.These terms & conditions create a contract between you & NextGen MyHealth VCP.If you are under age of 18 please confirm that you have your parents or legal guardian’s permission to use this platform.',
            'The Site reserves the right to amend, modify, add, change, or remove portions of these Terms and Conditions under this Agreement at any time without any prior notification. Changes will be effective once posted on the Site without any notice provided on the Site or by any other means. You shall check these Terms and Conditions regularly for updates. Your continued use of the site once the changes of Terms and Conditions had been made shall be construed as your acceptance of the changed Terms and Conditions. Hence, you shall adhere to the subsequent changes accordingly and agree to be held accountable for non-compliance of any terms as contained here in.'
    ],
    'Introduction': [
            'NextGen MyHealth VCP is a virtual healthcare platform. NextGen MyHealth VCP is a universal medium, where it connects service receivers (patients) and service providers (doctors).',
    ],
    'Acceptance of Terms & Conditions': [
            '(i) While accessing and using this Site or the Services, you agree to comply with all applicable laws and regulations of Bangladesh. You may be denied access or use of the Services or the Site and may not accept the Terms and Conditions if you are not permitted to receive any Services under the laws of Bangladesh.',
            '(ii) You agree not to undertake any action to undermine the integrity of the systems or networks of NextGen MyHealth VCP and/or any other user nor to gain unauthorized access to such systems or networks.',
            '(iii) You agree that (a) you will not copy, reproduce, download, re-publish, sell, distribute or resell any Services or any information, text, images, graphics, video clips, sound, directories, files, databases or listings, etc. available on or through the Site and (b) you will not copy, reproduce, download, compile or otherwise use any Site content for the purposes of operating a business that competes with NextGen MyHealth VCP, or otherwise commercially exploiting the Site content. Systematic retrieval of Site content from the Site to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from NextGen MyHealth VCP is prohibited. Use of any content or materials on the Site for any purpose not expressly permitted in the Terms and Conditions is prohibited.',
            '(iv) You agree to grant an irrevocable, permanent, worldwide and sub-licensable (through multiple tiers) license to NextGen MyHealth VCP to adapt, modify, translate, display, transmit, distribute, reproduce, publish, duplicate, display, create derivative works, and otherwise use any or all of the user content in any form, media, or technology now known and for any purpose in future which may be beneficial to NextGen MyHealth VCP, the operation of the Site, the provision of any Services and/or the business of the user. You confirm and warrant to NextGen MyHealth VCP that you possess the entire authority, rights, power necessary to grant the above license. Information that is protected under data protection laws shall only be used and kept in compliance with the stated laws through posting or displaying any information, content or material on the Site or providing any user content to NextGen MyHealth VCP or our representative(s), and to the extent permitted under applicable laws.',
            '(v) Users must not misuse the Service by knowingly introducing viruses, Trojans, worms, logic bombs or other material which would harm the Service or any user of the Service’s own equipment.',
            '(vi) Users must not submit or contribute any information or content that contains nudity or violence, is abusive, bullying, threatening, harassing, obscene, misleading, untrue, offensive, derogatory or uses bad or rude language, as NextGen MyHealth VCP may decide in its absolute discretion.',
            '(vii) Users must not submit contribute any information or commentary about another person without that person’s permission, or post private or confidential information via the Service, including, without limitation, your or any other person’s credit card information, social security or alternate national identity numbers, non-public phone numbers or non-public email addresses.',
    ],
    'Medical Services & Internet Disclaimer': [
            '(i) You agree and acknowledge that any decisions or actions affecting your own health, your child or your family’s health or safety is based on the advice you received from a Doctor, and NextGen MyHealth VCP does not guarantee or is responsible for the quality and accuracy of such advice.',
            '(ii) NextGen MyHealth VCP will not be responsible if the advice of the registered doctor of the NextGen MyHealth VCP is applied to another person and any kind of problem is faced due to it.',
            '(iii) You acknowledge and understand that the Platform is not suitable to be used as the sole consultation method in potential or actual medical emergencies.',
            '(iv) If a medical condition requires a particular protocol to diagnose and prescribe as in a case of in-person consult then the same prevailing principle will be applicable to a Telemedicine consult.',
            '(v) As the doctor consultation service through NextGen MyHealth VCP platform is internet dependent and NextGen MyHealth VCP authorities will not be considered responsible if the service is interrupted due to any problem related to the internet.',
            '(vi) Since the service center has to accept services with the help of internet and mobile and computer devices, the service center will not be considered responsible if any kind of service is interrupted due to the problem of the said device.',
            '(vii) Since the internet and device are required to receive the service, sometimes due to device charge or internet problem, the doctor may provide the service earlier or later than the doctor’s scheduled time, which should be considered as system delay by the service recipient.',
    ],
    'Limitation of Liability': [
            'Under no circumstances, including, but not limited to, negligence, will we or our subsidiaries, parent companies or affiliates be liable for any direct, indirect, incidental, special or consequential damages that result from the use of, or the inability to use, the site, including its materials, products, or services, or third-party materials, products, or services made available through the site, even if we are advised beforehand of the possibility of such damages. (because some states do not allow the exclusion or limitation of certain categories of damages, the above limitation may not apply to you. In such states, our liability and the liability of our subsidiaries, parent companies and affiliates, is limited to the fullest extent permitted by such state law.) You specifically acknowledge and agree that we are not liable for any defamatory, offensive or illegal conduct of any user. If you are dissatisfied with the site, or any materials, products, or services on the site, or with any of the site’s terms and conditions, your sole and exclusive remedy is to discontinue using the site.',
    ],
    "Creation and Maintenance of Member's Account": [
            '(i) Users must be registered on the Sites to access or use the Services (a registered User is also referred to as a “Member” below). One user may only register one account at a time on the Site with the approval of NextGen MyHealth VCP. It reserves the right to cancel or terminate a user’s member account if it has reasons to believe that the user has concurrently registered or is in control of two or more member accounts. Further, NextGen MyHealth VCP reserves the right to reject an user’s application for registration for any reason as NextGen MyHealth VCP deems appropriate.',
            '(ii) Each Member willl be solely responsible for maintaining the confidentiality and security of their Member ID and password and for all use of and activities that occur under their account (whether such use or activities are authorized or not). No Member may share, assign, or permit the use of their Member account, ID or password by another person. Members agree to notify NextGen MyHealth VCP immediately if he/she becomes aware of any unauthorized use of their password or his/her account or any other breach of security of the account.',
            '(iii) Members agree that all use of the Site and Services, and all activities that occur under their account shall be deemed to have been authorized by the Member.',
            '(iv) Members shall ensure that the details provided are correct and complete at all times. Members are obligated to update details about their account in real time by accessing his account online. NextGen MyHealth VCP may at any time in its sole discretion request that the Member updates their personal data or related details without giving any reason or prior notice.',
            '(v) Members hereby agree to change the account password from time to time and to keep the account secure and also shall be responsible for the confidentiality of the account and liable for any disclosure or use (whether such use is authorized or not) of the username and/or password.',
    ],
    'Suspension and Termination of Access': [
            'You agree that, in our sole discretion, we may suspend or terminate your password, account (or any part thereof) or use of the Site, or any part of the Site, and remove and discard any materials that you submit to the Site, at any time, for any reason, without notice. You agree that we will not be liable to you or any third party for any suspension or termination of your password, account or use of the Site or any part thereof, or any removal of any materials that you have submitted to the Site. In the event that we suspend or terminate your access to and/or use of the Site, you will continue to be bound by the Terms of Service that were in effect as of the date of your suspension or termination. In case of lack of evidence for the practice as doctor or provider by the law or authorization of the particular body the suspension will be activated upon findings and all responsibilities go to the provider.',
    ],
    'Community Rules': [
            'The Site may include a variety of features, such as blogs, photo- and video-sharing pages, social networking features that allow feedback to us and allow users to interact with each other on the Site and to post content and materials for display on the Site.',
            '(i) Restrict or inhibit any other user from using and enjoying the Site.',
            '(ii) Use the Site to impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.',
            '(iii) Interfere with or disrupt any servers or networks used to provide the Site or its features, or disobey any requirements, procedures, policies or regulations of the networks we use to provide the Site.',
            '(iv) Use the Site to instigate or encourage others to commit illegal activities or cause injury or property damage to any person.',
            '(v) Gain unauthorized access to the Site, or any account, computer system, or network connected to the Site, by means such as hacking, password mining or other illicit means.',
            '(vi) Obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site.',
            '(vii) Use the Site to post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, pornographic, profane or indecent information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, national or international law.',
            '(viii) Use the Site to post or transmit any information, software or other material that violates or infringes upon the rights of others, including material that is an invasion of privacy or publicity rights or that is protected by copyright, trademark or other proprietary right, or derivative works with respect thereto, without first obtaining permission from the owner or rights holder.',
            '(ix) Use the Site to post or transmit any information, software or other material that contains a virus or other harmful component.',
            '(x) Use the Site to post, transmit or in any way exploit any information, software or other material for commercial purposes, or that contains advertising.',
            '(xi) Use the Site to advertise or solicit to anyone to buy or sell services, or to make donations of any kind, without our express written approval.',
            '(xii) Gather for marketing purposes any email addresses or other personal information that has been posted by other users of the Site.',
            '(xiii) Contact anyone who has asked not to be contacted.',
            '(xiv) Take or cause to be taken any action that disrupts the normal flow of postings and dialogue on our Site (such as submitting an excessive number of messages – i.e. a flooding attack), or that otherwise negatively affects other users’ ability to use the Site and/or services.',
            'We reserve the right to deny access to the Site or any features of the Site to anyone who violates these Community Rules or who, in our sole judgment, interferes with the ability of others to enjoy our website or infringes the rights of others.',
    ],
    'Health Information': [
            '(i) The health information provided on the Site is solely for informative purposes as a public service to enhance customer service for our customers and to promote consumer health. It does not constitute medical advice and is not intended to be a substitute for proper medical care provided by qualified health care professionals.',
            '(ii) NextGen MyHealth VCP assumes no responsibility for any circumstances arising out of the use, misuse, interpretation or application of any information supplied on this Site. The Members are requested to consult with their health care professional for appropriate examinations, treatment, testing, and care recommendations.',
            '(iii) The Members are requested to exercise their own judgment when using any Service or selecting a healthcare professional through NextGen MyHealth VCP or any service linked to NextGen MyHealth VCP.',
    ],
    'Payment': [
            '(i) If you do not have health package coverage for Services, or if your coverage is denied, you acknowledge and agree that you shall be personally responsible for all incurred expenses. NextGen MyHealth VCP offers no guarantee that you shall receive any such reimbursement. NextGen MyHealth VCP reserves the right to modify or implement a new pricing structure at any time prior to billing you for your initial payment or for future payments due pursuant to these Terms of Use.',
            '(ii) Any invoices, bills, receipts, records or any kind of documents related to transactions will be provided or kept with NextGen MyHealth VCP by way of electronic documents format. NextGen MyHealth VCP will not be liable to provide the user or the member any documents other than as aforesaid.',
    ],
    'Dispute Resolution': [
            'NextGen MyHealth VCP encourages amicable settlement of any conflicts that might arise among you, NextGen MyHealth VCP or any other user including the Registered Doctors. Any such conflict shall be resolved with the assistance or involvement of this platform. For addressing any grievances, or any other issues, transaction disputes you are encouraged to report the same to Customer Support here [INSERT LINK] (“Dispute Notice”). On service of the Dispute Notice, NextGen MyHealth VCP and you will attempt in good faith to resolve the dispute. If the dispute cannot be resolved by consultation within 28 (twenty-eight) days from the date of commencement of such consultation, then NextGen MyHealth VCP and you will jointly engage a mediator. The procedure for the mediation shall be in accordance with section 89A of the Code of Civil Procedure, 1908. If either NextGen MyHealth VCP or you fail or refuse to agree to or participation in the mediation procedure or if in any event the dispute or difference is not resolved to satisfaction of both parties within 30 (thirty) days after it has been referred, the matter shall be referred for arbitration and settled in accordance with the Arbitration Act, 2001. The decision of the arbitrator of the arbitral tribunal will be final and binding upon the Parties. Each Party shall bear their costs of arbitration. The venue for arbitration will be Dhaka and the proceedings of arbitration shall be held in the English language.',
    ]
}

export const privacyPolicyData = {
    'Privacy Policy': [
        'This privacy policy describes how NextGen MyHealth VCP website and related application (the “Site”, “we” or “us”) collects, uses, and disclose the personal information that we receive from users of the Site.',
        'NextGen MyHealth VCP has established this Site to link up users with the service providing persons or team. This policy also applies to any mobile applications that we develop for use with our services and Team specific pages on the Site, and references to this “Site”, “we” or “us” is intended to also include these mobile applications.',
        'If you do not agree to the terms of this Privacy Policy, please do not use NextGen MyHealth VCP.'
    ],
    'Information Collects About You': [
        'While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").'
    ],
    'Communications': [
        'We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information.'
    ],
    'Log Data': [
        'Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").',
        'This Log Data may include information such as your computers Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.',
        'In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this.'
    ],
    'Your Contact Information': [
        'When you provide us with your contact information, we will use that information to communicate with you about your use of our service. For example, when you have entered into a confirmed transaction with us, we will use your contact information to notify you of the transaction. We will also share your contact information with the consultant. If you agree, we may also use your e-mail address to send you a newsletter or other information about our services or about other products or services in which you may be interested. You may change your preferences at any time, though you will not be able to opt out of messages relating to your use of our service.'
    ],
    'Cookies': [
        'Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computers hard drive.',
        'Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.'
    ],
    'Security': [
        'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.'
    ],
    'Changes To This Privacy Policy': [
        'We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.',
        'If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.'
    ],
}

export const refundPolicyData = {
    'Payments': [
        '1. Doctor’s consultation fee should be paid only through NextGen MyHealth VCP approved payment gateway or bank account. NextGen MyHealth VCP will not be responsible for using any another payment gateway.',
        '2. Patients must pay the consultation fee and VAT before joining the doctor for consultation.',
        '3. If there is no consultation fee, the Patients will not be required to make any payment and they will be directly transferred to the doctor consultation.',
        '4. Any kind of unethical transaction through NextGen MyHealth VCP will not be accepted and NextGen MyHealth VCP will never be responsible for it.'
    ],
    'Refund and Cancellation': [
        '1. If the doctor is not available for the consultation or the waiting time is more than 60 minutes then customers can cancel the consultation and a refund will be made.',
        '2. Consultation fees will not be refunded in any way after consulting a doctor.',
        '3. If a Patient cancels a consultation prior to the video call of the Doctor after acceptance by the Doctor of the Patient’s request before the expiry of 60 minutes from the Initiation Request, a 5% fee will be deducted and the remaining fee will be refunded to the Patient. There may be additional charges to make the refund. For example, if the user wants the money to be refunded to bKash, Nagad etc.The transaction fee will be deducted.',
        '4. For any cancellation and refund please email to: info@nextgenmyhealth.com',
        '5. Refund process may take up to 10 working days.'
    ]
}