import { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";

const Select = styled.select`
    /* width: 30%; */
    height: 2.5em;
    color: ${colors.darkGrey};
    font-size: 0.75rem;
    border: 1px solid ${colors.grey};
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    outline: none;
    cursor: pointer;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const SelectPracticeLocation = ({ open, setOpen, handleChangeFilter, setLocFiltTxt }) => {
    const [divisions, setDivisions] = useState([{id: null, name: 'Select Division'}]);
    const [districts, setDistricts] = useState([{id: null, name: 'Select District'}]);
    const [selectedDist, setSelectedDist] = useState({ id: null, name: "" });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = () => {
            setDivisions([{id: null, name: 'Select Division'}]);
            setDistricts([{id: null, name: 'Select District'}]);
            getDivisions();
        }
        if (open) init();
    }, [open]);

    const getDivisions = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `patient/division-list/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
               setDivisions([{id: null, name: 'Select Division'}].concat(res.data));
               setDistricts([{id: null, name: 'Select District'}]);
            } else {
                console.log('DIVISION LIST FETCH FAILED', res.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('DIVISION LIST FETCH ERROR', error);
        })
    }

    const getDistricts = (divisionId) => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `patient/district-list/?division_id=${divisionId}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
               setDistricts([{id: null, name: 'Select District'}].concat(res.data));
            } else {
                console.log('DISTRICT LIST FETCH FAILED', res.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('DISTRICT LIST FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const handleDone = () => {
        handleChangeFilter(selectedDist.id, 'district_id');
        setLocFiltTxt(selectedDist.name);
        handleClose();
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Select Location</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <Select className="mb-2" onChange={(e) => getDistricts(e.target.value)}>
                    {divisions.map((division, index) => (
                        division.id === null ?
                            <option value={division.id} style={{ display: 'none' }}>{division.name}</option>
                            :
                            <option value={division.id}>{division.name}</option>
                    ))}
                </Select>
                <Select onChange={(e) => setSelectedDist({id: districts[e.target.value].id, name: districts[e.target.value].name})}>
                    {districts.map((district, index) => (
                        district.id === null ?
                            <option value={index} style={{ display: 'none' }}>{district.name}</option>
                            :
                            <option value={index}>{district.name}</option>
                    ))}
                </Select>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleDone}
                >
                    Done
                </Button>
            </HBox>
        </Dialog>
    )
}

export default SelectPracticeLocation;