import { useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiArrowRight, FiX } from "react-icons/fi";
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import axios from "axios";
import { SpinnerCircular } from 'spinners-react';
import { useNavigate, useLocation } from "react-router-dom";

import { InputText } from "../../../components/InputText";
import { Button, IconButton } from "../../../components/Buttons";
import { H3, H4, H5, H6, P1, P2 } from "../../../components/Typography";
import { VBox, HBox } from "../../../components/Containers";
import colors from "../../../config/colors";
import voucherIcon from "../../../assets/images/voucher_icon.png";

const Image = styled.img`
    height: 80px;
    border-radius: 3px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const SelectPGWDlg = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selectedPGW, setSelectedPGW] = useState('bKash');
    const [amountPayable, setAmountPayable] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [showVoucherInput, setShowVoucherInput] = useState(false);
    const [voucherCode, setVoucherCode] = useState('');
    
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [isLoadingVoucher, setIsLoadingVoucher] = useState(false);

    useEffect(() => {
        if (open) {
            setVoucherCode('');
            setAmountPayable(localStorage.getItem('nh-amount-payable'));
            let disc = localStorage.getItem('nh-discount');
            setDiscount(disc === 'null' ? null : disc);  // localStorage store null as string
        }
    }, [open]);

    const handleApplyVoucher = () => {
        if (!voucherCode) {
            enqueueSnackbar('Please enter a voucher code.', { variant: 'error' });
            return;
        }

        setIsLoadingVoucher(true);
        axios({
            method: 'POST',
            url: `patient/apply-voucher/`,
            data: {
                appointment_id: localStorage.getItem('nh-appointment-id'),
                code: voucherCode,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoadingVoucher(false);
            if (response.status === 200) {
                localStorage.setItem('nh-amount-payable', response.data.amount_payable);
                localStorage.setItem('nh-discount', response.data.discount);
                setAmountPayable(response.data.amount_payable);
                setDiscount(response.data.discount);

                enqueueSnackbar('Voucher applied.', { variant: 'success' });
            } else {
                console.log('APPLY VOUCHER FAILED');
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
            }
        })
        .catch((error) => {
            setIsLoadingVoucher(false);
            console.log('APPLY VOUCHER ERROR', error);
            if (error.response.status === 406) {
                enqueueSnackbar(error.response.data.message, {variant: 'error'});
            }
            else {
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
            }
        });
    }

    const handlePayment = () => {
        if (!parseFloat(amountPayable)) {  // if payment amount is zero
            manualPayment(0);
        }
        else if (selectedPGW === 'bKash') {
            bKashCreatePayment();
        }
    }

    const bKashCreatePayment = () => {
        enqueueSnackbar('Redirecting to bKash payment gateway... Please wait a moment.', { persist: true });
        setIsLoadingPayment(true);
        axios({
            method: 'POST',
            url: `patient/bkash-create-payment/`,
            data: {
                appointment_id: localStorage.getItem('nh-appointment-id'),
                callback_url: process.env.REACT_APP_BKASH_CALLBACK_URL,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                if (response.data.statusCode === "0000") {
                    window.open(response.data.bkashURL,"_self");
                }
                else {
                    enqueueSnackbar(response.data.statusMessage, {variant: 'error'});
                    setIsLoadingPayment(false);
                }
            } else {
                console.log('BKASH PAYMENT CREATE FAILED', response.status);
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
                setIsLoadingPayment(false);
            }
        })
        .catch((error) => {
            closeSnackbar();
            setIsLoadingPayment(false);
            console.log('BKASH PAYMENT CREATE ERROR', error);
            if (error.response.status === 400) {
                enqueueSnackbar(error.response.data.message, {variant: 'error'});
            }
            else {
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
            }
        });
    }

    const manualPayment = (payAmount) => {
        enqueueSnackbar('Checking out... Please wait a moment.', { persist: true });
        setIsLoadingPayment(true);
        axios({
            method: 'POST',
            url: `patient/manual-payment/`,
            data: {
                appointment_id: localStorage.getItem('nh-appointment-id'),
                pay_amount: 0,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            setIsLoadingPayment(false);
            if (response.status === 200) {
                setOpen(false);
                
                if(location.pathname === '/upcoming-appointments') {
                    window.location.reload();
                }
                else navigate('/upcoming-appointments');
            } else {
                console.log('MANUAL PAYMENT FAILED', response.status);
                enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
            }
        })
        .catch((error) => {
            closeSnackbar();
            setIsLoadingPayment(false);
            console.log('MANUAL PAYMENT ERROR', error);
            enqueueSnackbar('Something went wrong! Please try again...', {variant: 'error'});
        });
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H4 className='ml-2 bold'>Payment Method</H4>
                <IconButton
                    className="mr-2"
                    onClick={() => {setOpen(false); navigate('/upcoming-appointments')}}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-2" align='center'>
                
                {parseFloat(amountPayable) ?
                    <HBox className="px-2 pt-4 pb-6" justify='center' style={{ width: '100%' }}>
                        {selectedPGW === 'bKash' ?
                            <Image
                                className="p-0_5 m-1 clickable"
                                style={{ border: `2px solid ${colors.grey}` }}
                                src="/images/bkash.png"
                            />
                            :
                            <Image
                                className="p-0_5 m-1 clickable"
                                style={{ border: `2px solid ${colors.grey}` }}
                                src="/images/bkash_grey.png"
                                onClick={() => setSelectedPGW('bKash')}
                            />
                        }
                    </HBox>
                    :
                    null
                }
                {discount ?
                    <VBox align='flex-end'>
                        <P2 align='center' className="bold">Total Amount : <span style={{ color: colors.darkGrey }}>৳ {parseFloat(amountPayable)+parseFloat(discount)}</span></P2>
                        <P2 align='center' className="bold">Discount : <span style={{ color: colors.green }}>৳ -{discount}</span></P2>
                        <P2 align='center' className="bold">Total Amount Payable: <span style={{ color: colors.blue }}>৳ {amountPayable}</span></P2>
                    </VBox>
                    :
                    <P1 align='center' className="bold">Total Amount Payable: <span style={{ color: colors.blue }}>৳ {amountPayable}</span></P1>
                }
                {!showVoucherInput && !discount && parseFloat(amountPayable) ?
                    <HBox align='center' className="mt-1 clickable" onClick={() => setShowVoucherInput(true)}>
                        <img
                            style={{ height: 16 }}
                            src={voucherIcon}
                        />
                        <H5 color='second' className="ml-0_5">Apply a Voucher</H5>
                    </HBox>
                    :
                    null
                }
                {showVoucherInput && !discount && parseFloat(amountPayable) ?
                    <HBox justify='center' align='center' className='my-2' style={{ width: '100%' }}>
                        <InputText
                            style={{ width: '50%' }}
                            placeholder="Enter voucher code"
                            value={voucherCode}
                            onChange={e => setVoucherCode(e.target.value)}
                        />
                        {voucherCode ?
                            isLoadingVoucher ?
                                <SpinnerCircular size={30} thickness={120} speed={100} color={colors.blue} secondaryColor={colors.lightGrey} />
                                :
                                <Button color='first' size='sm' className="ml-0_5" onClick={handleApplyVoucher}>Apply</Button>
                            :
                            <Button size='sm' className="ml-0_5" onClick={() => setShowVoucherInput(false)}>Hide</Button>
                        }
                    </HBox>
                    :
                    null
                }
            </VBox>
            {(selectedPGW || !parseFloat(amountPayable)) &&
                <>
                    <Line />
                    <HBox justify="center" className='px-2 py-2' style={{ backgroundColor: colors.lightGrey }}>
                        {isLoadingPayment ?
                            <SpinnerCircular size={30} thickness={120} speed={100} color={colors.blue} secondaryColor={colors.lightGrey} />
                            :
                            <Button
                                size='sm'
                                color='first'
                                className='ml-1'
                                onClick={handlePayment}
                            >
                                <FiArrowRight className="mr-1" />
                                Proceed to Checkout
                            </Button>
                        }
                    </HBox>
                </>
            }
            
        </Dialog>
    )
}

export default SelectPGWDlg;