import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { FiEdit3, FiCheck, FiX } from "react-icons/fi";
import { FaCamera } from "react-icons/fa";
import moment from "moment";
import { useSnackbar } from 'notistack';

import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import { Button } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { genderData } from "../../data";
import { getDate } from "../../utils";
import PictureUploadDlg from "./components/PictureUploadDlg";
import ChangePasswordDlg from "./components/ChangePasswordDlg";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Paper = styled.div`
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    background-color: ${colors.veryLightGrey};
`

const Image = styled.img`
    height: 120px;
    width: 120px;
    border-radius: 60px;
    border: 1px solid ${colors.grey};
`

const AddIcon = styled(FaCamera)`
    position: fixed;
    right: 5px;
    bottom: 5px;
    color: ${colors.red};
    font-size: 20px;
    cursor: pointer;
    z-index: 1;

    :hover {
        color: ${colors.darkRed};
    }
`

const ImageOverlay = styled.div`
    position: fixed;
    top: 0px;
    height: 120px;
    width: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0;
    cursor: pointer;

    :hover {
        opacity: 0.05;
        transition: 300ms;
    }
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const Select = styled.select`
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};

    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const Account = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profile, setProfile] = useState({
        image: null, first_name: null, last_name: null, name: null, gender: null,
        date_of_birth: null, address: null, email: null, phone: null,
    });
    const [editData, setEditData] = useState({
        first_name: null, last_name: null, gender: null,
        date_of_birth: null, address: null,
    });
    const [editState, setEditState] = useState({
        first_name: false, last_name: false, gender: false,
        date_of_birth: false, address: false,
    });
    const [uploadPicOpen, setUploadPicOpen] = useState(false);
    const [changePassOpen, setChangePassOpen] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `patient/patient-details/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setProfile(response.data);
            } else {
                console.log('PROFILE FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PFOFILE FETCH ERROR', error);
        })
    }

    const handleChangeField = (field) => {
        axios({
            method: 'PUT',
            url: `patient/patient-details/`,
            data: {
                key: field,
                value: editData[field],
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setProfile(response.data);
                setEditState({...editState, [field]: false});
                
                enqueueSnackbar('Profile updated.', {variant: 'success'});
            } else {
                console.log('PROFILE UPDATE FAILED', response.status);

                enqueueSnackbar('Profile update failure!', {variant: 'error'});
            }
        })
        .catch((error) => {
            console.log('PFOFILE UPDATE ERROR', error);

            enqueueSnackbar('Profile update error!', {variant: 'error'});
        })
    }

    const handleClickEdit = (field) => {
        setEditData({...editData, [field]: profile[field]});
        setEditState({...editState, [field]: true});
    }

    const inputField = (name, field, type='text') => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
            {editState[field] ?
                <Input
                    type={type}
                    value={editData[field]}
                    autoFocus
                    onChange={(e) => setEditData({...editData, [field]: e.target.value})}
                />
                :
                <P1>
                    {profile[field] ?
                        type==='date' ?
                            getDate(profile[field], 'long')
                            :
                            profile[field]
                        :
                        'Not Set'
                    }
                </P1>
            }
            </td>
            <td style={{ textAlign: 'right' }}>
                {editState[field] ?
                    <>
                        <CheckIcon onClick={() => handleChangeField(field)} />
                        <XIcon className="ml-1" onClick={() => setEditState({...editState, [field]: false})} />
                    </>
                    :
                    <EditIcon onClick={() => handleClickEdit(field)} />
                }
            </td>
        </tr>
    )

    const selectField = (name, field, options, notSelectedTxt="No Gender Selected") => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
                {editState[field] ?
                    <Select onChange={(e) => setEditData({...editData, [field]: e.target.value})}>
                        <option style={{ display: 'none' }}>{notSelectedTxt}</option>
                        {options.map((option, index) => (
                            <option value={option} selected={editData[field] === option}>{option}</option>
                        ))}
                    </Select>
                    :
                    <P1>{profile[field] ? profile[field] : 'Not Set'}</P1>
                }
            </td>
            <td style={{ textAlign: 'right' }}>
                {editState[field] ?
                    <>
                        <CheckIcon onClick={() => handleChangeField(field)} />
                        <XIcon className="ml-1" onClick={() => setEditState({...editState, [field]: false})} />
                    </>
                    :
                    <EditIcon onClick={() => handleClickEdit(field)} />
                }
            </td>
        </tr>
    )

    return (
        <>
        <LowerNav selected='account' />
        <Scrollbars
            style={{ height: isMobile ? 'calc(100vh - 110px)' : 'calc(100vh - 150px)' }}
            renderThumbVertical={({ style, ...props }) =>
                <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
        }>
            <Container>
                <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'} style={{ width: isMobile ? '90%' : '60%' }}>
                    <HBox align="center">
                        <VBox className='mr-3' style={{ contain: 'content' }}>
                            <Image
                                src={`${baseURL}${profile.image}`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="/images/noImage.svg";
                                }}
                            />
                            <AddIcon onClick={() => setUploadPicOpen(true)}/>
                            <ImageOverlay onClick={() => setUploadPicOpen(true)} />
                        </VBox>
                        <VBox className={isMobile ? 'mt-2' : ''}>
                            <H2 color="first" className="mb-1">Photo</H2>
                            <P1 style={{ wordWrap: 'break-word' }}>A photo helps to communicate in a better way.</P1>
                        </VBox>
                    </HBox>
                </Paper>

                <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'} style={{ width: isMobile ? '90%' : '60%' }}>
                    <VBox>
                        <H2 color="first">Personal Information</H2>
                        <P1>We keep your personal information secure.</P1>

                        <table style={{ width: '100%' }} className="mt-2">
                            {inputField('FIRST NAME', 'first_name')}
                            {inputField('LAST NAME', 'last_name')}
                            {selectField('GENDER', 'gender', genderData)}
                            {inputField('DATE OF BIRTH', 'date_of_birth', 'date')}
                            {inputField('ADDRESS', 'address', genderData)}
                        </table>
                    </VBox>
                </Paper>

                <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'} style={{ width: isMobile ? '90%' : '60%' }}>
                    <VBox>
                        <H2 color="first">Contact Information</H2>

                        <table style={{ width: '100%' }} className="mt-2">
                            {/* <tr style={{ height: 30 }}>
                                <td style={{ width: isMobile ? 120 : 200 }}><H5>EMAIL</H5></td>
                                <td><P1>{profile.email}</P1></td>
                            </tr> */}
                            <tr style={{ height: 30 }}>
                                <td style={{ width: isMobile ? 120 : 200 }}><H5>PHONE</H5></td>
                                <td><P1>{profile.phone}</P1></td>
                            </tr>
                        </table>
                    </VBox>
                </Paper>

                <Paper className={isMobile ? 'my-2 p-2' : 'my-2 p-4'} style={{ width: isMobile ? '90%' : '60%' }}>
                    <VBox>
                        <H2 color="first">Password</H2>
                        <P1>Do not share your password in public.</P1>

                        <table style={{ width: '100%' }} className="mt-2">
                            <tr style={{ height: 30 }}>
                                <td style={{ width: isMobile ? 120 : 200 }}><H5>********</H5></td>
                                <td>{profile.password_updated_on ?
                                        <P1>Last updated on {moment(profile.password_updated_on).format("DD MMMM YYYY")}</P1>
                                        :
                                        <P1>Not updated after registration</P1>
                                    }
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <EditIcon onClick={() => setChangePassOpen(true)} />
                                </td>
                            </tr>
                        </table>
                    </VBox>
                </Paper>
            </Container>
        </Scrollbars>
        {!isMobile && <Footer />}

        <PictureUploadDlg
            open={uploadPicOpen}
            setOpen={setUploadPicOpen}
            profile={profile}
            setProfile={setProfile}
        />
        <ChangePasswordDlg
            open={changePassOpen}
            setOpen={setChangePassOpen}
            profile={profile}
            setProfile={setProfile}
        />
        </>
    );
}

export default Account;
