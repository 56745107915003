import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import styled from "styled-components";
import { FiFileText, FiEye, FiMail, FiMic, FiMicOff, FiVideo, FiVideoOff, FiPhone } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Tooltip } from '@mui/material';

import { HBox, VBox } from "../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button, IconButton } from "../../components/Buttons";
import { InputText } from "../../components/InputText";
import colors from "../../config/colors";
import responsive from "../../config/responsive";
import { removeHttp } from "../../utils";
import { setShowUppernav } from '../../services/actions/authAction';
import { setCallState } from '../../services/actions/chatAction';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const DialContainer = styled(VBox)`
    height: 100vh;
    background: linear-gradient(229deg, #94c581 0%, rgb(17, 154, 192) 100%);
`

const Image = styled.img`
    height: 160px;
    width: 160px;
    border-radius: 80px;
    border: 2px solid ${colors.darkGrey};
`

const OtherVideo = styled.video`
`

const MyVideo = styled.video`
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
`

const BottomPanel = styled(HBox)`
    background-color: black;
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.25);
`

const PrescriptionIcon = styled(FiFileText)`
    color: white;
    cursor: pointer;
`

const DetailsIcon = styled(FiEye)`
    color: white;
    cursor: pointer;
`

const MessageIcon = styled(FiMail)`
    color: white;
    cursor: pointer;
`

const MicOnIcon = styled(FiMic)`
    color: ${colors.blue};
`

const MicOffIcon = styled(FiMicOff)`
    color: ${colors.red};
`

const VideoOnIcon = styled(FiVideo)`
    color: ${colors.blue};
`

const VideoOffIcon = styled(FiVideoOff)`
    color: ${colors.red};
`

const CallIcon = styled(FiPhone)`
    color: white;
`

const IconBox = styled(HBox)`
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : 'transparent'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent' };
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

let iceCandidatesFromCaller = [];
let peerConnection;
let remoteStream;
let localStream;
let callSocket;

const VideoCall = ({ callState, setShowUppernav, setCallState }) => {
    let pcConfig = {
        "iceServers":
            [
                { "url": "stun:nextgenmyhealth.com:3478" },
                {
                    "url": "turn:nextgenmyhealth.com:3478",
                    "username": "nextg",
                    "credential": "123456"
                },
                // { "url": "stun:103.125.253.253:3478" },
                // {
                //     "url": "turn:103.125.253.253:3478",
                //     "username": "nextg",
                //     "credential": "123456"
                // },
                { "url": "stun:openrelay.metered.ca:80" },
                {
                    "url": "turn:openrelay.metered.ca:443",
                    "username": "openrelayproject",
                    "credential": "openrelayproject"
                },
                {"url": "stun:stun.l.google.com:19302"}
            ]
    }

    const { callerId, receiverId } = useParams();
    const localVideo = useRef(null);
    const remoteVideo = useRef(null);
    const [doctor, setDoctor] = useState({
        id: "", name: "", image: ""
    });
    // const [windowState, setWindowState] = useState('INCALL')  // CONNECTING, INCALL, ENDED  // Turned into redux state
    const [micOn, setMicOn] = useState(true);
    const [videoOn, setVideoOn] = useState(true);
    const [remoteRTCMessage, setRemoteRTCMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    setShowUppernav(false);
    useEffect(() => {
        setCallState('CONNECTING');
        getUserData();
    }, []);

    useEffect(() => {
        if (remoteRTCMessage) initReplyInvitation();
    }, [remoteRTCMessage]);

    const getUserData = () => {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            params: {
                id: callerId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            if (res.status === 200) {
                setDoctor({...doctor, id: res.data.id, name: res.data.name, image: res.data.image});
                connectSocket();
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    function answerCall() {
        callSocket.send(JSON.stringify({
            type: 'answer_call',
            data: {
                caller_id: callerId,
            }
        }));
    }

    function initReplyInvitation() {
        // setCallState('INCALL');

        beReady()
                .then(bool => {
                    processAccept();
                })
    }

    function connectSocket() {
        let ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
        
        callSocket = new WebSocket(
            ws_scheme
            + removeHttp(process.env.REACT_APP_SERVER_URL)
            // + window.location.host
            + 'ws/chat/'
        );

        callSocket.onopen = event => {
            callSocket.send(JSON.stringify({
                type: 'login',
                data: {
                    id: receiverId
                }
            }));
        }
    
        callSocket.onmessage = (e) => {
            let response = JSON.parse(e.data);
            let type = response.type;
    
            if(type == 'connection') {
                console.log(response.data.message);
                answerCall();
            }

            if(type == 'vinvite_received') {
                onVInvitationReceived(response.data);
            }
    
            if(type == 'call_ended') {
                onCallEnded(response.data);
            }
    
            if(type == 'ICEcandidate') {
                onICECandidate(response.data);
            }
        }

        function onVInvitationReceived(data) {
            console.log('Video invitation received.');

            setRemoteRTCMessage(data.rtcMessage);

            // init reply invitation with useEffect
        }

        const onCallEnded = (data) => {
            stop();

            console.log("Call ended.");
        }
    
        const onICECandidate = (data) => {
            console.log("GOT ICE candidate");
    
            let message = data.rtcMessage;
    
            let candidate = new RTCIceCandidate({
                sdpMLineIndex: message.label,
                candidate: message.candidate
            });
    
            if (peerConnection) {
                console.log("ICE candidate Added");
                peerConnection.addIceCandidate(candidate);
            }
            else {
                console.log("ICE candidate Pushed");
                
                iceCandidatesFromCaller.push(candidate);
            }
        }
    }

    function replyVInvitation(data) {
        // setCallState('INCALL');
        
        callSocket.send(JSON.stringify({
            type: 'reply_vinvite',
            data
        }));
    }

    function sendICEcandidate(data) {
        //send only if we have caller, else no need to
        console.log("Send ICE candidate");
        callSocket.send(JSON.stringify({
            type: 'ICEcandidate',
            data
        }));
    }

    function beReady() {
        return navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        })
            .then(stream => {
                console.log('Stream...');
                localStream = stream;
                localVideo.current.srcObject = stream;
                
                return createConnectionAndAddStream()
            })
            .catch(function (e) {
                // alert('getUserMedia() error: ' + e.id);
                alert('Unable to reach the media devices. Please check the permissions.');
            });
    }

    function createConnectionAndAddStream() {
        createPeerConnection();
        peerConnection.addStream(localStream);
        return true;
    }

    function processAccept() {
        peerConnection.setRemoteDescription(new RTCSessionDescription(remoteRTCMessage));
        peerConnection.createAnswer((sessionDescription) => {
            peerConnection.setLocalDescription(sessionDescription);

            if (iceCandidatesFromCaller.length > 0) {
                //I am having issues with call not being processed in real world (internet, not local)
                //so I will push iceCandidates I received after the call arrived, push it and, once we accept
                //add it as ice candidate
                //if the offer rtc message contains all thes ICE candidates we can ingore this.
                for (let i = 0; i < iceCandidatesFromCaller.length; i++) {
                    //
                    let candidate = iceCandidatesFromCaller[i];
                    console.log("ICE candidate Added From queue");
                    try {
                        peerConnection.addIceCandidate(candidate).then(done => {
                            console.log(done);
                        }).catch(error => {
                            console.log(error);
                        })
                    } catch (error) {
                        console.log(error);
                    }
                }

                // setIceCandidatesFromCaller([]);
                iceCandidatesFromCaller = [];
                console.log("ICE candidate queue cleared");
            } else {
                console.log("NO Ice candidate in queue");
            }
    
            replyVInvitation({
                caller_id: callerId,
                rtcMessage: sessionDescription
            })

            setCallState('INCALL');
        }, (error) => {
            console.log("Error");
        })
    }

    function createPeerConnection() {
        try {
            peerConnection = new RTCPeerConnection(pcConfig);
            peerConnection.onicecandidate = handleIceCandidate;
            peerConnection.onaddstream = handleRemoteStreamAdded;
            peerConnection.onremovestream = handleRemoteStreamRemoved;
            console.log('Created RTCPeerConnnection');
            return;
        } catch (e) {
            console.log('Failed to create PeerConnection, exception: ' + e.message);
            // alert('Cannot create RTCPeerConnection object.');
            alert('Connection creation failed. It appears your current browser might not be supported. Please consider switching to a different browser.');
            return;
        }
    }

    function handleIceCandidate(event) {
        if (event.candidate) {
            console.log("Local ICE candidate");
            // If a srflx candidate was found, notify that the STUN server works!
            if(event.candidate.type == "srflx"){
                console.log("The STUN server is reachable!");
                // console.log(`   Your Public IP Address is: ${event.candidate.address}`);
            }

            // If a relay candidate was found, notify that the TURN server works!
            if(event.candidate.type == "relay"){
                console.log("The TURN server is reachable !");
            }
            
            sendICEcandidate({
                user_id: callerId,
                rtcMessage: {
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate
                }
            })
    
        } else {
            console.log('End of candidates.');
        }
    }

    function handleRemoteStreamAdded(event) {
        console.log('Remote stream added.');
        remoteStream = event.stream;
        remoteVideo.current.srcObject = remoteStream;
    }
    
    function handleRemoteStreamRemoved(event) {
        console.log('Remote stream removed. Event: ', event);
        remoteVideo.current.srcObject = null;
        localVideo.current.srcObject = null;
    }
    
    window.onbeforeunload = function () {
        if (callState === 'INCALL' || callState === 'CONNECTING') {
            handleEndCall();
        }
    }

    function handleEndCall() {
        stop();

        callSocket.send(JSON.stringify({
            type: 'end_call',
            data: {
                user_id: callerId
            }
        }));
    }
    
    function stop() {
        localStream?.getTracks().forEach(track => track.stop());
        peerConnection?.close();
        peerConnection = null;
        setCallState('ENDED');
    }

    const handleClickMic = () => {
        const audioTrack = localStream?.getTracks().find(track => track.kind === 'audio');

        if(audioTrack) {
            if(audioTrack.enabled) {
                audioTrack.enabled = false;
                setMicOn(false);
            }
            else {
                audioTrack.enabled = true;
                setMicOn(true);
            }
        }
    }

    const handleClickVideo = () => {
        const videoTrack = localStream?.getTracks().find(track => track.kind === 'video');

        if(videoTrack) {
            if(videoTrack.enabled) {
                videoTrack.enabled = false;
                setVideoOn(false);
            }
            else {
                videoTrack.enabled = true;
                setVideoOn(true);
            }
        }
    }

    return (
        <VBox>
            {callState === 'ENDED' && <DialContainer
                justify="center"
                align="center"
            >
                <H2 color="second" className='normal'>Call Ended</H2>
                {doctor.image && <Image
                                     className="mt-2"
                                     src={`${baseURL}${doctor.image}`}
                                     onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src="/images/noImage.svg";
                                     }}
                                 />}
                <H4 color="black" className='mt-1'>{doctor.name}</H4>
                <SLink to='/dashboard'>
                    <Button
                        size='md'
                        color='second'
                        className='mt-4'
                        elevated
                    >
                        Go back to dashboard
                    </Button>
                </SLink>
            </DialContainer>}
            {callState === 'CONNECTING' && <DialContainer
                justify="center"
                align="center"
            >
                <H2 color="black" className='normal'>Connecting...</H2>
            </DialContainer>}
            <VBox justify='center' style={{ height: 'calc(100vh - 60px)' }}>
                <OtherVideo
                    style={{ display: callState === 'INCALL' ? 'block' : 'none',
                        width: isMobile ? '100%' : 'auto',
                        height: isMobile ? 'auto' : '100%' }}
                    ref={remoteVideo}
                    autoPlay
                    // muted
                    playsInline
                />
            </VBox>
            <MyVideo
                ref={localVideo}
                autoPlay
                playsInline
                muted
                className="mr-2 mt-2"
                style={{ display: callState === 'INCALL' && videoOn ? 'block' : 'none', width: isMobile ? 100 : 150 }}
            />
            {callState !== 'ENDED' && <BottomPanel align="center" justify="space-between" className='px-2'>
                <HBox>
                    {/* <PrescriptionIcon className='mx-1' />
                    <DetailsIcon className='mx-1' />
                    <MessageIcon className='mx-1' /> */}
                </HBox>
                {callState === 'INCALL' && <HBox>
                    <Tooltip title={micOn ? 'Mute' : 'Unmute'} arrow>
                        <IconBox borderColor={micOn ? colors.blue : colors.red} align="center" justify="center" className='mx-0_5' onClick={handleClickMic}>
                            {micOn ? <MicOnIcon /> : <MicOffIcon />}
                        </IconBox>
                    </Tooltip>
                    <Tooltip title={micOn ? 'Turn Off Camera' : 'Turn On Camera'} arrow>
                        <IconBox borderColor={videoOn ? colors.blue : colors.red} align="center" justify="center" className='mx-0_5' onClick={handleClickVideo}>
                            {videoOn ? <VideoOnIcon /> : <VideoOffIcon />}
                        </IconBox>
                    </Tooltip>
                </HBox>}
                <Tooltip title='End Call' arrow>
                    <IconBox borderColor={colors.red} backgroundColor={colors.red} align="center" justify="center" onClick={handleEndCall}>
                        <CallIcon />
                    </IconBox>
                </Tooltip>
            </BottomPanel>}
        </VBox>
    )
}

const mapStateToProps = state => ({
    callState: state.chat.callState,
});

export default connect(mapStateToProps, { setShowUppernav, setCallState })(VideoCall);