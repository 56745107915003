import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link, useParams } from "react-router-dom";
import { FiX, FiArrowLeft, FiChevronRight } from "react-icons/fi";
import axios from "axios";
import moment from "moment";
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSnackbar } from "notistack";

import { setShowUppernav } from "../../services/actions/authAction";
import { VBox, HBox } from "../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import { Button, IconButton } from "../../components/Buttons";
import colors from "../../config/colors";
import { getDate } from "../../utils";
import DayScheduleCard from "./components/DayScheduleCard";

const Topbar = styled.div`
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 1px 0px 0px ${colors.shadow};
    z-index: 1;
`

const BtnIcon = styled(FiChevronRight)`
    margin-left: 4px;
`

const ProceedButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;

    :hover ${BtnIcon} {
        margin-left: 8px;
        transition: margin-left 300ms;
    }
`

const AppointmentSchedule = ({ setShowUppernav }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    var { doctorId } = useParams();
    const [slotsData, setSlotsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    setShowUppernav(false);
    useEffect(() => {
        enqueueSnackbar('Loading slots...', { persist: true });
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `patient/consult-slot-list/`,
            params: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setSlotsData(response.data);
            } else {
                console.log('CONSULT SLOT LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('CONSULT SLOT LIST FETCH ERROR', error);
        });
    }

    return (
        <>
            <Topbar>
                <IconButton
                    className="ml-2"
                    onClick={() => navigate(-1)}
                >
                    <FiArrowLeft />
                </IconButton>
                <P1>Select Appointment Slot</P1>
                <Link to='/dashboard'>
                    <IconButton
                        className="mr-2"
                        onClick={() => setShowUppernav(true)}
                    >
                        <FiX />
                    </IconButton>
                </Link>
            </Topbar>
            <Scrollbars
                style={{ height: 'calc(100vh - 50px)' }}
                renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <VBox className="px-5 pt-7 pb-5" style={{ width: '100%' }}>
                    {slotsData.length ?
                        slotsData.map((dateData, index) => (
                            <DayScheduleCard
                                doctorId={doctorId}
                                date={getDate(dateData.date, 'long')}
                                day={dateData.day}
                                consultSlots={dateData.consult_slots}
                                availableSlotsCount={dateData.available_slots_count}
                            />
                        ))
                        :
                        !isLoading && <H2 color='second' align='center'>No slots available.</H2>
                    }
                </VBox>
            </Scrollbars>
        </>
    )
}

export default connect(null, { setShowUppernav })(AppointmentSchedule);