import styled from "styled-components";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import Form from "./components/Form";
import Footer from "../../layouts/Footer";

const Container = styled(VBox)`
    height: calc(100vh - 90px);
    background-color: ${colors.lightGrey};
`

const PasscodeVerification = ({ isAuthenticated, isVerified }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => closeSnackbar(), []);

    if (isVerified) {
        return (<Navigate to="/dashboard" />)
    }

    return (
        <>
        <Container justify="center" align="center">
            <Form />
        </Container>
        <Footer />
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps, { })(PasscodeVerification);