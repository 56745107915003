import { useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from 'axios';
import { Camera, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { HBox, VBox } from "../components/Containers";
import { Button, IconButton } from "../components/Buttons";
import colors from "../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const TakeHRecordPhotoDlg = ({ open, setOpen, handleAddFile }) => {
    function handleTakePhoto (dataUri) {
        console.log('PHOTO TAKEN');
        setOpen(false);
        handleAddFile(dataUrlToFile(dataUri, 'health_record.png'));
    }

    function dataUrlToFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
      
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
      
        return new File([u8arr], filename, { type: mime });
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Take Report Photo</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox>
                <Camera
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    isFullscreen={false}
                    onTakePhoto = {dataUri => handleTakePhoto(dataUri)}
                />
            </VBox>
        </Dialog>
    )
}

export default TakeHRecordPhotoDlg;