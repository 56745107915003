import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import './App.css';
import { UpperNav } from './layouts/Navbar';
import Login from './pages/Login';
import PasscodeVerification from './pages/PasscodeVerification';
import Register from './pages/Register';
import ForgetPassword from './pages/ForgetPassword';
import Dashboard from './pages/Dashboard';
import UpcomingAppointments from './pages/UpcomingAppointments';
import PreviousAppointments from './pages/PreviousAppointments';
import Prescriptions from './pages/Prescriptions';
import HealthRecords from './pages/HealthRecords';
import HealthHistory from './pages/HealthHistory';
import BehavioralHealthHistory from './pages/BehavioralHealthHistory';
import LifeStyle from './pages/LifeStyle';
import FamilyHistory from './pages/FamilyHistory';
import SelectSpecialty from './pages/SelectSpecialty';
import SpecialtyDoctors from './pages/SpecialtyDoctors';
import DoctorProfile from './pages/DoctorProfile';
import AppointmentSchedule from './pages/AppointmentSchedule';
import UrgentSchedule from './pages/UrgentSchedule';
import Messages from './pages/Messages';
import Account from './pages/Account';
import VideoCall from './pages/VideoCall';
import BkashPaymentCallback from './pages/BkashPaymentCallback';
import { checkAuthentication } from './services/actions/authAction';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

function App({ isAuthenticated, isVerified, showUppernav, checkAuthentication }) {
    useEffect(() => {
        checkAuthentication();
    }, []);
    // checkAuthentication();

    let initPath;
    if (!isAuthenticated) {
        initPath = '/login';
    }
    else if (!isVerified && isAuthenticated) {
        initPath = '/passcode-verification';
    }
    else {
        initPath = '/dashboard';
    }

    return (
        <BrowserRouter>
            <UpperNav style={showUppernav ? {} : { display: 'none' }} />
            <Routes>
                <Route path="/" element={<Navigate to={initPath} />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forget-password' element={<ForgetPassword />} />
                <Route path='/passcode-verification' element={isAuthenticated ? <PasscodeVerification /> : <Login />} />
                <Route path='/dashboard' element={isAuthenticated ? <Dashboard /> : <Login />} />
                <Route path='/upcoming-appointments' element={isAuthenticated ? <UpcomingAppointments /> : <Login />} />
                <Route path='/previous-appointments' element={isAuthenticated ? <PreviousAppointments /> : <Login />} />
                <Route path='/prescriptions' element={isAuthenticated ? <Prescriptions /> : <Login />} />
                <Route path='/health-records' element={isAuthenticated ? <HealthRecords /> : <Login />} />
                <Route path='/health-history' element={isAuthenticated ? <HealthHistory /> : <Login />} />
                <Route path='/behavioral-health-history' element={isAuthenticated ? <BehavioralHealthHistory /> : <Login />} />
                <Route path='/life-style' element={isAuthenticated ? <LifeStyle /> : <Login />} />
                <Route path='/family-history' element={isAuthenticated ? <FamilyHistory /> : <Login />} />
                <Route path='/select-specialty' element={isAuthenticated ? <SelectSpecialty /> : <Login />} />
                <Route path='/doctors/:specialty' element={isAuthenticated ? <SpecialtyDoctors /> : <Login />} />
                <Route path='/doctor/:doctorId' element={isAuthenticated ? <DoctorProfile /> : <Login />} />
                <Route path='/appointment-schedule/:doctorId' element={isAuthenticated ? <AppointmentSchedule /> : <Login />} />
                <Route path='/urgent-schedule' element={isAuthenticated ? <UrgentSchedule /> : <Login />} />
                <Route path='/messages' element={isAuthenticated ? <Messages /> : <Login />} />
                <Route path='/account' element={isAuthenticated ? <Account /> : <Login />} />
                <Route path='/call/:callerId/:receiverId' element={isAuthenticated ? <VideoCall /> : <Login />} />
                <Route path='bkash-payment-callback' element={isAuthenticated ? <BkashPaymentCallback /> : <Login />} />
            </Routes>
        </BrowserRouter>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified,
    showUppernav: state.auth.showUppernav,
});

export default connect(mapStateToProps, { checkAuthentication })(App);
