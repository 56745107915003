import styled from "styled-components";

import { HBox, VBox } from "../../../components/Containers";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from "../../../components/Typography";
import colors from "../../../config/colors";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Image = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 20px;
    /* border: 1px solid ${colors.grey}; */
`

const Container = styled.div`
    width: '100%';
    /* border: 1px solid ${colors.grey}; */
    background-color: ${props => props.selected ? colors.lightGrey : 'white'};
    border-radius: 5px;
    cursor: pointer;

    :hover {
        /* background-color: ${colors.lightGrey}; */
        box-shadow: 0px 1px 5px 0px ${colors.shadow};
        transition: box-shadow 100ms;
    }
`

const DoctorCard = ({ image, name, bmdc, qualification, specialty, selected, onClick }) => {
    return (
        <Container className="p-1 mb-1" selected={selected} onClick={onClick}>
            <HBox justify='space-between'>
                <HBox>
                    <VBox align='center' className="">
                        <Image
                            src={`${baseURL}${image}`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/images/noImage.svg";
                            }}
                        />
                    </VBox>
                    <VBox className="ml-1">
                        <HBox align='center' className="">
                            <H6>{name}</H6>
                            <P4 className="" style={{ marginLeft: 4 }}>{qualification}</P4>
                        </HBox>

                        <P3 color='second'>{specialty}</P3>
                        <P4>BMDC: {bmdc}</P4>
                    </VBox>
                </HBox>
            </HBox>
        </Container>
    );
}

export default DoctorCard;